export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export const USER_LOGOUT = "USER_LOGOUT";
export const NEW_ACCESS_TOKEN = "NEW_ACCESS_TOKEN";
export const SET_AUTH_HEADER = "SET_AUTH_HEADER";

export const FETCH_USER = "FETCH_USER";
export const FETCH_CUSTOMER_ID = "FETCH_CUSTOMER_ID";

export const FETCH_OWNED_GYMS = "FETCH_OWNED_GYMS";
export const SELECT_GYM = "SELECT_GYM";
export const UPDATE_GYM_COVER_IMG = "UPDATE_GYM_COVER_IMG";
export const UPDATE_GYM_LOGO_IMG = "UPDATE_GYM_LOGO_IMG";

export const FETCH_CLASS_PROGRAMS = "FETCH_CLASS_PROGRAMS";
