import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const StaffUserModal = ({ show, onHide }) => {
  const [canEditExpiry, setCanEditExpiry] = useState(false);
  const [canVerifyUsers, setCanVerifyUsers] = useState(false);
  const [canTakeAssistance, setCanTakeAssistance] = useState(false);

  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        initialValues={{
          first_name: "",
          username: "",
          password: ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          safeGymApi
            .post(
              "/staff/",
              {
                first_name: values.first_name,
                username: values.username,
                password: values.password,
                staffpermissions: {
                  can_edit_user_expiry: canEditExpiry,
                  can_verify_users: canVerifyUsers,
                  can_take_assistance: canTakeAssistance
                }
              },
              { headers: getAuthHeader() }
            )
            .catch(e => alert(e));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header>Crear nuevo usuario Staff</Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Usuario</Form.Label>
                <Field className="form-control" name="username" type="text" />
                <div className="text-danger">
                  <ErrorMessage name="username" />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Nombre de la persona</Form.Label>
                <Field className="form-control" name="first_name" type="text" />
                <div className="text-danger">
                  <ErrorMessage name="first_name" />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Contraseña</Form.Label>
                <Field className="form-control" name="password" type="text" />
                <div className="text-danger">
                  <ErrorMessage name="password" />
                </div>
              </Form.Group>

              <hr />
              <Form.Label style={{ fontWeight: "bold" }}>Permisos</Form.Label>
              <div className="d-flex flex-column justify-content-around">
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Verificar usuarios"
                    onChange={() => setCanVerifyUsers(!canVerifyUsers)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Editar membresías"
                    onChange={() => setCanEditExpiry(!canEditExpiry)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Tomar asistencias"
                    onChange={() => setCanTakeAssistance(!canTakeAssistance)}
                  />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onHide} variant="outline-secondary">
                Cerrar
              </Button>
              <Button type="submit" variant="success">
                Crear
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default StaffUserModal;
