import React, { useState } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import { dateFormatter } from "../../helpers/cellFormaters";
import { formatISO, parse, parseISO} from "date-fns";

const DeleteTimeslotModal = ({
  show,
  onHide,
  timeslot,
  hiddenRows,
  setHiddenRows
}) => {
  const [success, setSuccess] = useState(null);

  const renderAlert = () => {
    if (success === null) return;
    const message = success
      ? "Se ha eliminado este bloque con éxito."
      : "Ocurrió un error al intentar eliminar el bloque.";
    return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
  };

  const handleClose = () => {
    setSuccess(null);
    onHide();
  };

  const getDateText = () => {
    let start = parseISO(timeslot.start_date)
    if (timeslot.start_time !== null) {
      start = parse(timeslot.start_time, "HH:mm:ss", start)
    }
    return dateFormatter(formatISO(start))
  }

  if (!timeslot) return <React.Fragment></React.Fragment>;
  return (
    <Modal show={show} onHide={handleClose}>
      <Formik
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setSuccess(null);
          safeGymApi
            .delete(`/programs/timeslots/${timeslot.id}/`, {
              headers: getAuthHeader()
            })
            .then(res => {
              setSuccess(true);
              setHiddenRows([...hiddenRows, timeslot.id]);
              setSubmitting(false);
            })
            .catch(err => {
              setSuccess(false);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header className="text-danger">
              Confirmar Eliminación
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              ¿Estas seguro que quieres eliminar este bloque?
              <div>
                <strong>{timeslot.area_program.area.name}</strong>
                <br />
                <strong>{getDateText()}</strong>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button
                variant="outline-danger"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <EllipsisLoader /> : "Eliminar"}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default DeleteTimeslotModal;
