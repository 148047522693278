import React from "react";
import { Container, Button, Nav } from "react-bootstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TopNav = ({ toggleSidebar }) => {
  return (
    <Nav
      id="safeGymTopNav"
      className="d-md-none navbar navbar-expand-lg navbar-light bg-light fixed-top"
    >
      <Container fluid>
        <Button
          id="sidebarCollapse"
          className="btn btn-primary"
          onClick={toggleSidebar}
        >
          <i>
            <FontAwesomeIcon icon={faBars} />
          </i>
          &nbsp;Menú
        </Button>
      </Container>
    </Nav>
  );
};

export default TopNav;
