import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, isBefore, addDays, isAfter, parseISO, max } from "date-fns";
import { es } from "date-fns/locale";
import {
  faSearch,
  faUserCheck,
  faStar,
  faAsterisk,
  faEdit,
  faSync,
  faUserTimes
} from "@fortawesome/free-solid-svg-icons";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import ActionsModal from "./ActionsModal";
import LoadingRoller from "../misc/LoadingRoller";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import { sortCaret } from "../../helpers/sortCaret";
import AddMembershipModal from "./AddMembershipModal";
import RemotePaginationTable from "../../helpers/RemotePaginationTable";
import "../../assets/styles/loaders.css";

const Members = props => {
  const [data, setData] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const [fetchingData, setFetchingData] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showAddMembershipModal, setShowAddMembershipModal] = useState(false);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [stats, setStats] = useState({
    verified: "",
    active: "",
    inactive: ""
  });

  const gymState = useSelector(state => state.selectedGym);

  // const [showNewUserModal, setShowNewUserModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    setShowModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    safeGymApi
      .get("/user/gym/stats/", { headers: getAuthHeader() })
      .then(resp => setStats(resp.data))
      .catch();
  }, []);

  const toggleUserVerification = row => {
    const verificationLevel = row.verification_level >= 2 ? 0 : 2;
    safeGymApi
      .patch(
        `/user/${row.id}/verify/`,
        {
          verification_level: verificationLevel
        },
        { headers: getAuthHeader() }
      )
      .then(resp => {
        let newData = data.map(u => {
          if (u.id === row.id) {
            u.verification_level = verificationLevel;
          }
          return u;
        });
        setData(newData);
      })
      .catch(err => {
        alert(err.response.data.detail);
      });
  };

  const verifiedMemberFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (cell >= 2) {
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="verifiedTooltip">Usuario verificado</Tooltip>}
        >
          <Button
            onClick={() => toggleUserVerification(row)}
            variant="outline-success"
            size="sm"
          >
            <i>
              <FontAwesomeIcon icon={faUserCheck} />
            </i>
          </Button>
        </OverlayTrigger>
      );
    }
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="unverifiedTooltip">Usuario no verificado</Tooltip>
        }
      >
        <Button
          onClick={() => toggleUserVerification(row)}
          variant="outline-danger"
          size="sm"
        >
          <i>
            <FontAwesomeIcon icon={faUserTimes} />
          </i>
        </Button>
      </OverlayTrigger>
    );
  };

  const creditsFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (cell.length === 0) {
      return <span>0</span>;
    }
    let total = 0;
    let dates = [];
    cell.forEach(i => {
      total += i.current_amount;
      dates.push(parseISO(i.expire_on));
    });
    return (
      <div>
        {total}
        <div
          style={{ fontSize: "0.7rem" }}
          className="text-secondary font-weight-bold p-0 m-0"
        >
          {cell.length === 1 ? (
            <div>
              <span className="font-weight-normal">Exp. </span>{" "}
              {format(max(dates), "dd-MM-yy")}
            </div>
          ) : (
            <div>
              {cell.map(c => (
                <div key={c.id}>
                  <span className="font-weight-normal">
                    <span className="text-secondary font-weight-bold">
                      {c.current_amount}
                    </span>{" "}
                    Exp.{" "}
                  </span>{" "}
                  {format(parseISO(c.expire_on), "dd/MM/yy")}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const memberDateFormatter = (cell, row) => {
    const date = parseISO(cell);
    return (
      <div>
        <span>{format(date, "dd-MMM-yyyy", { locale: es })} </span>
        <div>
          <small className="text-primary">{format(date, "hh:mm aa")}</small>
        </div>
      </div>
    );
  };

  const viewActionsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="d-inline-flex">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="userDetailsTooltip">Ver Detalles</Tooltip>}
        >
          <Button
            size="sm"
            onClick={() =>
              props.history.push({
                pathname: `/members/details/${cell}`,
                state: { user: row }
              })
            }
          >
            <FontAwesomeIcon icon={faAsterisk} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="userEditTooltip">Editar</Tooltip>}
        >
          <Button
            className="mx-1"
            size="sm"
            variant="info"
            onClick={() => {
              setSelectedMember(row);
              handleShow();
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </OverlayTrigger>
      </div>
    );
  };

  const gym_id_formatter = (cell, row) => {
    if (Object.entries(gymState).length > 0) {
      return gymState.data.permissions.use_safegym_ids ? row.safe_gym_id : cell;
    }
    return cell;
  };

  const membershipFormatter = (cell, row) => {
    if (!cell) {
      return (
        <Button
          onClick={() => {
            setSelectedMember(row);
            setShowAddMembershipModal(true);
          }}
          size="sm"
          variant="outline-secondary"
          className="h-50"
        >
          <small>+ Agregar membresía</small>
        </Button>
      );
    }
    const date = parseISO(cell, "yyyy-MM-dd'T'HH:mm:ssXX", new Date());
    let badgeStyle = "";
    let label = "";
    const now = new Date();
    if (isAfter(date, now) && isBefore(date, addDays(now, 2))) {
      badgeStyle = "badge-info";
      label = "Vence Pronto";
    } else if (isAfter(date, now)) {
      badgeStyle = "badge-success";
      label = "Vigente";
    } else if (isBefore(date, now)) {
      badgeStyle = "badge-danger";
      label = "Vencida";
    }

    return (
      <div className="d-flex justify-content-between ">
        <div>
          <span>{format(date, "dd-MMM-yyyy", { locale: es })} &nbsp;</span>
          <div>
            <h6>
              <span className={`badge ${badgeStyle}`}>{label}</span>
            </h6>
          </div>
        </div>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="renovarMemb">Renovar</Tooltip>}
        >
          <Button
            onClick={() => {
              setSelectedMember(row);
              setShowAddMembershipModal(true);
            }}
            variant="outline-primary"
            className="h-50"
            size="sm"
          >
            <small>
              <FontAwesomeIcon icon={faSync} />
            </small>
          </Button>
        </OverlayTrigger>
      </div>
    );
  };

  const columns = [
    {
      text: "Nombre",
      dataField: "first_name",
      sort: true,
      sortCaret: sortCaret
    },
    {
      text: "E-Mail",
      dataField: "email",
      sort: true,
      sortCaret: sortCaret
    },
    {
      text: "Teléfono",
      dataField: "phone"
    },
    {
      text: "ID Socio",
      dataField: "external_gym_id",
      sort: true,
      sortCaret: sortCaret,
      formatter: gym_id_formatter
    },
    {
      text: "Créditos",
      dataField: "credits2",
      sortCaret: sortCaret,
      formatter: creditsFormatter,
      headerFormatter: (column, colIndex) => {
        return (
          <span className="text-warning">
            <FontAwesomeIcon icon={faStar} />
            <span className="text-dark">Créditos</span>
          </span>
        );
      }
    },
    {
      text: "Membresía Exp.",
      dataField: "membership.expiration",
      formatter: membershipFormatter,
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: (column, colIndex) => {
        return (
          <span className="text-warning">
            <FontAwesomeIcon icon={faStar} />
            <span className="text-dark">Exp. Membresía</span>
          </span>
        );
      }
    },
    {
      text: "Verificado",
      dataField: "verification_level",
      formatter: verifiedMemberFormatter,
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: (column, colIndex) => {
        return <FontAwesomeIcon icon={faUserCheck} />;
      }
    },
    {
      text: "Registrado el:",
      dataField: "date_joined",
      formatter: memberDateFormatter,
      sort: true,
      sortCaret: sortCaret
    },
    {
      text: "",
      dataField: "id",
      formatter: viewActionsFormatter
    }
  ];

  useEffect(() => {
    safeGymApi
      .get(`/user/gym/?page=1`, {
        headers: getAuthHeader()
      })
      .then(response => {
        setTotalResults(response.data.count);
        setData(response.data.results);
        setFetchingData(false);
      })
      .catch(() => alert("Error obteniendo los usuarios"));
  }, []);

  const fetchMembers = async (page, sortField, sortOrder) => {
    if (Object.entries(props.selectedGym).length > 0) {
      const searchQuery = searchTerm.length > 1 ? `&search=${searchTerm}` : "";
      const sortQuery = !sortField
        ? ""
        : `&sort_by=${sortField}&sort_order=${sortOrder}`;
      const response = await safeGymApi.get(
        `/user/gym/?page=${page}${searchQuery}${sortQuery}`,
        {
          headers: getAuthHeader()
        }
      );
      if (response.status === 200) {
        setTotalResults(response.data.count);
        setData(response.data.results);
        setFetchingData(false);
      }
    }
  };

  useEffect(() => {
    const searchFn = setTimeout(() => {
      fetchMembers(1).then();
    }, 900);
    return () => clearTimeout(searchFn);
  }, [searchTerm]);

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    setPage(page);
    fetchMembers(page, sortField, sortOrder).then();
  };

  const handleSearch = e => {};

  const renderTable = () => {
    if (fetchingData) {
      return <LoadingRoller />;
    }
    return (
      <React.Fragment>
        <Row>
          <Col lg={7} />
          <Col lg={5}>
            <div className="d-flex justify-content-end">
              <InputGroup className="mb-2">
                <Form.Control
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  onKeyDown={handleSearch}
                  placeholder="Buscar..."
                />
                <InputGroup.Append>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="userSearchBar">Iniciar búsqueda</Tooltip>
                    }
                  >
                    <Button
                      onClick={() => {
                        fetchMembers(1).then();
                      }}
                      variant="secondary"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </OverlayTrigger>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </Col>
        </Row>
        <RemotePaginationTable
          data={data}
          columns={columns}
          sizePerPage={25}
          page={page}
          onTableChange={handleTableChange}
          totalSize={totalResults}
          hover
          wrapperClasses="table-responsive"
        />
      </React.Fragment>
    );
  };

  return (
    <div>
      <ActionsModal
        show={showModal}
        handleClose={handleClose}
        selectedMember={selectedMember}
        data={data}
        setData={setData}
      />
      <AddMembershipModal
        show={showAddMembershipModal}
        onHide={() => setShowAddMembershipModal(false)}
        user={selectedMember}
      />
      <Card>
        <Card.Header>
          <div className="d-flex flex-row justify-content-between">
            <div>
              <h4 className="text-primary">Clientes</h4>
              Administra personas que se registren en tu gimnasio.
            </div>
            <div className=" d-none d-lg-block">
              <div className="d-flex flex-row">
                <div
                  style={{ width: "85px" }}
                  className="border rounded border-primary p-1 text-center text-primary mr-1"
                >
                  <div>
                    <small>Total</small>
                  </div>
                  <div>{totalResults}</div>
                </div>
                <div
                  style={{ width: "85px" }}
                  className="border rounded border-primary p-1 text-center text-primary  mr-1"
                >
                  <div>
                    <small>Verificados</small>
                  </div>
                  <div>{stats.verified}</div>
                </div>
                <div
                  style={{ width: "85px" }}
                  className="border rounded border-primary p-2 text-center text-primary  mr-1"
                >
                  <div>
                    <small>Vigentes</small>
                  </div>
                  <div>{stats.active}</div>
                </div>
                <div
                  style={{ width: "85px" }}
                  className="border rounded border-primary p-2 text-center text-primary  "
                >
                  <div>
                    <small>Vencidos</small>
                  </div>
                  <div>{stats.inactive}</div>
                </div>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>{renderTable()}</Card.Body>
      </Card>
    </div>
  );
};
const mapStateToProps = state => {
  return { selectedGym: state.selectedGym };
};

export default connect(mapStateToProps)(Members);
