import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const DaysRepeatCheckboxes = ({ handleChange, values }) => {
  return (
    <div>
      <Row>
        <Col>
          <Form.Group className="text-center">
            <Form.Label className="text-left">
              Repetir en estos dias de la semana{" "}
              <small className="text-secondary">(opcional)</small>
            </Form.Label>
            <br />
            <Form.Check
              id="monCheckbox"
              inline
              label="Lun"
              type="checkbox"
              name="monday"
              onChange={handleChange}
              checked={values.monday}
            />
            <Form.Check
              inline
              label="Mar"
              type="checkbox"
              name="tuesday"
              onChange={handleChange}
              checked={values.tuesday}
            />
            <Form.Check
              inline
              label="Mie"
              type="checkbox"
              name="wednesday"
              onChange={handleChange}
              checked={values.wednesday}
            />
            <Form.Check
              inline
              label="Jue"
              type="checkbox"
              name="thursday"
              onChange={handleChange}
              checked={values.thursday}
            />
            <Form.Check
              inline
              label="Vie"
              type="checkbox"
              name="friday"
              onChange={handleChange}
              checked={values.friday}
            />
            <Form.Check
              inline
              label="Sab"
              type="checkbox"
              name="saturday"
              onChange={handleChange}
              checked={values.saturday}
            />
            <Form.Check
              inline
              label="Dom"
              type="checkbox"
              name="sunday"
              onChange={handleChange}
              checked={values.sunday}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default DaysRepeatCheckboxes;
