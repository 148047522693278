import React, {useEffect, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";
import {format, startOfDay, endOfDay, parse} from "date-fns";
import {Row, Col, Card, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faCircle, faEdit,
  faStream,
  faTrashAlt,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import {es} from "date-fns/locale";
import axios from "axios";

import LoadingRoller from "../misc/LoadingRoller";
import safeGymApi from "../../apis/safegym";
import {getAuthHeader} from "../../helpers/tokens";
import PeopleListModal from "./PeopleListModal";
import {sortCaret} from "../../helpers/sortCaret";
import CreateRsvpModal from "./CreateRsvpModal";
import DeleteRsvpModal from "./DeleteRsvpModal";
import DeleteTimeslotModal from "./DeleteTimeslotModal";
import {dateFormatter} from "../../helpers/cellFormaters";
import EditTimeSlotModal from "./EditTimeSlotModal";

const Reservations = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [showUserListModal, setShowUserListModal] = useState(false);
  const [showNewRsvpModal, setShowNewRsvpModal] = useState(false);
  const [showDeleteTimeslotModal, setShowDeleteTimeslotModal] = useState(false);
  const [showDeleteRsvpModal, setShowDeleteRsvpModal] = useState(false);
  const [showEditTimeSlotModal, setShowEditTimeSlotModal] = useState(false);
  const [showModalType, setShowModalType] = useState("");
  const [selectedTimeslot, setSelectedTimeslot] = useState();
  const [hiddenRows, setHiddenRows] = useState([]);
  const [selectedRsvp, setSelectedRsvp] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const nameFormatter = (cell, row) => {
    return (
      <span className="d-flex align-items-center">
        <span
          className="mr-2"
          style={{color: cell.hex_color, fontSize: "7px"}}
        >
          <FontAwesomeIcon icon={faCircle}/>
        </span>
        {cell.name}
      </span>
    );
  };

  const viewListFormatter = (cell, row) => {
    return (
      <div>
        <Button
          className="pb-0"
          value={cell}
          variant="link"
          onClick={() => {
            setSelectedTimeslot(row);
            setShowModalType("list");
          }}
        >
          <i className="mr-1">
            <FontAwesomeIcon icon={faStream}/>
          </i>
          Lista
        </Button>
        <Button
          className="pb-0 pl-2 text-secondary"
          variant="link"
          value={cell}
          onClick={() => {
            setSelectedTimeslot(row);
            setShowModalType("create");
          }}
        >
          <i className="mr-1">
            <FontAwesomeIcon icon={faUserPlus}/>
          </i>
          Crear{" "}
        </Button>
        <Button
          className="pb-0 pl-2 text-primary"
          variant="link"
          value={cell}
          onClick={() => {
            setSelectedTimeslot(row);
            setShowModalType("edit");
          }}
        >
          <i className="mr-1">
            <FontAwesomeIcon icon={faEdit}/>
          </i>
        </Button>
        <Button
          className="pb-0 pl-2 text-danger"
          variant="link"
          value={cell}
          onClick={() => {
            setSelectedTimeslot(row);
            setShowModalType("delete");
          }}
        >
          <i className="mr-1">
            <FontAwesomeIcon icon={faTrashAlt}/>
          </i>
        </Button>
      </div>
    );
  };

  const defaultSorted = [
    {
      dataField: "start_date",
      order: "asc"
    }
  ];

  const columns = [
    {
      text: "Area / Clase",
      dataField: "area_program.area",
      sort: true,
      sortValue: (cell, row) => cell.name,
      sortCaret: sortCaret,
      formatter: nameFormatter,
      headerStyle: {cursor: "pointer"}
    },
    {
      text: "Fecha",
      dataField: "start_date",
      formatter: dateFormatter,
      sort: true,
      sortCaret: sortCaret,
      headerStyle: {cursor: "pointer"}
    },
    {
      text: "Instructor",
      dataField: "area_program.instructor_name",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: {cursor: "pointer"}
    },
    {
      text: "Personas",
      dataField: "reservations_count",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: {cursor: "pointer"},
      formatter: (cell, row) => `${cell} / ${row.area_program.people_limit}`
    },
    {
      text: "Acciones",
      dataField: "id",
      formatter: viewListFormatter
    }
  ];

  useEffect(() => {
    if (!selectedTimeslot) return;
    if (showModalType === "list") {
      setShowUserListModal(true);
    } else if (showModalType === "create") {
      setShowNewRsvpModal(true);
    } else if (showModalType === "edit") {
      setShowEditTimeSlotModal(true);
    } else if (showModalType === "delete") {
      setShowDeleteTimeslotModal(true);
    }
  }, [selectedTimeslot, showModalType]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getReservations = async () => {
      setLoadingData(true);
      const dateFormat = "yyyy-MM-dd'T'HH:mm:ssXX";
      const start = format(startOfDay(selectedDate), dateFormat);
      const end = format(endOfDay(selectedDate), dateFormat);
      const response = await safeGymApi.get(
        `/programs/timeslots/?start=${start}&end=${end}`,
        {headers: getAuthHeader(), cancelToken: source.token}
      );
      if (response.status === 200) {
        setData(response.data);
      }
      setLoadingData(false);
    };
    getReservations();

    return () => {
      source.cancel();
    };
  }, [selectedDate]);

  const renderTable = () => {
    if (loadingData) {
      return <LoadingRoller/>;
    }
    return (
      <BootstrapTable
        keyField="id"
        columns={columns}
        data={data}
        defaultSorted={defaultSorted}
        noDataIndication="No hay datos para mostrar."
        hover
        wrapperClasses="table-responsive"
        hiddenRows={hiddenRows}
      />
    );
  };

  const handleDeleteRsvp = () => {
    setShowUserListModal(false);
    setShowDeleteRsvpModal(true);
  };

  return (
    <Row>
      <PeopleListModal
        show={showUserListModal}
        selectedTimeSlot={selectedTimeslot}
        handleClose={() => {
          setShowUserListModal(false);
          setSelectedTimeslot(null);
        }}
        setSelectedRsvp={setSelectedRsvp}
        handleDeleteRsvp={handleDeleteRsvp}
      />
      <CreateRsvpModal
        show={showNewRsvpModal}
        timeslot={selectedTimeslot}
        onHide={() => setShowNewRsvpModal(false)}
      />
      <EditTimeSlotModal show={showEditTimeSlotModal} onUpdate={(timeslot) => {
        setData(data.map(ts => ts.id === timeslot.id ? timeslot : ts))
      }} onHide={() => {
        setShowEditTimeSlotModal(false);
        setSelectedTimeslot(null)
      }}
                         timeslot={selectedTimeslot}/>
      <DeleteTimeslotModal
        show={showDeleteTimeslotModal}
        timeslot={selectedTimeslot}
        onHide={() => {
          setShowDeleteTimeslotModal(false);
          setSelectedTimeslot(null);
        }}
        hiddenRows={hiddenRows}
        setHiddenRows={setHiddenRows}
      />
      <DeleteRsvpModal
        show={showDeleteRsvpModal}
        timeslot={selectedTimeslot}
        onHide={() => {
          setShowDeleteRsvpModal(false);
          setSelectedTimeslot(null);
        }}
        rsvpData={selectedRsvp}
      />
      <Col>
        <Card className="h-100">
          <Card.Header>
            <h4 className="text-primary">Reservaciones</h4>
            <span>Ver bloques horarios y reservaciones.</span>
          </Card.Header>
          <Card.Body>
            <div className="d-flex justify-content-start align-items-center">
              <DatePicker
                dateFormat="dd 'de' MMMM 'del' yyyy"
                selected={selectedDate}
                onChange={d => setSelectedDate(d)}
                locale={es}
              />
              <p className="my-0 ml-2 text-secondary">
                <i>
                  <FontAwesomeIcon icon={faChevronLeft}/>
                </i>{" "}
                Escoger fecha
              </p>
            </div>
            <div className="py-3"></div>
            {renderTable()}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Reservations;
