import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CreatableSelect from "react-select/creatable";

import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const ClassesSelect = ({
  selectedGym,
  selectClassValue,
  setSelectClassValue,
  placeholder = "Clase"
}) => {
  const [classesOptions, setClassesOptions] = useState([]);
  const [classesLoading, setClassesLoading] = useState(true);

  const createOption = (label, value) => ({ label, value });

  const handleCreateNewClass = async inputValue => {
    setClassesLoading(true);
    const response = await safeGymApi.post(
      "/gym/areas/",
      {
        name: inputValue,
        gym: selectedGym.data.id
      },
      { headers: getAuthHeader() }
    );
    if (response.status === 201) {
      const item = response.data;
      const newOption = createOption(item.name, item.id);
      setClassesOptions([...classesOptions, newOption]);
      setSelectClassValue(newOption);
    } else {
      alert("Error al intentar crear nueva clase");
    }
    setClassesLoading(false);
  };

  const handleClassChange = (newValue, actionMeta) => {
    setSelectClassValue(newValue);
  };

  useEffect(() => {
    const getSelectClassOptions = async () => {
      const response = await safeGymApi.get("/gym/areas/", {
        headers: getAuthHeader()
      });
      if (response.status === 200) {
        const options = response.data.map(item => {
          return createOption(item.name, item.id);
        });
        setClassesOptions(options);
        setClassesLoading(false);
      } else {
        alert("Error al intentar comunicarse con el servidor.");
      }
    };
    getSelectClassOptions();
  }, []);

  return (
    <CreatableSelect
      autoFocus
      required
      value={selectClassValue}
      isLoading={classesLoading}
      options={classesOptions}
      onCreateOption={handleCreateNewClass}
      onChange={handleClassChange}
      placeholder="Ejemplo: Spinning"
      formatCreateLabel={val => `Crear nueva ${placeholder}: ${val}`}
    />
  );
};

const mapStateToProps = state => {
  return { selectedGym: state.selectedGym };
};

export default connect(mapStateToProps)(ClassesSelect);
