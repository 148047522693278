import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faCalendarCheck,
  faUsers,
  faBell,
  faToolbox,
  faMoneyBillAlt,
  faUser,
  faCalendarAlt,
  faIdCard,
  faChartLine
} from "@fortawesome/free-solid-svg-icons";

import logoImg from "../assets/img/logo-small.png";
import "../assets/styles/sidebar.css";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight
} from "@fortawesome/free-regular-svg-icons";

const SidebarNav = ({ activeClassName, selectedGym, setOpenSidebar }) => {
  const [close, setClose] = useState(false);

  return (
    <nav id="sidebar" className={activeClassName}>
      <div className="sidebar-logo ">
        <Image src={logoImg} fluid />
      </div>
      <div className="sidebar-header text-center text-primary">
        <Image
          className="shadow p-1"
          src={selectedGym ? selectedGym.logo_img : ""}
          roundedCircle
          fluid
        />
      </div>
      <ul className="list-unstyled components ">
        <li className="">
          <NavLink to="/home" activeClassName="active">
            <i>
              <FontAwesomeIcon icon={faChartPie} />
            </i>
            <span>Dashboard</span>
          </NavLink>
        </li>

        <div className="secondStep">
          <li className="">
            <NavLink to="/classes" activeClassName="active">
              <i>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </i>
              <span>Horarios</span>
            </NavLink>
          </li>
        </div>

        <li>
          <NavLink to="/reservations" activeClassName="active">
            <i>
              <FontAwesomeIcon icon={faCalendarCheck} />
            </i>
            <span>Reservaciones</span>
          </NavLink>
        </li>
        <li className="notifStep">
          <NavLink to="/notifications" activeClassName="active">
            <i>
              <FontAwesomeIcon icon={faBell} />
            </i>
            <span>Avisos</span>
          </NavLink>
        </li>
        <li className="membersStep">
          <NavLink to="/members" activeClassName="active">
            <i>
              <FontAwesomeIcon icon={faUsers} />
            </i>
            <span>Clientes</span>
          </NavLink>
        </li>
        <li className="">
          <NavLink to="/memberships" activeClassName="active">
            <i>
              <FontAwesomeIcon icon={faIdCard} />
            </i>
            <span>Membresías</span>
          </NavLink>
        </li>
        <li className="">
          <NavLink to="/reports" activeClassName="active">
            <i>
              <FontAwesomeIcon icon={faChartLine} />
            </i>
            <span>Reportes</span>
          </NavLink>
        </li>

        <li className="payStep">
          <NavLink to="/billing" activeClassName="active">
            <i>
              <FontAwesomeIcon icon={faMoneyBillAlt} />
            </i>
            <span>Plan</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/account" activeClassName="active">
            <i>
              <FontAwesomeIcon icon={faUser} />
            </i>
            <span>Cuenta</span>
          </NavLink>
        </li>
        <li className="toolboxStep">
          <NavLink to="/toolbox" activeClassName="active">
            <i>
              <FontAwesomeIcon icon={faToolbox} />
            </i>
            <span>Personalización</span>
          </NavLink>
        </li>
      </ul>
      <div className="text-center d-none d-md-block">
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="sidebarTooltip">
              {`${close ? "Maximizar" : "Minimizar"} sidebar`}
            </Tooltip>
          }
        >
          <span className="" style={{ cursor: "pointer", fontSize: "1.5rem" }}>
            <FontAwesomeIcon
              onClick={() => {
                setClose(!close);
                setOpenSidebar(close);
              }}
              icon={close ? faArrowAltCircleRight : faArrowAltCircleLeft}
            />
          </span>
        </OverlayTrigger>
      </div>

      <ul className="list-unstyled CTAs">
        {/*<li>*/}
        {/*  <a*/}
        {/*    href="http://docs.safegymadmin.com"*/}
        {/*    target="_blank"*/}
        {/*    className="download"*/}
        {/*  >*/}
        {/*    Documentación*/}
        {/*  </a>*/}
        {/*</li>*/}
        <li>
          <NavLink to="/support" activeClassName="active" className="download">
            Soporte
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = state => {
  return { selectedGym: state.selectedGym.data };
};

export default connect(mapStateToProps)(SidebarNav);
