import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Form, Button } from "react-bootstrap";

import TimezoneSelectModal from "./TimezoneSelectModal";
import FacebookLoader from "../misc/FacebookLoader";
import AddressEditModal from "./AddressEditModal";
import PhoneModal from "./PhoneModal";

const GeneralDataCard = ({ selectedGym }) => {
  const [loading, setLoading] = useState(true);
  const [addressData, setAddressData] = useState({});
  const [showTimezoneModal, setShowTimezoneModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  // const [showInvitationCodeModal, setShowInvitationCodeModal] = useState(false);

  // const renderInvitationCodeTooltip = props => (
  //   <Tooltip id="invitCodeTooltip" {...props}>
  //     El código de invitación sirve para que lo puedas compartir con miembros de
  //     tu gimnasio. Esto ayuda filtrar usuarios reales en el proceso de
  //     verificación.
  //   </Tooltip>
  // );

  useEffect(() => {
    if (selectedGym) {
      setAddressData(selectedGym.address);
      setLoading(false);
    }
  }, [selectedGym]);

  const renderForm = () => {
    if (loading) return <FacebookLoader />;
    return (
      <React.Fragment>
        <Form.Group>
          <Form.Label>
            Dirección del gimnasio{" "}
            <Button
              onClick={() => setShowAddressModal(true)}
              className="p-0 ml-2"
              variant="link"
            >
              Editar
            </Button>
          </Form.Label>
          <Form.Control
            type="text"
            value={`${addressData.street} ${addressData.number}. ${addressData.string_address.city}, ${addressData.string_address.state}. ${addressData.string_address.country.name}`}
            disabled
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Numero telefónico del gimnasio{" "}
            <Button
              className="p-0 m-0 ml-2"
              variant="link"
              onClick={() => setShowPhoneModal(true)}
            >
              Editar
            </Button>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Este numero sera visible por usuarios de la app"
            name="phone"
            value={selectedGym.phone}
            readOnly
          />
        </Form.Group>
        {/*<Form.Group>*/}
        {/*  <Form.Label>*/}
        {/*    Código de Invitación{" "}*/}
        {/*    <OverlayTrigger*/}
        {/*      placement="top"*/}
        {/*      delay={{ show: 100, hide: 300 }}*/}
        {/*      overlay={renderInvitationCodeTooltip}*/}
        {/*    >*/}
        {/*      <i>*/}
        {/*        <FontAwesomeIcon color="grey" icon={faQuestionCircle} />*/}
        {/*      </i>*/}
        {/*    </OverlayTrigger>*/}
        {/*    <Button*/}
        {/*      className="p-0 m-0 ml-2"*/}
        {/*      variant="link"*/}
        {/*      onClick={() => setShowInvitationCodeModal(true)}*/}
        {/*    >*/}
        {/*      Editar*/}
        {/*    </Button>*/}
        {/*  </Form.Label>*/}
        {/*  <Form.Control*/}
        {/*    type="text"*/}
        {/*    value={selectedGym.invitation_code}*/}
        {/*    placeholder="Ejemplo: XYZ123"*/}
        {/*    readOnly*/}
        {/*  />*/}
        {/*</Form.Group>*/}
        <Form.Group>
          <Form.Label>
            Zona Horaria{" "}
            <Button
              className="p-0 m-0 ml-2"
              variant="link"
              onClick={() => setShowTimezoneModal(true)}
            >
              Editar
            </Button>
          </Form.Label>
          <Form.Control type="text" disabled value={selectedGym.timezone} />
        </Form.Group>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <TimezoneSelectModal
        show={showTimezoneModal}
        handleClose={() => setShowTimezoneModal(false)}
        selectedGymId={selectedGym.id}
      />
      <AddressEditModal
        show={showAddressModal}
        onHide={() => setShowAddressModal(false)}
        selectedGym={selectedGym}
      />
      <PhoneModal
        show={showPhoneModal}
        onHide={() => setShowPhoneModal(false)}
        selectedGym={selectedGym}
      />
      {/*<InvitationCodeModal*/}
      {/*  show={showInvitationCodeModal}*/}
      {/*  onHide={() => setShowInvitationCodeModal(false)}*/}
      {/*  selectedGym={selectedGym}*/}
      {/*/>*/}
      <Card>
        <Card.Header>
          <h5 className="mb-0 text-primary">Datos Generales</h5>
          <small className="text-secondary mb-0">
            Estos datos aparecen en tu perfil de la aplicación móvil. Te
            recomendamos llenarlos correctamente.
          </small>
        </Card.Header>
        <Card.Body>{renderForm()}</Card.Body>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return { selectedGym: state.selectedGym.data };
};

export default connect(mapStateToProps)(GeneralDataCard);
