import React from "react";
import {
  faSort,
  faSortDown,
  faSortUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const sortCaret = (order, column) => {
  const defaultClass = "ml-2 text-secondary";
  let icon = null;
  if (!order) icon = faSort;
  else if (order === "asc") {
    icon = faSortUp;
  } else if (order === "desc") {
    icon = faSortDown;
  }
  return (
    <span className={defaultClass}>
      <FontAwesomeIcon icon={icon} />
    </span>
  );
};
