import React from "react";

import "../../assets/styles/loaders.css";

const LoadingRoller = () => {
  return (
    <div className="text-center mt-5">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingRoller;
