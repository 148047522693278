import React, { useEffect, useState } from "react";
import Select from "react-select";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Row, Col, Card, Alert } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faExclamationTriangle,
  faLock
} from "@fortawesome/free-solid-svg-icons";

import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import "../../assets/styles/cardSection.css";
import { countries } from "../../data/countries";

const CardSetupForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [countryCode, setCountryCode] = useState(null);

  const showAlert = () => {
    if (showErrorAlert) {
      return (
        <Alert variant="danger">
          <FontAwesomeIcon icon={faExclamationTriangle} /> {errorMessage}
        </Alert>
      );
    }
  };

  return (
    <Row>
      <Col></Col>
      <Col className="text-center">
        {showAlert()}

        <Formik
          initialValues={{
            name: "",
            city: "",
            state: "",
            country: "",
            line1: "",
            line2: "",
            postalCode: "",
            titleError: ""
          }}
          validate={values => {
            const errors = {};
            if (!stripe || !elements || !props.custSecret) {
              errors.titleError =
                "Ocurrió un error. Por favor recargue la pagina.";
            }

            if (!values.name) {
              errors.name = "Campo requerido";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const result = await stripe.confirmCardSetup(props.custSecret, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: values.name,
                  address: {
                    city: values.city,
                    country: countryCode,
                    line1: values.line1,
                    line2: values.line2,
                    postal_code: values.postalCode,
                    state: values.state
                  }
                }
              }
            });
            if (result.error) {
              setErrorMessage(result.error.message);
              setShowErrorAlert(true);
            } else {
              props.toSelectPaymentMethod();
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Card>
                <Card.Header>
                  <h4 className="mt-3 text-secondary">
                    Agregar método de pago.
                  </h4>
                </Card.Header>
                <Card.Body>
                  <ErrorMessage
                    name="titleError"
                    component="div"
                    className="text-danger mb-3"
                  />
                  <Field
                    className="StripeElement"
                    type="text"
                    name="name"
                    placeholder="Nombre de Titular"
                  />
                  <ErrorMessage name="name" component="div" />
                  <Field
                    className="StripeElement"
                    type="text"
                    name="line1"
                    placeholder="Dirección - Linea 1"
                  />
                  <Field
                    className="StripeElement"
                    type="text"
                    name="line2"
                    placeholder="Dirección - Linea 2 (Opcional)"
                  />

                  <Field
                    className="StripeElement"
                    type="text"
                    name="city"
                    placeholder="Ciudad"
                  />
                  <Field
                    className="StripeElement"
                    type="text"
                    name="state"
                    placeholder="Estado"
                  />

                  <Select
                    options={countries}
                    placeholder="País"
                    onChange={({ value }) => setCountryCode(value)}
                  />

                  <div className="mt-3">
                    <CardElement />
                  </div>
                  <div className="text-secondary d-flex flex-row justify-content-start align-items-center pt-3">
                    <div className="border rounded p-1">
                      <i style={{ color: "#4BB543" }}>
                        <FontAwesomeIcon icon={faLock} />
                      </i>
                      <small className="pl-1">SSL Encrypted</small>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <Button
                      className="mr-auto mt-4"
                      disabled={isSubmitting}
                      variant="outline-secondary"
                      size="sm"
                      onClick={props.toSelectPaymentMethod}
                    >
                      <i className="mr-2">
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </i>
                      Atrás
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      variant="success"
                      size="sm"
                      type="submit"
                      className="mt-4"
                    >
                      Siguiente
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Form>
          )}
        </Formik>
      </Col>
      <Col></Col>
    </Row>
  );
};

const NewCard = props => {
  const [custSecret, setCustSecret] = useState(null);

  useEffect(() => {
    const getCustSecret = async () => {
      const { status, data } = await safeGymApi.get("/orders/intent/", {
        headers: getAuthHeader()
      });
      if (status === 200) {
        setCustSecret(data.client_secret);
      } else {
        alert("Ocurrió un error. Por favor recargue la pagina.");
      }
    };
    getCustSecret();
  }, []);

  return (
    <div>
      <CardSetupForm custSecret={custSecret} {...props} />
    </div>
  );
};

export default NewCard;
