import React, { useState} from 'react';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import {addDays, formatISO9075, parse, parseISO} from "date-fns";
import {Formik, Form as FormikForm} from "formik";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safeGymApi";
import {getAuthHeader} from "../../helpers/tokens";

const EditTimeSlotModal = ({show, onHide, timeslot, onUpdate}) => {
  const [success, setSuccess] = useState(null);

  const renderAlert = () => {
    if (success === null) return;
    const message = success
      ? "Horario actualizado."
      : "Ocurrió un error al actualizar el horario. Solo es posible actualizar horarios futuros.";
    return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
  };

  const getInitialStartDate = () => {
    let start = parseISO(timeslot.start_date)
    if (timeslot.start_time !== null) {
      return parse(timeslot.start_time, "HH:mm:ss", start)
    }
    return start
  }

  if (!timeslot) return <React.Fragment></React.Fragment>;
  return (
    <Formik
      initialValues={{start_date: getInitialStartDate()}}
      onSubmit={(values, {setSubmitting}) => {
        setSuccess(null)
        setSubmitting(true)
        safeGymApi.patch(`/programs/timeslot/${timeslot.id}/`, {
            start_date: values.start_date,
            start_time: formatISO9075(values.start_date, {representation: "time"})
          },
          {headers: getAuthHeader()}
        ).then(r => {
          setSubmitting(false)
          setSuccess(true)
          onUpdate(r.data)
        }).catch(err => {
          setSubmitting(false)
          setSuccess(false)
        })
      }}
    >
      {({isSubmitting, values, setFieldValue, handleSubmit}) => (<FormikForm>
          <Modal show={show} onHide={onHide}>
            <Modal.Header>Editar hora</Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <Form.Group>
                <Form.Label>Fecha</Form.Label>
                <br/>
                <DatePicker
                  disabled={true}
                  className="form-control"
                  selected={values.start_date}
                  // onChange={date => setStartDate(date)}
                  dateFormat="EEEE dd 'de' MMMM 'del' yyyy"
                  locale={es}
                  minDate={new Date()}
                  maxDate={addDays(new Date(), 90)}
                  name="start_date"
                />
              </Form.Group>
              <Form.Group className="w-100">
                <Form.Label>Hora de Inicio</Form.Label>
                <br/>
                <DatePicker
                  className="form-control w-100 m-0"
                  selected={values.start_date}
                  onChange={date => setFieldValue("start_date", date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Hora"
                  dateFormat="h:mm aa"
                  // name="time"
                  customInput={<input className="form-control"/>}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onHide}>
                Cerrar
              </Button>
              <Button onClick={() => handleSubmit()} type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader/> : "Guardar"}
              </Button>
            </Modal.Footer>
          </Modal>
        </FormikForm>
      )}
    </Formik>
  )

};

export default EditTimeSlotModal;
