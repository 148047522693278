import React, { useState } from "react";
import { format, startOfMonth, endOfMonth } from "date-fns";
import { Row, Col, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import { es } from "date-fns/locale";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";

const CalendarTopBar = ({ calendar, showModal, getSchedules, loading }) => {
  const [renderedDate, setRenderedDate] = useState(
    format(new Date(), "MMMM yyyy", { locale: es })
  );

  const btnStyle = {
    backgroundColor: "#51df81",
    borderColor: "#51df81"
  };

  const handleNextBtn = () => {
    calendar.next();
    const newDate = calendar.getDate()._date;
    setRenderedDate(format(newDate, "MMMM yyyy", { locale: es }));
    const start = startOfMonth(newDate);
    const end = endOfMonth(newDate);
    getSchedules(start, end);
  };

  const handlePrevBtn = () => {
    calendar.prev();
    const newDate = calendar.getDate()._date;
    setRenderedDate(format(newDate, "MMMM yyyy", { locale: es }));
    const start = startOfMonth(newDate);
    const end = endOfMonth(newDate);
    getSchedules(start, end);
  };

  return (
    <Row className="pb-3">
      <Col>
        <div className="d-flex flex-row align-items-center p-1">
          <Button
            id="crearHorarioBtn"
            className="step4"
            style={btnStyle}
            onClick={showModal}
          >
            + Crear Horario
          </Button>
          <Link to="/classes/table">
            <Button variant="outline-primary" className="ml-2">
              <i style={{ fontSize: "14px" }} className="mr-1">
                <FontAwesomeIcon icon={faEdit} />
              </i>
              Editar Horarios
            </Button>
          </Link>
          <DropdownButton
            id="dropDownViewSelect"
            variant="outline-secondary"
            title="Tipo de calendario"
            className="ml-2"
          >
            <Dropdown.Item onSelect={() => calendar.changeView("day", true)}>
              Dia
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => calendar.changeView("week", true)}>
              Semanal
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => calendar.changeView("month", true)}>
              Mensual
            </Dropdown.Item>
          </DropdownButton>
          <Button
            onClick={() => calendar.today()}
            variant="outline-secondary"
            className="ml-2"
          >
            Hoy
          </Button>
          <Button
            className="ml-2"
            variant="outline-secondary"
            onClick={handlePrevBtn}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Button
            className="ml-1"
            variant="outline-secondary"
            onClick={handleNextBtn}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
          {loading ? (
            <div className="align-self-end ml-1">
              <ReactLoading
                type="bubbles"
                height={17}
                width={20}
                color="#007bff"
              />
            </div>
          ) : (
            <React.Fragment />
          )}

          <h5 className="ml-auto  text-primary">{renderedDate}</h5>
        </div>
      </Col>
    </Row>
  );
};

export default CalendarTopBar;
