import React, { useState } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";

const ForgotPasswordModal = ({ show, onHide }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const submit = async () => {
    setIsSubmitting(true);

    safeGymApi
      .post("/auth/users/reset_password/", {
        email: email
      })
      .then(() => {
        setSuccess(true);
        setShowAlert(true);
      })
      .catch(() => {
        setShowAlert(true);
      });

    setIsSubmitting(false);
  };

  const renderAlert = () => {
    if (!showAlert) return;
    return (
      <Alert variant={success ? "success" : "danger"}>
        {success
          ? "Se ha enviado un correo para recuperar la contraseña"
          : "Error: Correo electrónico no encontrado."}
      </Alert>
    );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>Recuperar Contraseña</Modal.Header>
      <Modal.Body>
        {renderAlert()}
        <Form.Group>
          <Form.Label>Correo Electrónico de la cuenta</Form.Label>
          <Form.Control
            onChange={e => setEmail(e.target.value)}
            placeholder="Correo Electrónico"
            type="text"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="outline-secondary">
          Cerrar
        </Button>
        <Button onClick={submit} variant="success">
          {isSubmitting ? <EllipsisLoader /> : "Recuperar Contraseña"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ForgotPasswordModal;
