import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import {
  Card,
  Form,
  Tooltip,
  OverlayTrigger,
  Button,
  Alert
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faQuestionCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { ErrorMessage, Field, Formik } from "formik";
import { object, number } from "yup";

import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import EllipsisLoader from "../misc/EllipsisLoader";

const RsvpSettingsCard = ({ selectedGym }) => {
  const [initialValues, setInitialValues] = useState({});
  const [loadingValues, setLoadingValues] = useState(true);
  const [showText, setShowText] = useState(false);
  const [successResponse, setSuccessResponse] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  const prevDaysTooltip = props => (
    <Tooltip {...props}>
      Son los dias previos a una clase/area en los que se les permite a miembros
      de tu gimnasio hacer una reservación.
    </Tooltip>
  );

  const prevCancelMinutesTooltip = props => (
    <Tooltip {...props}>
      Son los minutos previos a que comience el bloque reservado, donde un
      miembro del gimnasio se le permite realizar una cancelación.
    </Tooltip>
  );

  const maxReservationsTooltip = props => (
    <Tooltip {...props}>
      Es el numero maximo de reservaciones que un miembro de tu gimnasio puede
      realizar en un solo dia.
    </Tooltip>
  );

  const maxReservationsWeekTooltip = props => (
    <Tooltip {...props}>
      Es el numero maximo de reservaciones que un miembro de tu gimnasio puede
      realizar a la semana.
    </Tooltip>
  );

  const membershipActiveTooltip = props => (
    <Tooltip {...props}>
      Todos los usuarios requerirán tener una membresía vigente para poder
      realizar reservaciones. Si lo activas, tendrás que actualizar las fechas
      de expiración de los usuarios a través de esta plataforma.
    </Tooltip>
  );

  const creditsRequiredTooltip = props => (
    <Tooltip {...props}>
      Los usuarios requerirán contar con créditos para realizar reservaciones.
      Útil para gimnasios donde los clientes compran un número determinado de
      clases.
    </Tooltip>
  );

  const isDataChanged = values => {
    if (isEqual(values, initialValues)) {
      setShowSaveBtn(false);
    } else {
      setShowSaveBtn(true);
    }
  };

  const renderMessage = () => {
    if (!showText) return;
    if (successResponse) {
      return (
        <Alert variant="success" className="d-flex flex-row">
          <span>Los datos se actualizaron con éxito</span>
          <Button
            variant="link"
            onClick={() => setShowText(false)}
            className="ml-auto text-secondary p-0"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Alert>
      );
    }
    return (
      <Alert variant="danger">
        Ocurrió un error al intentar actualizar los datos.
      </Alert>
    );
  };

  const schema = object({
    max_rsvp_per_day: number()
      .required("Campo requerido")
      .positive("Solo números positivos")
      .max(100, "El numero maximo para este campo es 100")
      .integer("Solo números enteros"),
    max_rsvp_per_week: number()
      .required("Campo requerido")
      .positive("Solo números positivos")
      .max(100, "El numero maximo para este campo es 100")
      .integer("Solo números enteros"),
    max_rsvp_prior_days: number()
      .required("Campo requerido")
      .positive("Solo números positivos")
      .max(100, "El numero maximo para este campo es 100")
      .integer("Solo números enteros"),
    max_prior_cancellation_minutes: number()
      .required("Campo requerido")
      .positive("Solo números positivos")
      .max(30000, "El numero maximo para este campo es 30,000")
      .integer("Solo números enteros")
  });

  const renderForm = () => {
    if (loadingValues) {
      return <EllipsisLoader />;
    }
    return (
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        validate={values => isDataChanged(values)}
        onSubmit={(values, { setSubmitting }) => {
          safeGymApi
            .put(`/gym/permissions/${selectedGym.permissions["id"]}/`, values, {
              headers: getAuthHeader()
            })
            .then(result => {
              setSuccessResponse(true);
              setShowText(true);
            })
            .catch(err => {
              setShowText(true);
              setSuccessResponse(false);
            });
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {renderMessage()}
            <Form.Group className="toolboxStep2">
              <Form.Label>
                Reservaciones máximas por{" "}
                <span className="text-primary">día</span> por miembro
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 300 }}
                  overlay={maxReservationsTooltip}
                >
                  <i className="ml-1 text-secondary">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="number"
                name="max_rsvp_per_day"
                value={values.max_rsvp_per_day}
                onChange={handleChange}
              />
              <div className="text-danger">
                <ErrorMessage name="max_rsvp_per_day" />
              </div>
            </Form.Group>
            <Form.Group className="toolboxStep3">
              <Form.Label>
                Reservaciones máximas por{" "}
                <span className="text-primary"> semana</span> por miembro
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 300 }}
                  overlay={maxReservationsWeekTooltip}
                >
                  <i className="ml-1 text-secondary">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="number"
                name="max_rsvp_per_week"
                value={values.max_rsvp_per_week}
                onChange={handleChange}
              />
              <div className="text-danger">
                <ErrorMessage name="max_rsvp_per_week" />{" "}
              </div>
            </Form.Group>
            <Form.Group className="prevRsvpDays">
              <Form.Label>
                Dias previos permitidos para reservar
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 300 }}
                  overlay={prevDaysTooltip}
                >
                  <i className="ml-1 text-secondary">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="number"
                name="max_rsvp_prior_days"
                value={values.max_rsvp_prior_days}
                onChange={handleChange}
              />
              <div className="text-danger">
                <ErrorMessage name="max_rsvp_prior_days" />
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Minutos máximos antes del bloque para cancelar una reservación
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 300 }}
                  overlay={prevCancelMinutesTooltip}
                >
                  <i className="ml-1 text-secondary">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="number"
                name="max_prior_cancellation_minutes"
                value={values.max_prior_cancellation_minutes}
                onChange={handleChange}
              />
              <div className="text-danger">
                <ErrorMessage name="max_prior_cancellation_minutes" />
              </div>
            </Form.Group>
            {/*<Form.Group className="">*/}
            {/*  <div className="d-flex flex-row align-items-center">*/}
            {/*    <p className="my-0 mx-2">Autogenerar ID's de socio:</p>*/}
            {/*    <Field className="p-0" name="use_safegym_ids" type="checkbox" />*/}
            {/*  </div>*/}
            {/*</Form.Group>*/}
            <OverlayTrigger
              placement="left"
              delay={{ show: 100, hide: 300 }}
              overlay={membershipActiveTooltip}
            >
              <Form.Group className="membershipRequired">
                <div className="d-flex flex-row align-items-center">
                  <i className="text-warning">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </i>
                  <p className="my-0 mx-2">
                    Usuarios requieren membresía vigente:
                  </p>
                  <Field
                    className="p-0"
                    name="requires_active_membership"
                    type="checkbox"
                  />
                </div>
              </Form.Group>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              delay={{ show: 100, hide: 300 }}
              overlay={creditsRequiredTooltip}
            >
              <Form.Group className="creditsSystemCheckboxStep">
                <div className="d-flex flex-row align-items-center">
                  <i className="text-warning">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </i>
                  <p className="my-0 mx-2">Activar sistema de créditos:</p>
                  <Field
                    className="p-0"
                    name="requires_credits"
                    type="checkbox"
                  />
                </div>
              </Form.Group>
            </OverlayTrigger>

            <Button
              type="submit"
              size="sm"
              disabled={isSubmitting}
              hidden={!showSaveBtn}
            >
              {isSubmitting ? <EllipsisLoader /> : "Guardar Cambios"}
            </Button>
          </Form>
        )}
      </Formik>
    );
  };

  useEffect(() => {
    const getRsvpSettings = async () => {
      if (!selectedGym) return;
      const { status, data } = await safeGymApi.get(
        `/gym/permissions/${selectedGym.permissions["id"]}/`,
        {
          headers: getAuthHeader()
        }
      );
      if (status === 200) {
        setInitialValues(data);
      }
      setLoadingValues(false);
    };
    getRsvpSettings();
  }, [selectedGym]);

  return (
    <Card>
      <Card.Header>
        <h4 className="text-primary mb-0">Ajustes de Reservaciones</h4>
      </Card.Header>
      <Card.Body>{renderForm()}</Card.Body>
    </Card>
  );
};

const mapStateToProps = state => {
  return { selectedGym: state.selectedGym.data };
};

export default connect(mapStateToProps)(RsvpSettingsCard);
