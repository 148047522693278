import React, { useState } from "react";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import {format, parse, parseISO} from "date-fns";
import { es } from "date-fns/locale";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const CreateRsvpModal = ({ show, onHide, timeslot }) => {
  const [success, setSuccess] = useState(null);

  const renderAlert = () => {
    if (success === null) return;
    const message = success
      ? "Se ha creado la reservación con éxito."
      : "Ocurrió un error al intentar crear la reservación";
    return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
  };

  const getDateText = () => {
    let start = parseISO(timeslot.start_date)
    if (timeslot.start_time !== null) {
      start = parse(timeslot.start_time, "HH:mm:ss", start)
    }
    return format(
      start,
      "EEEE d 'de' MMMM 'del' yyyy'.' h:mm aaa",
      { locale: es }
    )
  }

  if (!timeslot) return <React.Fragment></React.Fragment>;
  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        initialValues={{
          timeslot: timeslot.id,
          first_name: "",
          email: "",
          phone: ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          safeGymApi
            .post("/programs/rsvp/admincreate/", values, {
              headers: getAuthHeader()
            })
            .then(res => {
              setSuccess(true);
              setSubmitting(false);
            })
            .catch(err => {
              setSuccess(false);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header>
              <div className="text-primary">Crear Reservación</div>
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <div>
                <strong>Bloque:</strong>
                <div style={{ color: timeslot.area_program.area.hex_color }}>
                  {timeslot.area_program.area.name}
                </div>
                <div>
                  {getDateText()}
                </div>
              </div>
              <hr />
              <Form.Group>
                <Form.Label>
                  <span className="text-danger">*</span>Nombre
                </Form.Label>
                <Field
                  className="form-control"
                  name="first_name"
                  placeholder="Nombre de la persona"
                />
                <div className="text-danger">
                  <ErrorMessage name="first_name" />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>E-Mail</Form.Label>
                <Field
                  className="form-control"
                  name="email"
                  placeholder="Correo Electrónico"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Teléfono</Form.Label>
                <Field
                  className="form-control"
                  name="phone"
                  placeholder="Teléfono"
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={onHide}>
                Cerrar
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Crear"}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateRsvpModal;
