import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Modal, Alert, Form, Row, Col, Card } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import { addDays, format, parseISO, endOfDay, formatISO } from "date-fns";
import { es } from "date-fns/locale";

import EllipsisLoader from "../misc/EllipsisLoader";
import { paymentMethods } from "../../data/paymentMethods";
import { getAuthHeader } from "../../helpers/tokens";
import safegym from "../../apis/safegym";
import DatePicker from "react-datepicker";

const AddUserMembershipModal = ({ show, onHide, user }) => {
  const [success, setSuccess] = useState(null);
  const [plans, setPlans] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [expireDate, setExpireDate] = useState(new Date());
  const [dateAfter, setDateAfter] = useState("today");
  const [customDate, setCustomDate] = useState(new Date());

  useEffect(() => {
    safegym
      .get("/memberships/plans/", { headers: getAuthHeader() })
      .then(resp => {
        const options = resp.data.map(plan => ({
          value: plan.id,
          label: plan.title
        }));
        setOptions(options);
        setPlans(resp.data);
      })
      .catch(() => alert("Error"));
  }, []);

  const handleClose = () => {
    setSuccess(null);
    setSelectedPlan(null);
    setPaymentMethod(null);
    setDateAfter("today");
    onHide();
  };

  const renderAlert = message => {
    if (success === null) return;
    if (!message) {
      message = success
        ? "Se ha agregado el plan de membresía con éxito."
        : "Ocurrió un error. Intente de nuevo.";
    }
    return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
  };

  const onChangeRadioOptions = e => {
    setDateAfter(e.target.value);
  };

  useEffect(() => {
    if (plans.length > 0) {
      if (!selectedPlan) {
        return;
      }
      const daysDuration = plans.find(e => e.id === selectedPlan).days_duration;
      let date;
      if (dateAfter === "today") {
        date = new Date();
      } else if (dateAfter === "afterCurrentExpiry") {
        date = parseISO(user.membership.expiration);
      } else {
        date = customDate;
      }
      date = addDays(date, daysDuration);
      setExpireDate(date);
    }
  }, [plans, dateAfter, selectedPlan, customDate, user]);

  const renderMembershipDetails = () => {
    if (!selectedPlan) return <React.Fragment />;
    const plan = plans.find(e => e.id === selectedPlan);

    return (
      <div>
        <Row>
          <Col lg={5}>
            <p className="mb-0 mt-2">
              <strong>Detalles</strong>
            </p>
            <p className="m-0">
              Vigencia:
              <strong> {plan.days_duration} dias</strong>
            </p>
            <p className="m-0">
              Créditos:
              <strong>
                {" "}
                {plan.credits_count === 0 ? "N/A" : plan.credits_count}
              </strong>
            </p>
            <p className="">
              Precio:
              <strong> $ {plan.price}</strong>
            </p>
          </Col>
          <Col lg={7}>
            <p className="m-0">
              <strong>Cliente</strong>
            </p>
            <p className="m-0">{user.first_name}</p>
            <p className="m-0">{user.email}</p>
            <p className="m-0">{user.phone}</p>
          </Col>
        </Row>
        {plan.is_credits_plan ? (
          <React.Fragment />
        ) : (
          <p className="">
            <strong>Vencimiento de membresía actual: </strong>
            {"   "}
            {user.membership === null ? (
              "N/A"
            ) : (
              <span className="text-primary">
                {format(parseISO(user.membership.expiration), "dd-MMM-yyyy", {
                  locale: es
                })}
              </span>
            )}
          </p>
        )}

        <Form.Group onChange={onChangeRadioOptions}>
          <Form.Label>
            <strong>
              {plan.is_credits_plan
                ? "Vigencia de créditos inicia a partir de:"
                : "Definir nueva fecha de vencimiento a partir de:"}
            </strong>
          </Form.Label>

          <Form.Check
            defaultChecked={true}
            value="today"
            type="radio"
            label="a partir de hoy"
            name="expiryAfter"
          />
          {plan.is_credits_plan ? (
            <React.Fragment />
          ) : (
            <Form.Check
              value="afterCurrentExpiry"
              type="radio"
              label="a partir de la fecha de vencimiento actual"
              name="expiryAfter"
            />
          )}
          <Form.Check
            value="custom"
            type="radio"
            label="Manualmente"
            name="expiryAfter"
          />
        </Form.Group>
        <div className="d-inline-flex my-2">
          {plan.is_credits_plan
            ? "Créditos vencerán el:"
            : "Nueva fecha de vencimiento:"}
          {dateAfter === "custom" ? (
            <DatePicker
              className="form-control"
              dateFormat="dd 'de' MMMM 'del' yyyy"
              selected={customDate}
              onChange={d => setCustomDate(d)}
              locale={es}
            />
          ) : (
            <strong className="ml-2 text-success">
              {format(expireDate, "dd 'de' MMMM 'del' yyyy", { locale: es })}
            </strong>
          )}
        </div>
        <Form.Group>
          <Form.Label>
            <strong>Método de pago</strong>
          </Form.Label>
          <Select
            options={paymentMethods}
            placeholder="Método de Pago"
            onChange={({ value }) => setPaymentMethod(value)}
          />
        </Form.Group>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik
        initialValues={{}}
        validate={() => {
          if (selectedPlan === null) {
            setSuccess(false);
            setAlertMessage("Es necesario escoger una membresía");
            return { error: "error" };
          }
          if (paymentMethod === null) {
            setSuccess(false);
            setAlertMessage("Es necesario escoger un método de pago");
            return { error: "error" };
          }
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSuccess(null);
          setAlertMessage(null);
          safegym
            .post(
              "/memberships/transactions/",
              {
                user: user.id,
                membership_plan: selectedPlan,
                payment_method: paymentMethod,
                expiration: formatISO(endOfDay(expireDate))
              },
              {
                headers: getAuthHeader()
              }
            )
            .then(res => {
              setSuccess(true);
              setSubmitting(false);
            })
            .catch(err => {
              setSuccess(false);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header>
              <div className="text-primary">
                {user.membership === null ? "Agregar" : "Renovar"} Membresía
              </div>
            </Modal.Header>
            <Modal.Body style={{ fontSize: "12px" }}>
              {renderAlert(alertMessage)}
              <Form.Group>
                <Form.Label>
                  <strong>Membresía</strong>
                </Form.Label>
                <Select
                  options={options}
                  placeholder="Seleccionar membresía"
                  onChange={({ value }) => setSelectedPlan(value)}
                  noOptionsMessage={() =>
                    'Debes crear un plan en la sección "Membresías" del menú'
                  }
                />
              </Form.Group>
              {renderMembershipDetails()}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button type="submit" disabled={isSubmitting || success}>
                {isSubmitting ? (
                  <EllipsisLoader />
                ) : user.membership === null ? (
                  "Agregar"
                ) : (
                  "Renovar"
                )}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUserMembershipModal;
