import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Support = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Row>
      <Col lg={8}>
        <h2 className="pb-4 text-secondary">Soporte</h2>
        <Card>
          <Card.Header>
            <h4 className="text-primary">Contáctanos</h4>
          </Card.Header>
          <Card.Body>
            En SafeGym siempre queremos brindar la mejor atención al cliente. No
            dudes en contactarnos para cualquier duda o sugerencia.
            <div className="pt-4">
              <div className="d-flex flex-row">
                <strong className="mr-3">E-Mail</strong>
                <a
                  className="text-primary"
                  href="mailto: soporte@safegymapp.com"
                >
                  soporte@safegymapp.com
                </a>
              </div>
              <div className="py-3">
                <Button
                  className="px-5 font-weight-bold"
                  variant="success"
                  href="https://api.whatsapp.com/send?phone=526621323750&text=%C2%A1Hola%2C%20quiero%20saber%20m%C3%A1s%20de%20Safe%20Gym!"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                  <span>&nbsp; WhatsApp</span>
                </Button>
              </div>
            </div>
            <Button
              className="px-5 font-weight-bold"
              variant="info"
              href="https://t.me/safegym"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTelegram} />
              <span>&nbsp; Telegram</span>
            </Button>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4}></Col>
    </Row>
  );
};

export default Support;
