import { SET_AUTH_HEADER } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_AUTH_HEADER:
      return { ...state, Authorization: `Bearer ${action.payload}` };
    default:
      return state;
  }
};
