import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Card, Col, Row } from "react-bootstrap";
import {
  parseISO,
  format,
  endOfDay,
  startOfDay,
  formatISO,
  isBefore
} from "date-fns";
import { es } from "date-fns/locale";

import safegym from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import RemotePaginationTable from "../../helpers/RemotePaginationTable";
import LoadingRoller from "../misc/LoadingRoller";

const MembershipReports = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalSize, setTotalSize] = useState(null);
  const [page, setPage] = useState(1);
  const [loadingData, setLoadingData] = useState(true);
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const [totals, setTotals] = useState([0, 0, 0]);

  useEffect(() => {
    if (isBefore(startDate, endDate)) {
      const start = formatISO(startDate);
      const end = formatISO(endDate);
      safegym
        .get(
          `/memberships/transactions/?page=${page}&start=${start}&end=${end}`,
          {
            headers: getAuthHeader()
          }
        )
        .then(resp => {
          setTotalSize(resp.data.count);
          setTransactions(resp.data.results);
          setLoadingData(false);
        });
      safegym
        .get(`/memberships/reports/totals/?start=${start}&end=${end}`, {
          headers: getAuthHeader()
        })
        .then(resp => {
          setTotals([
            resp.data.cash_total,
            resp.data.card_total,
            resp.data.other_total
          ]);
        });
    }
  }, [page, startDate, endDate]);

  const getTransactions = page => {
    setLoadingData(true);
    setTransactions([]);
    const start = formatISO(startDate);
    const end = formatISO(endDate);
    console.log("ok");
    safegym
      .get(
        `/memberships/transactions/?page=${page}&start=${start}&end=${end}`,
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        setTotalSize(resp.data.count);
        setTransactions(resp.data.results);
        setLoadingData(false);
      });
    safegym
      .get(`/memberships/reports/totals/?start=${start}&end=${end}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        setTotals([
          resp.data.cash_total,
          resp.data.card_total,
          resp.data.other_total
        ]);
      });
  };

  const handleTableChange = (type, { page }) => {
    setPage(page);
    getTransactions(page);
  };

  const userFormatter = (cell, row) => {
    return <div>{!cell.first_name ? cell.email : cell.first_name}</div>;
  };

  const transactionColumns = [
    {
      text: "ID Cliente",
      dataField: "user.external_gym_id"
    },
    {
      text: "Cliente",
      dataField: "user",
      formatter: userFormatter
    },
    {
      text: "Concepto",
      dataField: "membership_plan.title"
    },
    {
      text: "Monto",
      dataField: "membership_plan.price"
    },
    {
      text: "Método de pago",
      dataField: "payment_method"
    },
    {
      text: "Atendió",
      dataField: "seller",
      formatter: userFormatter
    },
    {
      text: "Fecha",
      dataField: "created_on",
      formatter: cell => (
        <span>
          {format(parseISO(cell), "dd-MMM-yy", { locale: es })}{" "}
          <span className="text-primary">
            {format(parseISO(cell), "HH:mm")}
          </span>
        </span>
      )
    }
  ];

  const renderTable = () => {
    if (loadingData) {
      return <LoadingRoller />;
    }
    return (
      <React.Fragment>
        <div className="d-flex mb-2 justify-content-between align-items-end">
          <div className="d-inline-flex">
            <div>
              <small>Inicio</small>
              <DatePicker
                selected={startDate}
                onChange={d => {
                  setPage(1);
                  setStartDate(startOfDay(d));
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd 'de' MMMM 'del' yyyy"
                locale={es}
              />
            </div>
            <div>
              <small>Final</small>
              <DatePicker
                selected={endDate}
                onChange={d => {
                  setPage(1);
                  setEndDate(endOfDay(d));
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd 'de' MMMM 'del' yyyy"
                locale={es}
              />
            </div>
          </div>
          <div className="d-inline-flex">
            <div className="border rounded p-2 text-center">
              Total
              <p>
                <strong>$ {totals.reduce((a, b) => a + b)}</strong>
              </p>
            </div>
            <div className="border rounded ml-1  p-2 text-center">
              Efectivo
              <p>
                <strong>$ {totals[0]}</strong>
              </p>
            </div>
            <div className="border rounded ml-1  p-2 text-center">
              Tarjetas
              <p>
                <strong>$ {totals[1]}</strong>
              </p>
            </div>
            <div className="border rounded ml-1 p-2 text-center">
              Otros
              <p>
                <strong>$ {totals[2]}</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="text-secondary">{`${totalSize} resultados`}</div>
        <RemotePaginationTable
          data={transactions}
          page={page}
          columns={transactionColumns}
          sizePerPage={25}
          onTableChange={handleTableChange}
          totalSize={totalSize}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card className="mb-4">
            <Card.Header>
              <h4 className="text-primary">$ Reporte de Ventas</h4>
              Historial de ventas de membresías
            </Card.Header>
            <Card.Body>{renderTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MembershipReports;
