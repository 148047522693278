import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";

const RemotePaginationTable = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns
}) => (
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div>
          <BootstrapTable
            bootstrap4
            remote
            hover
            keyField="id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            {...paginationTableProps}
            noDataIndication="No hay datos para mostrar."
            wrapperClasses="table-responsive"
          />
          <div>
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  </div>
);
export default RemotePaginationTable;
