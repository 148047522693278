import React from "react";
import Calendar from "tui-calendar";
import {Card, Button, Row, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTable} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {
  format,
  startOfMonth,
  endOfMonth,
  addMinutes,
  parseISO,
  formatISO,
  parse,
} from "date-fns";
import axios from "axios";

import CalendarTopBar from "./CalendarTopBar";
import NewClassModal from "./NewClassModal";
import SuccessAlert from "../misc/SuccessAlert";
import GymAreasCard from "./GymAreas";
import safeGymApi from "../../apis/safegym";
import {getAuthHeader} from "../../helpers/tokens";

import "tui-calendar/dist/tui-calendar.css";
import "../../assets/styles/customCalendar.css";

class EventsCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      schedules: [],
      showSuccessAlert: false
    };
    this.startDatesCache = [];
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
  }

  componentWillUnmount = () => {
    this.source.cancel();
  };

  setCalendarRef = element => {
    const daynames = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ];
    this.calendar = new Calendar(document.querySelector("#calendar"), {
      defaultView: "month",
      week: {
        daynames: daynames
      },
      month: {
        daynames: daynames
      },
      disableClick: true,
      taskView: false,
      usageStatistics: false,
      useDetailPopup: true,
      template: {
        time: function (schedule) {
          return `<span class="rounded text-light" style="background-color:${
            schedule.bgColor
          }; width: 100%; font-size: 10px;">&nbsp;${format(
            schedule.start.getTime(),
            "HH:mm"
          )}  ${schedule.title}</span>`;
        }
      }
    });
    this.forceUpdate();
  };

  showModal = () => {
    this.setState({
      showModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  showAlert = () => {
    this.setState({
      showSuccessAlert: true
    });
  };

  hideAlert = () => {
    this.setState({
      showSuccessAlert: false
    });
  };

  renderAlert = () => {
    if (this.state.showSuccessAlert) {
      return (
        <SuccessAlert
          text="La clase se ha agregado con exito y esta disponible para reservaciones."
          onHide={this.hideAlert}
        />
      );
    }
    return <React.Fragment/>;
  };

  getSchedules = async (startDate, endDate) => {
    startDate = formatISO(startDate);
    endDate = formatISO(endDate);

    if (!this.startDatesCache.includes(startDate)) {
      this.setState({loading: true});
      const response = await safeGymApi.get(
        `/programs/timeslots/?start=${startDate}&end=${endDate}`,
        {headers: getAuthHeader(), cancelToken: this.source.token}
      );

      const newData = response.data.map(item => {
        let result = parseISO(item.start_date);
        if (item.start_time !== null) {
          result = parse(item.start_time, "HH:mm:ss", result)
        }
        const start = formatISO(result);
        const end = formatISO(
          addMinutes(result, item.area_program.minutes_duration),
        );
        return {
          id: item.id,
          calendarId: "1",
          title: item.area_program.area.name,
          category: "time",
          dueDateClass: "",
          start: start,
          end: end,
          isReadOnly: true,
          color: item.area_program.area.hex_color,
          dragBgcolor: "transparent",
          borderColor: "transparent",
          bgColor: item.area_program.area.hex_color
        };
      });
      this.setState({
        schedules: newData,
        loading: false
      });
      this.calendar.createSchedules(this.state.schedules);
      this.startDatesCache.push(startDate);
    }
  };

  addNewSchedules = newSchedules => {
    const scheduleItems = newSchedules.map(item => {
      let start = parseISO(item.start_date)
      if (item.start_time !== null) {
        start = parse(item.start_time, "HH:mm:ss", start)
      }
      let end = addMinutes(start, item.area_program.minutes_duration)
      return {
        id: item.id,
        calendarId: "1",
        title: item.area_program.area.name,
        category: "time",
        dueDateClass: "",
        start: formatISO(start),
        end: formatISO(end),
        isReadOnly: true,
        color: item.area_program.area.hex_color,
        dragBgcolor: "transparent",
        borderColor: "transparent",
        bgColor: item.area_program.area.hex_color
      };
    });
    this.setState({
      schedules: [...this.state.schedules, scheduleItems],
      loading: false
    });
    this.calendar.createSchedules(scheduleItems);
  };

  componentDidMount = () => {
    const now = new Date();
    this.getSchedules(startOfMonth(now), endOfMonth(now));
  };

  render() {
    return (
      <div className="h-100">
        <NewClassModal
          show={this.state.showModal}
          onHide={this.handleCloseModal}
          showAlert={this.showAlert}
          hideAlert={this.hideAlert}
          addNewSchedules={this.addNewSchedules}
        />
        {this.renderAlert()}
        <Row>
          <Col style={{paddingRight: "0px"}} lg="2">
            <GymAreasCard/>
          </Col>

          <Col lg="10">
            <Card>
              <Card.Header>
                <div className="d-flex flex-row">
                  <h4 className="text-primary">Calendario</h4>
                  <Link to="/classes/table" className="ml-auto">
                    <Button>
                      <i className="mr-1">
                        <FontAwesomeIcon icon={faTable}/>
                      </i>
                      Ver Horarios
                    </Button>
                  </Link>
                </div>
                Administra los horarios de tu gimnasio.
              </Card.Header>
              <Card.Body>
                <CalendarTopBar
                  calendar={this.calendar}
                  showModal={this.showModal}
                  getSchedules={this.getSchedules}
                  loading={this.state.loading}
                />

                <div
                  className="joyCalendarStep"
                  style={{height: "700px"}}
                  id="calendar"
                  // style={this.calStyle}
                  ref={this.setCalendarRef}
                ></div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EventsCalendar;
