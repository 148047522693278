import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import EllipsisLoader from "../misc/EllipsisLoader";

const DeleteModal = ({
  show,
  handleClose,
  selectedItem,
  setShowSuccessAlert,
  setAlertText,
  hiddenRows,
  setHiddenRows
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDeleteConfirm = async () => {
    setIsSubmitting(true);
    const { status } = await safeGymApi.delete(
      `/notifications/${selectedItem.id}`,
      {
        headers: getAuthHeader()
      }
    );
    if (status === 204) {
      setAlertText("El aviso se ha eliminado correctamente.");
      setShowSuccessAlert(true);
      setHiddenRows([...hiddenRows, selectedItem.id]);
      handleClose();
    } else {
      console.log("error");
    }
    setIsSubmitting(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="font-weight-bold">Eliminar Aviso</Modal.Header>
      <Modal.Body>
        Confirmar que deseas eliminar este aviso. Los miembros de tu gimnasio no
        lo podran ver despues de esta accion.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleDeleteConfirm}>
          {isSubmitting ? <EllipsisLoader /> : "Eliminar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
