import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Card, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faCreditCard,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex
} from "@fortawesome/free-brands-svg-icons";
import { useStripe } from "@stripe/react-stripe-js";
import { addDays, format } from "date-fns";
import { es } from "date-fns/locale";

import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const SubscriptionConfirm = props => {
  const stripe = useStripe();
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleCustomerActionRequired({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry
  }) {
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (!paymentIntent) {
      return { subscription, priceId, paymentMethodId };
    }

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId
        })
        .then(result => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handlePaymentMethodRequired({
    subscription,
    paymentMethodId,
    priceId
  }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (subscription.latest_invoice.payment_intent === null) {
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      console.log("HERE2");
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      throw new Error({ error: { message: "Your card was declined." } });
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  const onSubscriptionComplete = result => {
    setIsSubmitting(false);
    props.setResponseDetails(result);
    props.toEventMessage();
  };

  const startSubscription = () => {
    setIsSubmitting(true);
    safeGymApi
      .post(
        "/orders/create-subscription/",
        {
          customerId: props.custId,
          paymentMethodId: props.paymentMethod,
          priceId: props.selectedPrice
        },
        { headers: getAuthHeader() }
      )
      .then(response => {
        return response;
      })
      // If the card is declined, display an error to the user.
      .then(result => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer
          console.log(result.error);
          props.toEventMessage();
        }
        return result;
      })
      // Normalize the result to contain the object returned
      // by Stripe. Add the addional details we need.
      .then(result => {
        return {
          // Use the Stripe 'object' property on the
          // returned result to understand what object is returned.
          subscription: result.data,
          paymentMethodId: props.paymentMethod,
          priceId: props.selectedPrice
        };
      })
      // Some payment methods require a customer to do additional
      // authentication with their financial institution.
      // Eg: 2FA for cards.
      .then(handleCustomerActionRequired)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail. You will
      // get a requires_payment_method error.
      .then(handlePaymentMethodRequired)
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .catch(error => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        console.log(error);
        props.toEventMessage();
      });
  };

  const renderCardDetails = () => {
    let brand = props.paymentMethodDetails.card.brand;
    let icon = null;
    if (brand === "visa") {
      icon = faCcVisa;
    } else if (brand === "mastercard") {
      icon = faCcMastercard;
    } else if (brand === "amex") {
      icon = faCcAmex;
    } else {
      icon = faCreditCard;
    }

    return (
      <div className="text-secondary pl-1 d-flex flex-row">
        <p className="mb-0 mr-auto">Método de pago:</p>
        <p>
          <i className="mr-2">
            <FontAwesomeIcon icon={icon} />
          </i>
          *****{props.paymentMethodDetails.card.last4}
        </p>
      </div>
    );
  };

  return (
    <Row>
      <Col lg={3}></Col>
      <Col lg={6}>
        <Card>
          <Card.Body>
            <h4>
              <i className="mr-2 text-success">
                <FontAwesomeIcon icon={faShoppingCart} />
              </i>
              Confirmar Orden
            </h4>
            <hr />

            <ListGroup.Item>
              <p className="mr-auto mb-0">
                1x Suscripción Mensual -{" "}
                {props.selectedPlanDetails.product.name}.
                <small className="ml-2 text-success">
                  (3 días de prueba gratis)
                </small>
              </p>
              <strong>
                $ {(props.selectedPlanDetails.unit_amount / 100).toFixed(2)} MXN
              </strong>
            </ListGroup.Item>
            <hr />
            {renderCardDetails()}
            <div className="text-right mt-5">
              <strong>
                Total: $
                {(props.selectedPlanDetails.unit_amount / 100).toFixed(2)} MXN
              </strong>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <Button variant="outline-secondary" onClick={props.prevStep}>
                <i className="mr-2">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </i>
                Atrás
              </Button>
              <Button
                onClick={startSubscription}
                variant="success"
                disabled={isSubmitting}
              >
                {isSubmitting ? <EllipsisLoader /> : "Confirmar"}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={3}></Col>
    </Row>
  );
};

const mapStateToProps = state => {
  return { custId: state.customerId };
};

export default connect(mapStateToProps)(SubscriptionConfirm);
