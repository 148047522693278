import React, { useEffect, useState } from "react";
import axios from "axios";

import LoadingRoller from "../misc/LoadingRoller";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import { ListGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex
} from "@fortawesome/free-brands-svg-icons";
import {
  faCreditCard,
  faPlus,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";

const SelectPaymentMethod = props => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleSelectMethod = e => {
    props.setPaymentMethod(e.target.value);
    props.setPaymentMethodDetails(
      paymentMethods.filter(i => i.id === e.target.value)[0]
    );
    props.nextStep();
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getPaymentMethods = async () => {
      const { status, data } = await safeGymApi.get("/orders/pay-methods/", {
        headers: getAuthHeader(),
        cancelToken: source.token
      });
      if (status === 200) {
        setLoading(false);
        setPaymentMethods(data.data);
      } else {
        alert("error");
      }
    };

    getPaymentMethods();

    return () => {
      source.cancel();
    };
  }, []);

  const renderPaymentMethods = () => {
    return (
      <ListGroup>
        {paymentMethods.map(item => {
          var brand = item.card.brand;
          brand = brand.charAt(0).toUpperCase() + brand.slice(1);

          var icon = null;
          if (brand === "Visa") {
            icon = faCcVisa;
          } else if (brand === "Mastercard") {
            icon = faCcMastercard;
          } else if (brand === "Amex") {
            icon = faCcAmex;
          } else {
            icon = faCreditCard;
          }

          return (
            <ListGroup.Item key={item.id}>
              <div className="d-flex flex-row align-items-center text-secondary">
                <i className="mr-2" style={{ fontSize: "20px" }}>
                  <FontAwesomeIcon icon={icon} />
                </i>
                <div className="mr-auto">
                  {brand} ****{item.card.last4}
                </div>
                <Button
                  variant="link"
                  value={item.id}
                  onClick={handleSelectMethod}
                >
                  Seleccionar
                </Button>
              </div>
            </ListGroup.Item>
          );
        })}
        <ListGroup.Item>
          <div className="text-primary">
            <i className="mr-1">
              <FontAwesomeIcon icon={faPlus} />
            </i>
            <Button onClick={props.toAddPaymentMethod} variant="link">
              Agregar nuevo método de pago.
            </Button>
          </div>
        </ListGroup.Item>
      </ListGroup>
    );
  };

  if (loading) {
    return <LoadingRoller />;
  }
  return (
    <div>
      <h3 className="mb-3">Métodos de pago</h3>
      {renderPaymentMethods()}
      <Button
        onClick={props.prevStep}
        variant="outline-secondary"
        className="mt-4"
      >
        <i className="mr-2">
          <FontAwesomeIcon icon={faChevronLeft} />
        </i>
        Escoger plan
      </Button>
    </div>
  );
};

export default SelectPaymentMethod;
