import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Row } from "react-bootstrap";

import ChangePasswordModal from "./ChangePasswordModal";
import { logoutUser } from "../../actions";
import StaffUsersCard from "./StaffUsersCard";

const Account = props => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Row className="pt-4">
      <ChangePasswordModal
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <Col lg={3}></Col>
      <Col lg={6}>
        <Card className="mb-4">
          <Card.Header>
            <h4 className="text-primary mb-0">Cuenta</h4>
          </Card.Header>
          <Card.Body>
            <p>
              <strong>E-Mail:</strong> {props.user.email}
            </p>
            <p>
              <strong>Nombre: </strong> {props.user.firstName}
            </p>
            <hr />
            <Button size="sm" onClick={() => setShowModal(true)}>
              Cambiar Contraseña
            </Button>
            <hr />
            <div className="text-right">
              <Button onClick={props.logoutUser} variant="outline-danger">
                Cerrar Sesión
              </Button>
            </div>
          </Card.Body>
        </Card>

        <StaffUsersCard />
      </Col>
      <Col lg={3}></Col>
    </Row>
  );
};

const mapStateToProps = state => {
  return { user: state.user };
};

export default connect(mapStateToProps, { logoutUser })(Account);
