import React, { useState } from "react";
import { Formik } from "formik";
import { Modal, Button, Form, Alert } from "react-bootstrap";

import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import EllipsisLoader from "../misc/EllipsisLoader";

const GymNameModal = ({ show, setShowGymNameModal, gymData }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);

  const renderAlert = () => {
    if (!showAlert) return;
    if (successAlert) {
      return (
        <Alert variant="success">
          El nombre fue actualizado correctamente.
        </Alert>
      );
    }
    return <Alert variant="danger">El nombre no pudo ser actualizado.</Alert>;
  };

  const handleClose = () => {
    setShowGymNameModal(false);
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.name = "Campo Requerido";
        } else if (values.name.length < 3) {
          errors.name = "El nombre debe ser mayor a 2 caracteres.";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        safeGymApi
          .put(
            `/gym/${gymData.id}/`,
            { name: values.name },
            { headers: getAuthHeader() }
          )
          .then(() => {
            setSuccessAlert(true);
            setShowAlert(true);
          })
          .catch(() => {
            setSuccessAlert(false);
            setShowAlert(true);
          });

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <Modal show={show} onHide={handleClose}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Cambiar Nombre</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <Form.Group>
                <Form.Label>Nombre del Gimnasio</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={gymData.name}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                <Form.Text className="text-danger">
                  {errors.name && touched.name && errors.name}
                </Form.Text>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Guardar Cambios"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default GymNameModal;
