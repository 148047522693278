import React from "react";

const FacebookLoader = () => (
  <div className="lds-facebook">
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default FacebookLoader;
