import React, { useState } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const DeletePlanModal = ({ show, onHide, plan }) => {
  const [success, setSuccess] = useState(null);

  const renderAlert = () => {
    if (success === null) return;
    const message = success
      ? "Se ha eliminado la membresía con éxito."
      : "Ocurrió un error al intentar eliminar la membresía.";
    return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
  };

  const handleClose = () => {
    setSuccess(null);
    onHide();
  };
  if (!plan) return <React.Fragment />;
  return (
    <Modal show={show} onHide={handleClose}>
      <Formik
        initialValues={{
          archived: true
        }}
        onSubmit={(values, { setSubmitting }) => {
          safeGymApi
            .patch(`/memberships/plans/${plan.id}/`, values, {
              headers: getAuthHeader()
            })
            .then(res => {
              setSuccess(true);
              setSubmitting(false);
            })
            .catch(err => {
              setSuccess(false);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header>
              <div className="text-danger">Eliminar Membresía</div>
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <strong>¿Estas seguro que quieres eliminar este plan?</strong>
              <p className="m-0">{plan.title}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button
                variant="danger"
                type="submit"
                disabled={isSubmitting || success}
              >
                {isSubmitting ? <EllipsisLoader /> : "Confirmar"}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default DeletePlanModal;
