import {
  USER_LOGIN_SUCCESS,
  NEW_ACCESS_TOKEN,
  USER_LOGOUT
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      localStorage.setItem("access", action.payload.access);
      localStorage.setItem("refresh", action.payload.refresh);
      return {
        ...state,
        access: action.payload.access,
        refresh: action.payload.refresh
      };
    case NEW_ACCESS_TOKEN:
      localStorage.setItem("access", action.payload.access);
      return { ...state, access: action.payload.access };
    case USER_LOGOUT:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return {};
    default:
      return state;
  }
};
