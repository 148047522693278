import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Button } from "react-bootstrap";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import StaffUserModal from "./StaffUserModal";
import { Link } from "react-router-dom";

const StaffUsersCard = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [staffUsers, setStaffUsers] = useState([]);
  const columns = [
    {
      text: "Nombre",
      dataField: "first_name"
    },
    {
      text: "Username",
      dataField: "username"
    }
  ];

  useEffect(() => {
    const getStaffUsers = async () => {
      const response = await safeGymApi.get("/staff/", {
        headers: getAuthHeader()
      });
      if (response.status === 200) {
        setStaffUsers(response.data);
      }
    };
    getStaffUsers();
  }, []);

  return (
    <React.Fragment>
      <StaffUserModal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
      />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-primary m-0">Mi Staff</h4>
            <Link to="/new-staff">
              <Button variant="success">+ Nuevo</Button>
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          <p>
            Crea usuarios staff que tendrán acceso a la app{" "}
            <strong>Safe Gym Staff</strong> y también podrán ver{" "}
            <a
              className="text-primary"
              target="_blank"
              href="https://staff.safegymadmin.com"
            >
              listas de reservaciones.
            </a>
            Los usuarios staff deben de ingresar desde{" "}
            <a
              className="text-primary"
              target="_blank"
              href="https://staff.safegymadmin.com"
            >
              staff.safegymadmin.com
            </a>
          </p>
          <BootstrapTable keyField="id" data={staffUsers} columns={columns} />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default StaffUsersCard;
