import { FETCH_OWNED_GYMS } from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_OWNED_GYMS:
      return [...state, { name: action.payload.name, id: action.payload.id }];
    default:
      return state;
  }
};
