import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Row, Col, Button, Card, Badge } from "react-bootstrap";

import LoadingRoller from "../misc/LoadingRoller";
import SuccessAlert from "../misc/SuccessAlert";
import CreateNotifModal from "./CreateNotifModal";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import { basicDateFormatter } from "../../helpers/cellFormaters";
import DeleteModal from "./DeleteModal";

const Notifications = () => {
  const [data, setData] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [hiddenRows, setHiddenRows] = useState([]);

  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    setShowModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDelete = e => {
    const notifId = e.target.value;
    const notif = data.find(item => item.id === parseInt(notifId));
    setSelectedItem(notif);
    setShowDeleteModal(true);
  };

  const actionsFormatter = (cell, row) => {
    return (
      <Button onClick={handleDelete} variant="outline-danger" value={cell}>
        Eliminar
      </Button>
    );
  };

  const renderAlert = () => {
    if (showSuccessAlert) {
      return (
        <SuccessAlert
          text={alertText}
          onHide={() => setShowSuccessAlert(false)}
        />
      );
    }
    return <React.Fragment></React.Fragment>;
  };

  const columns = [
    {
      text: "Titulo",
      dataField: "title"
    },
    {
      text: "Contenido",
      dataField: "content"
    },
    {
      text: "Creado En:",
      dataField: "created_on",
      formatter: basicDateFormatter
    },
    {
      text: "Acciones",
      dataField: "id",
      formatter: actionsFormatter
    }
  ];

  useEffect(() => {
    safeGymApi
      .get("/notifications/", {
        headers: getAuthHeader()
      })
      .then(resp => {
        setData(resp.data);
        setFetchingData(false);
      });
    setFetchingData(false);
  }, []);

  if (fetchingData) {
    return <LoadingRoller />;
  }
  return (
    <React.Fragment>
      <CreateNotifModal
        show={showModal}
        handleClose={handleClose}
        data={data}
        setData={setData}
      />
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        selectedItem={selectedItem}
        setAlertText={setAlertText}
        setShowSuccessAlert={setShowSuccessAlert}
        hiddenRows={hiddenRows}
        setHiddenRows={setHiddenRows}
      />
      {renderAlert()}
      <Card>
        <Card.Header>
          <h4 className="text-primary">Avisos</h4>
          <p className="mb-0">
            Crea avisos que usuarios de tu gimnasio verán dentro de la app.
          </p>
        </Card.Header>
        <Card.Body>
          <div>
            <Badge variant="success">NUEVO</Badge>
            <span className="ml-1 mb-2 text-secondary">
              Envía notificaciones push con avisos o promociones directamente al
              celular de tus clientes.
            </span>
          </div>
          <Button onClick={handleShow}> + Crear nuevo aviso</Button>
          <Row className="mt-4">
            <Col>
              <BootstrapTable
                keyField="id"
                data={data}
                columns={columns}
                hiddenRows={hiddenRows}
                noDataIndication="No hay datos para mostrar"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default Notifications;
