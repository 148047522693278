import React, { useEffect, useState } from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import CreateAreaModal from "./CreateAreaModal";
import UpdateAreaModal from "./UpdateAreaModal";
import DeleteAreaModal from "./DeleteAreaModal";

import safeGymApi from "../../../apis/safegym";
import { getAuthHeader } from "../../../helpers/tokens";

const GymAreasCard = () => {
  const [gymAreas, setGymAreas] = useState([]);
  const [showCreateAreaModal, setShowCreateAreaModal] = useState(false);
  const [showUpdateAreaModal, setShowUpdateAreaModal] = useState(false);
  const [showDeleteAreaModal, setShowDeleteAreaModal] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);

  const handleShowDeleteModal = () => {
    setShowUpdateAreaModal(false);
    setShowDeleteAreaModal(true);
  };

  useEffect(() => {
    safeGymApi
      .get("/gym/areas/", { headers: getAuthHeader() })
      .then(resp => {
        const data = resp.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        setGymAreas(data);
      })
      .catch(resp => alert(resp.data.error));
  }, []);

  const renderAreas = () => {
    if (gymAreas.length === 0) {
      return (
        <div className="text-center">
          <p>
            Haz click en el boton{" "}
            <strong className="text-primary">Crear</strong> para agregar un área
            o clase.
          </p>
        </div>
      );
    }
    return gymAreas.map(area => {
      return (
        <ListGroup.Item key={area.id} style={{ padding: "0.5rem 1.25rem" }}>
          <div className="d-flex justify-content-between">
            <div>
              <span
                className="mr-2"
                style={{ color: area.hex_color, fontSize: "7px" }}
              >
                <FontAwesomeIcon icon={faCircle} />
              </span>
              {area.name}
            </div>
            <Button
              onClick={() => {
                setSelectedArea(area);
                setShowUpdateAreaModal(true);
              }}
              className="text-secondary"
              variant="link"
              size="sm"
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </div>
        </ListGroup.Item>
      );
    });
  };

  return (
    <div>
      <CreateAreaModal
        show={showCreateAreaModal}
        onHide={() => setShowCreateAreaModal(false)}
        gymAreas={gymAreas}
        setGymAreas={setGymAreas}
      />
      <UpdateAreaModal
        show={showUpdateAreaModal}
        onHide={() => {
          setShowUpdateAreaModal(false);
          setSelectedArea(null);
        }}
        gymAreas={gymAreas}
        setGymAreas={setGymAreas}
        selectedArea={selectedArea}
        handleShowDeleteModal={handleShowDeleteModal}
      />
      <DeleteAreaModal
        show={showDeleteAreaModal}
        onHide={() => {
          setShowDeleteAreaModal(false);
          setSelectedArea(null);
        }}
        gymAreas={gymAreas}
        setGymAreas={setGymAreas}
        selectedArea={selectedArea}
      />
      <Card className="mb-2">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center pb-4">
            <div>Áreas y Clases</div>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setShowCreateAreaModal(true)}
            >
              Crear
            </Button>
          </div>
        </Card.Header>
        <Card.Body style={{ padding: "0.25rem" }}>
          <ListGroup style={{ fontSize: "14px" }} variant="flush">
            {renderAreas()}
          </ListGroup>
        </Card.Body>
      </Card>
    </div>
  );
};

export default GymAreasCard;
