import React, { useState } from "react";
import { Modal, Button, Alert, Form } from "react-bootstrap";
import { Field, Form as FormikForm, Formik } from "formik";
import { addDays, endOfDay, formatISO } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import safegym from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import EllipsisLoader from "../misc/EllipsisLoader";

const CreditsModal = ({
  show,
  onHide,
  selectedMember,
  isPositive,
  user,
  setUser
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [success, setSuccess] = useState(false);

  const renderAlert = () => {
    if (!showAlert) return <React.Fragment />;
    return (
      <Alert variant={success ? "success" : "danger"}>
        {success
          ? "Créditos actualizados correctamente"
          : "Ocurrió un error. Contactar soporte."}
      </Alert>
    );
  };

  const handleClose = () => {
    setShowAlert(false);
    onHide();
  };

  if (!selectedMember) return <React.Fragment />;
  return (
    <Modal show={show} onHide={handleClose}>
      <Formik
        initialValues={{
          current_amount: 0,
          expireInDays: 30
        }}
        onSubmit={(values, { setSubmitting }) => {
          setShowAlert(false);
          if (isPositive) {
            const expireDate = addDays(
              endOfDay(new Date()),
              values.expireInDays
            );
            safegym
              .post(
                `/credits/v2/`,
                {
                  user: selectedMember.id,
                  current_amount: values.current_amount,
                  expire_on: formatISO(expireDate)
                },
                {
                  headers: getAuthHeader()
                }
              )
              .then(resp => {
                setSuccess(true);
                setShowAlert(true);
                setUser({
                  ...user,
                  credits2: [
                    ...user.credits2,
                    {
                      id: resp.data.id,
                      current_amount: resp.data.current_amount,
                      expire_on: resp.data.expire_on
                    }
                  ]
                });
                setSubmitting(false);
              })
              .catch(e => {
                console.log(e);
                setSuccess(false);
                setShowAlert(true);
                setSubmitting(false);
              });
          } else {
            safegym
              .post(
                `/credits/v2/remove/`,
                {
                  amount: values.current_amount,
                  user: selectedMember.id
                },
                {
                  headers: getAuthHeader()
                }
              )
              .then(resp => {
                setSuccess(true);
                setShowAlert(true);
                setSubmitting(false);
              })
              .catch(e => {
                console.log(e);
                setSuccess(false);
                setShowAlert(true);
                setSubmitting(false);
              });
          }
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header
              className={isPositive ? "text-success" : "text-danger"}
            >
              <h5>{isPositive ? "Agregar Créditos" : "Quitar Créditos"}</h5>
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <strong>
                <FontAwesomeIcon icon={faUser} /> {selectedMember.first_name}
              </strong>
              <hr />

              <Form.Group>
                <Form.Label>Cantidad</Form.Label>
                <Field
                  className="form-control form-control-sm"
                  name="current_amount"
                  type="number"
                  placeholder="Cantidad"
                  min="0"
                />
              </Form.Group>
              {isPositive ? (
                <Form.Group>
                  <Form.Label>Vigencia (Días)</Form.Label>
                  <Field
                    className="form-control form-control-sm"
                    name="expireInDays"
                    type="number"
                    min="1"
                    max="10000"
                  />
                </Form.Group>
              ) : (
                <React.Fragment />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} variant="outline-secondary">
                Cerrar
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Guardar"}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default CreditsModal;
