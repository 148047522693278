import React from "react";
import { Card, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const ScheduleCard = ({ gymData, setShow }) => {
  return (
    <Card>
      <Card.Header>
        <div>
          <div className="d-flex row-flex justify-content-between">
            <h5 className="pt-2 mb-0 text-primary">Horarios</h5>
            <Button
              className="mb-0 pb-0"
              variant="link"
              onClick={() => setShow(true)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </div>
          <div>
            <small>
              Muestra a tus usuarios los horarios en que se encuentra abierto tu
              gimnasio.
            </small>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Table borderless size="sm">
          <thead>
            <tr>
              <th>Lunes</th>
              <th>Martes</th>
              <th>Miercoles</th>
              <th>Jueves</th>
              <th>Viernes</th>
              <th>Sabado</th>
              <th>Domingo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{gymData.schedule.monday}</td>
              <td>{gymData.schedule.tuesday}</td>
              <td>{gymData.schedule.wednesday}</td>
              <td>{gymData.schedule.thursday}</td>
              <td>{gymData.schedule.friday}</td>
              <td>{gymData.schedule.saturday}</td>
              <td>{gymData.schedule.sunday}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ScheduleCard;
