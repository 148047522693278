import React, { useState } from "react";
import { startOfWeek, endOfWeek } from "date-fns";
import { Row, Col, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const TopBar = ({ calendar, showModal, getSchedules }) => {
  const [renderedDate, setRenderedDate] = useState(
    format(new Date(), "MMMM yyyy", { locale: es })
  );

  const handleNextBtn = () => {
    calendar.next();
    const newDate = calendar.getDate()._date;
    setRenderedDate(format(newDate, "MMMM yyyy", { locale: es }));
    const start = startOfWeek(newDate);
    const end = endOfWeek(newDate);
    getSchedules(start, end);
  };

  const handlePrevBtn = () => {
    calendar.prev();
    const newDate = calendar.getDate()._date;
    setRenderedDate(format(newDate, "MMMM yyyy", { locale: es }));
    const start = startOfWeek(newDate);
    const end = endOfWeek(newDate);
    getSchedules(start, end);
  };

  return (
    <Row className="align-items-start">
      <Col>
        <div className="d-flex flex-row p-1">
          <Button
            id="addAreaBtn"
            className="step4"
            variant="info"
            onClick={showModal}
          >
            + Agregar Area
          </Button>
          <DropdownButton
            id="dropDownViewSelect"
            variant="outline-secondary"
            title="Tipo de calendario"
            className="ml-2"
          >
            <Dropdown.Item onSelect={() => calendar.changeView("day", true)}>
              Dia
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => calendar.changeView("week", true)}>
              Semanal
            </Dropdown.Item>
          </DropdownButton>
          <Button
            onClick={() => calendar.today()}
            variant="outline-secondary"
            className="ml-2"
          >
            Hoy
          </Button>
          <Button
            className="ml-2"
            variant="outline-secondary"
            onClick={handlePrevBtn}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Button
            className="ml-1"
            variant="outline-secondary"
            onClick={handleNextBtn}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
          <h5 className="ml-auto text-primary">{renderedDate}</h5>
        </div>
      </Col>
    </Row>
  );
};

export default TopBar;
