import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import SelectPlan from "./SelectPlan";
import NewCard from "./NewCard";
import SelectPaymentMethod from "./SelectPaymentMethod";
import SubscriptionConfirm from "./SubscriptionConfirm";
import EventMessage from "./EventMessage";
import BillingDetails from "./BillingDetails";

let key;
key =
  process.env.NODE_ENV === "production"
    ? "pk_live_51H4XFRFNqcKYLzi9WrkhjMWsruWWbJeb8Cfedza4rPhMBHtJBKqt4L7XieTSsPwYjkjeaqg8dKiCZgpLfi0eltHm007q6LXt1i"
    : "pk_test_51H4XFRFNqcKYLzi9dzfEDlk8mCRVVObXTKMDpiTwcTBWlgvP8VYgD2J13yrAdC0OqIOT6fiCdhJwTL7t1TdQ36rF006xU2ub5a";

const stripePromise = loadStripe(key, { locale: "es" });

const MultiStepForm = props => {
  const [step, setStep] = useState(1);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodDetails, setPaymentMethodDetails] = useState({});
  const [responseDetails, setResponseDetails] = useState(null);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const toSelectPaymentMethod = () => setStep(2);
  const toAddPaymentMethod = () => setStep(40);
  const toEventMessage = () => setStep(50);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderCurrentStepComponent = () => {
    if (props.subActive) {
      return <BillingDetails />;
    }
    switch (step) {
      case 1:
        return (
          <SelectPlan
            setSelectedPrice={setSelectedPrice}
            nextStep={nextStep}
            setSelectedPlanDetails={setSelectedPlanDetails}
          />
        );
      case 2:
        return (
          <SelectPaymentMethod
            prevStep={prevStep}
            nextStep={nextStep}
            toAddPaymentMethod={toAddPaymentMethod}
            setPaymentMethod={setPaymentMethod}
            setPaymentMethodDetails={setPaymentMethodDetails}
          />
        );
      case 3:
        return (
          <SubscriptionConfirm
            prevStep={prevStep}
            toEventMessage={toEventMessage}
            setResponseDetails={setResponseDetails}
            selectedPrice={selectedPrice}
            selectedPlanDetails={selectedPlanDetails}
            paymentMethod={paymentMethod}
            paymentMethodDetails={paymentMethodDetails}
          />
        );
      case 40:
        return <NewCard toSelectPaymentMethod={toSelectPaymentMethod} />;
      case 50:
        return <EventMessage data={responseDetails} />;
      default:
        return <div>Error</div>;
    }
  };

  return (
    <Elements stripe={stripePromise}>{renderCurrentStepComponent()}</Elements>
  );
};

const mapStateToProps = state => {
  return { subActive: state.user.subscriptionActive };
};

export default connect(mapStateToProps)(MultiStepForm);
