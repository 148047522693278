import React, {useEffect, useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import EllipsisLoader from "../misc/EllipsisLoader";
import {Formik, Form} from "formik";
import DatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {endOfDay, formatISO, parseISO} from "date-fns";
import safegym from "../../apis/safegym";
import {getAuthHeader} from "../../helpers/tokens";

const EditCreditsModal = ({show, onHide, credits, user, setUser}) => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [showAlert, setShowAlert] = useState(false)
  const [success, setSuccess] = useState(false)

  const renderAlert = () => {
    if (!showAlert) return <React.Fragment/>;
    return (<Alert variant={success ? "success" : "danger"}>
      {success ? "Créditos actualizados correctamente" : "Ocurrió un error. Contactar soporte."}
    </Alert>);
  };

  const handleClose = () => {
    setShowAlert(false);
    onHide();
  };

  useEffect(() => {
    if (credits) {
      setSelectedDate(parseISO(credits.expire_on))
    }
  }, [credits])

  if (!credits) return <React.Fragment/>
  return (<Modal show={show} onHide={handleClose}>
    <Formik
      initialValues={{}}
      onSubmit={(values, {setSubmitting}) => {
        safegym.post("/credits/update/", {
          credits: credits.id, expire_on: formatISO(endOfDay(selectedDate))
        }, {
          headers: getAuthHeader()
        }).then(res => {
          setSuccess(true)
          setShowAlert(true)
          setSubmitting(false)
          setUser({
            ...user,
            credits2: user.credits2.map(c => c.id === credits.id ? {...c, expire_on: formatISO(selectedDate)} : c)
          })
        }).catch(err => {
          setSuccess(false)
          setShowAlert(true)
          setSubmitting(false)
        })
      }}
    >
      {({isSubmitting}) => (<Form>
        <Modal.Header>Editar créditos</Modal.Header>
        <Modal.Body>
          {renderAlert()}
          <p className="font-weight-bold">{credits.current_amount} créditos</p>
          <div className="d-inline-flex">
            <div className="mr-2">Vencimiento</div>
            <DatePicker
              className="form-control"
              dateFormat="dd 'de' MMMM 'del' yyyy"
              selected={selectedDate}
              onChange={d => setSelectedDate(d)}
              locale={es}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} variant="outline-secondary">
            Cerrar
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? <EllipsisLoader/> : "Guardar"}
          </Button>
        </Modal.Footer>
      </Form>)}
    </Formik>
  </Modal>);
};

export default EditCreditsModal;
