import React from "react";
import { format, fromUnixTime } from "date-fns";
import { Alert } from "react-bootstrap";

const EventMessage = ({ data }) => {
  const heading = data != null ? "Exito" : "Error";
  const variant = data != null ? "success" : "danger";

  const renderErrorMessage = () => {
    return (
      <div>
        Ocurrió un error al intentar crear la suscripción en tu cuenta.
        Asegúrate que tu método de pago se encuentre vigente.
      </div>
    );
  };

  const renderSuccessMessage = () => {
    if (data.trial_end != null) {
      return (
        <div>
          Tu periodo de prueba ha comenzado. No se te hara ningún cobro hasta el
          dia {format(fromUnixTime(data.trial_end), "dd 'de' MMMM 'de' yyyy")}
        </div>
      );
    }
    return <div>Se ha iniciado tu suscripción correctamente.</div>;
  };

  return (
    <div>
      <Alert variant={variant}>
        <Alert.Heading>{heading}</Alert.Heading>
        {data != null ? renderSuccessMessage() : renderErrorMessage()}
      </Alert>
    </div>
  );
};

export default EventMessage;
