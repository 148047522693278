import React, { useEffect, useState } from "react";
import { Row, Modal, Button, Col, Form } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { parseISO, addDays, formatISO, isEqual } from "date-fns";
import { es } from "date-fns/locale";
import DatePicker from "react-datepicker";

import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const EditModal = ({ data, show, handleClose, showAlert, setAlertText }) => {
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (data) {
      setEndDate(parseISO(data.end_date));
    }
  }, [data]);

  const renderField = (label, name, type = "text") => (
    <div className="form-group w-100">
      <label>{label}</label>
      <Field className="form-control" type={type} name={name} />
      <ErrorMessage name={name} />
    </div>
  );

  const resetModal = resetForm => {
    resetForm();
  };

  if (data === null || data === undefined) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          minutes: data.minutes_duration,
          peopleLimit: data.people_limit,
          instructorName: data.instructor_name
        }}
        validate={values => {
          return {};
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { status } = await safeGymApi.put(
            `/programs/${data.id}/`,
            {
              minutes_duration: values.minutes,
              people_limit: values.peopleLimit,
              instructor_name: values.instructorName
            },
            { headers: getAuthHeader() }
          );
          if (!isEqual(endDate, parseISO(data.end_date))) {
            console.log(formatISO(endDate));
            safeGymApi
              .post(
                "/programs/update/end/",
                {
                  area_program: data.id,
                  end_date: formatISO(endDate)
                },
                { headers: getAuthHeader() }
              )
              .then(() => {})
              .catch(() => alert("Error actualizando la fecha final."));
          }
          if (status === 200) {
            setAlertText(
              "Se han actualizado los datos correctamente. Refrescar pagina para visualizarlos."
            );
            showAlert();
            handleClose();
          } else {
            handleClose();
            alert("Ocurrió un error al intentar actualizar los datos.");
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, resetForm }) => (
          <Modal
            show={show}
            onHide={handleClose}
            onEntering={() => resetModal(resetForm)}
          >
            <FormikForm>
              <Modal.Header className="text-left">
                <span>
                  Editar programación de{" "}
                  <span style={{ color: data.area.hex_color }}>
                    {data.area.name}
                  </span>
                </span>
              </Modal.Header>
              <Modal.Body>
                <Row className="px-2">
                  <Col>
                    {renderField("Duración (Minutos)", "minutes")}
                    {renderField("Limite de Personas", "peopleLimit")}
                    {renderField("Instructor / Coach", "instructorName")}
                    <div>
                      <Form.Group>
                        <Form.Label>Fecha Final</Form.Label>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd 'de' MMMM 'del' yyyy"
                          selected={endDate}
                          onChange={d => setEndDate(d)}
                          locale={es}
                          maxDate={addDays(new Date(), 365)}
                          minDate={new Date()}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cerrar
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <EllipsisLoader /> : "Guardar Cambios"}
                </Button>
              </Modal.Footer>
            </FormikForm>
          </Modal>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default EditModal;
