import React, { useState } from "react";
import { Alert, Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const CreateStaffUser = () => {
  const [canEditExpiry, setCanEditExpiry] = useState(false);
  const [canVerifyUsers, setCanVerifyUsers] = useState(false);
  const [canTakeAssistance, setCanTakeAssistance] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  if (showSuccessAlert) {
    return <Alert variant="success">Usuario staff creado con exito.</Alert>;
  }
  return (
    <div className="mx-5">
      <Card>
        <Card.Header>
          <h4>Crear usuario Staff</h4>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={{
              first_name: "",
              username: "",
              password: ""
            }}
            onSubmit={(values, { setSubmitting }) => {
              safeGymApi
                .post(
                  "/staff/",
                  {
                    first_name: values.first_name,
                    username: values.username,
                    email: values.username,
                    password: values.password,
                    staffpermissions: {
                      can_edit_user_expiry: canEditExpiry,
                      can_verify_users: canVerifyUsers,
                      can_take_assistance: canTakeAssistance
                    }
                  },
                  { headers: getAuthHeader() }
                )
                .then(() => {
                  setSubmitting(false);
                  setShowSuccessAlert(true);
                })
                .catch(e => alert(e));
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <FormikForm>
                <Form.Group>
                  <Form.Label>E-Mail de acceso</Form.Label>
                  <Field
                    className="form-control "
                    name="username"
                    type="text"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="username" />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Nombre de la persona</Form.Label>
                  <Field
                    className="form-control"
                    name="first_name"
                    type="text"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="first_name" />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Contraseña</Form.Label>
                  <Field
                    className="form-control "
                    name="password"
                    type="text"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="password" />
                  </div>
                </Form.Group>

                <hr />
                <Row>
                  <Col>
                    <Form.Label style={{ fontWeight: "bold" }}>
                      Permisos app móvil (Safe Gym Staff)
                    </Form.Label>
                    <div className="d-flex flex-column justify-content-around">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Verificar usuarios"
                          onChange={() => setCanVerifyUsers(!canVerifyUsers)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Editar membresías"
                          onChange={() => setCanEditExpiry(!canEditExpiry)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Tomar asistencias"
                          onChange={() =>
                            setCanTakeAssistance(!canTakeAssistance)
                          }
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                <hr />
                <div className="text-center">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="outline-success"
                    className="px-5"
                  >
                    Guardar
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CreateStaffUser;
