import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Alert,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const CreatePlanModal = ({ show, onHide }) => {
  const [success, setSuccess] = useState(null);
  const [isCreditsPlan, setIsCreditsPlan] = useState(false);

  const renderAlert = () => {
    if (success === null) return;
    const message = success
      ? "Se ha creado el plan de membresía con éxito."
      : "Ocurrió un error al intentar crear el plan de membresía.";
    return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
  };

  const handleClose = () => {
    setSuccess(null);
    setIsCreditsPlan(false);
    onHide();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik
        initialValues={{
          title: "",
          days_duration: 30,
          price: "",
          credits_count: 0
        }}
        onSubmit={(values, { setSubmitting }) => {
          safeGymApi
            .post(
              "/memberships/plans/",
              { ...values, is_credits_plan: isCreditsPlan },
              {
                headers: getAuthHeader()
              }
            )
            .then(res => {
              setSuccess(true);
              setSubmitting(false);
            })
            .catch(err => {
              setSuccess(false);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header>
              <div className="text-primary">Crear Membresía</div>
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <Form.Group>
                <Form.Label>Título</Form.Label>
                <Field className="form-control form-control-sm" name="title" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Vigencia (Días)</Form.Label>
                <Field
                  className="form-control form-control-sm"
                  type="number"
                  name="days_duration"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Precio</Form.Label>
                <Field
                  className="form-control form-control-sm"
                  type="number"
                  name="price"
                />
              </Form.Group>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="addMembCredits">
                    Si activas esta opción, se le asignaran créditos al usuario
                    con esta membresía.
                  </Tooltip>
                }
              >
                <Form.Group>
                  <Form.Check
                    defaultChecked={false}
                    type="checkbox"
                    label="Créditos"
                    onChange={val => {
                      setIsCreditsPlan(val.target.checked);
                    }}
                  />
                </Form.Group>
              </OverlayTrigger>
              {isCreditsPlan ? (
                <Form.Group>
                  <Form.Label>Cantidad de Créditos</Form.Label>
                  <Field
                    className="form-control form-control-sm"
                    type="number"
                    placeholder=""
                    name="credits_count"
                    min="0"
                    max="10000"
                  />
                </Form.Group>
              ) : (
                <React.Fragment />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Crear"}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default CreatePlanModal;
