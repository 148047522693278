import safeGymApi from "../apis/safegym";
import {
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  NEW_ACCESS_TOKEN,
  USER_LOGOUT,
  SET_AUTH_HEADER,
  FETCH_OWNED_GYMS,
  SELECT_GYM,
  FETCH_CLASS_PROGRAMS,
  FETCH_CUSTOMER_ID,
  FETCH_USER,
  UPDATE_GYM_COVER_IMG,
  UPDATE_GYM_LOGO_IMG
} from "./types";
import { getAuthHeader } from "../helpers/tokens";

export const userLogin = user => async dispatch => {
  const response = await safeGymApi.post("/user/dashboard/jwt/create/", user);
  if (response.status === 200) {
    dispatch({ type: USER_LOGIN_SUCCESS, payload: response.data });
    dispatch({ type: SET_AUTH_HEADER, payload: response.data.access });
  }
  dispatch({ type: USER_LOGIN_FAILED, payload: null });
};

export const successLogin = data => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: data
  };
};

export const refreshAccessToken = refreshToken => async dispatch => {
  const response = await safeGymApi.post("/auth/jwt/refresh/", {
    refresh: refreshToken
  });
  if (response.status === 200) {
    dispatch({ type: NEW_ACCESS_TOKEN, payload: response.data });
    dispatch({ type: SET_AUTH_HEADER, payload: response.data.access });
  }
};

export const logoutUser = () => {
  return {
    type: USER_LOGOUT,
    payload: null
  };
};

export const setAuthHeader = accessToken => {
  return {
    type: SET_AUTH_HEADER,
    payload: accessToken
  };
};

export const fetchUser = () => async dispatch => {
  var headers = {};
  headers["Authorization"] = `Bearer ${localStorage.getItem("access")}`;
  const response = await safeGymApi.get("/user/dashboard/", {
    headers: headers
  });
  safeGymApi.get("/metrics/", { headers: headers });
  if (response.status === 200) {
    dispatch({ type: FETCH_CUSTOMER_ID, payload: response.data.customer_id });
    dispatch({ type: FETCH_USER, payload: response.data });
    response.data.gyms.forEach(gym => {
      dispatch({ type: SELECT_GYM, payload: gym });
      dispatch({ type: FETCH_OWNED_GYMS, payload: gym });
    });
  }
};

export const selectGym = gym => {
  return {
    type: SELECT_GYM,
    payload: gym
  };
};

export const updateGymCoverImg = newUrl => {
  return {
    type: UPDATE_GYM_COVER_IMG,
    payload: newUrl
  };
};

export const updateGymLogoImg = newUrl => {
  return {
    type: UPDATE_GYM_LOGO_IMG,
    payload: newUrl
  };
};

export const fetchClassPrograms = () => async dispatch => {
  const response = await safeGymApi.get("/programs/", {
    headers: getAuthHeader()
  });
  if (response.status === 200) {
    dispatch({ type: FETCH_CLASS_PROGRAMS, payload: response.data });
  }
};
