import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row, Form, Button, Card } from "react-bootstrap";
import { Formik } from "formik";
import { object, string } from "yup";

import {
  userLogin,
  successLogin,
  refreshAccessToken,
  logoutUser,
  fetchUser,
  selectGym
} from "../../actions";
import LoadingRoller from "../misc/LoadingRoller";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import loginImg from "../../assets/img/brand.png";
import "../../assets/styles/login.css";
import ForgotPasswordModal from "./ForgotPasswordModal";

const LoginForm = props => {
  const [loading, setLoading] = useState(true);
  const [errorHidden, setErrorHidden] = useState(true);
  const [showRecoverPasswordModal, setShowRecoverPasswordModal] = useState(
    false
  );

  useEffect(() => {
    const token = localStorage.getItem("refresh");
    if (token == null) {
      setLoading(false);
      return;
    }
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    let exp = JSON.parse(jsonPayload).exp;
    let now = new Date();
    let secondsInEpoch = Math.round(now.getTime() / 1000);
    let hoursLeftBeforeExpiry = (exp - secondsInEpoch) / 60 / 60;
    if (hoursLeftBeforeExpiry >= 4) {
      props.refreshAccessToken(token).then(() => {
        props.fetchUser();
      });
    } else {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      window.location.href = "/";
      props.logoutUser();
    }
  }, [props]);

  const schema = object({
    username: string()
      .required("Campo requerido")
      .max(150),
    password: string()
      .required("Campo requerido")
      .max(150)
  });

  if (loading) {
    return <LoadingRoller />;
  }
  return (
    <Row className="text-center h-100">
      <ForgotPasswordModal
        show={showRecoverPasswordModal}
        onHide={() => setShowRecoverPasswordModal(false)}
      />
      <Col lg={3}></Col>
      <Col lg={6} className="d-flex justify-content-center align-items-center">
        <Formik
          validationSchema={schema}
          initialValues={{ username: "", password: "" }}
          onSubmit={async (values, { setSubmitting }) => {
            setErrorHidden(true);
            try {
              const response = await safeGymApi.post(
                "/user/dashboard/jwt/create/",
                { username: values.username, password: values.password }
              );
              if (response.status === 200) {
                setSubmitting(false);
                props.successLogin(response.data);
                props.fetchUser();
              }
            } catch (e) {
              setErrorHidden(false);
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleSubmit,
            handleChange
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Card className="shadow rounded">
                <Card.Img src={loginImg} />
                <Card.Body>
                  <p className="text-danger" hidden={errorHidden}>
                    No se pudo iniciar sesión. Verifique los datos ingresados.
                  </p>
                  <Form.Group>
                    <Form.Row>
                      <Col lg={3}>
                        <Form.Label>E-Mail</Form.Label>
                      </Col>
                      <Col lg={9}>
                        <Form.Control
                          name="username"
                          type="text"
                          placeholder="Correo Electronico"
                          value={values.username}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Text className="text-danger">
                      {errors.password && touched.password && errors.password}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Row>
                      <Col lg={3}>
                        <Form.Label>Contraseña</Form.Label>
                      </Col>
                      <Col lg={9}>
                        <Form.Control
                          name="password"
                          type="password"
                          placeholder="Contraseña"
                          value={values.password}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Text className="text-danger">
                      {errors.password && touched.password && errors.password}
                    </Form.Text>
                  </Form.Group>
                  <div className="text-right">
                    <Button
                      onClick={() => setShowRecoverPasswordModal(true)}
                      variant="link"
                    >
                      ¿Olvidaste tu contraseña?
                    </Button>
                  </div>
                  <hr />
                  <div className="text-center mt-4">
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? <EllipsisLoader /> : "Iniciar Sesion"}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Form>
          )}
        </Formik>
      </Col>
      <Col lg={3}></Col>
    </Row>
  );
};

const mapStateToProps = state => {
  return { gym: state.ownedGyms };
};

export default connect(mapStateToProps, {
  userLogin,
  successLogin,
  refreshAccessToken,
  logoutUser,
  fetchUser,
  selectGym
})(LoginForm);
