import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { object, string } from "yup";

import EllipsisLoader from "../../misc/EllipsisLoader";
import safeGymApi from "../../../apis/safegym";
import { getAuthHeader } from "../../../helpers/tokens";

const CreateAreaModal = ({
  show,
  onHide,
  gymAreas,
  setGymAreas,
  selectedGym
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#21ABE6");
  const [success, setSuccess] = useState(null);

  const renderAlert = () => {
    if (success === null) return;
    const message = success ? "Creado correctamente." : "Ocurrió un error.";
    return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
  };

  const renderColorPicker = () => {
    if (!displayColorPicker) {
      return <React.Fragment></React.Fragment>;
    }
    return (
      <SketchPicker
        color={selectedColor}
        disableAlpha
        onChange={c => setSelectedColor(c.hex)}
      />
    );
  };

  const colorBoxStyle = {
    background: selectedColor,
    height: "20px",
    cursor: "pointer"
  };

  const validation = object({
    name: string()
      .required("Campo requerido.")
      .min(2, "Mínimo 2 caracteres")
  });

  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        initialValues={{ name: "" }}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          setSuccess(false);
          safeGymApi
            .post(
              "/gym/areas/",
              {
                name: values.name,
                hex_color: selectedColor,
                gym: selectedGym.data.id
              },
              { headers: getAuthHeader() }
            )
            .then(resp => {
              setGymAreas([...gymAreas, resp.data]);
              setSuccess(true);
              setSubmitting(false);
            })
            .catch(() => {
              setSuccess(false);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header className="text-primary">
              Crear Área / Clase
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <Field
                  className="form-control"
                  name="name"
                  placeholder="Ejemplos: Alberca, Pesas, Yoga, etc..."
                />
                <div className="text-danger">
                  <ErrorMessage name="name" />
                </div>
              </Form.Group>
              <div className="d-flex flex-row ">
                <p className="mr-3">Color:</p>
                <div
                  style={colorBoxStyle}
                  className="px-3 mt-1 border rounded"
                  onClick={() => setDisplayColorPicker(!displayColorPicker)}
                ></div>
                {renderColorPicker()}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onHide} variant="outline-secondary">
                Cerrar
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Crear"}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = state => {
  return { selectedGym: state.selectedGym };
};

export default connect(mapStateToProps)(CreateAreaModal);
