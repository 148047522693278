import React, { useState } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { dateFormatter } from "../../helpers/cellFormaters";
import { getAuthHeader } from "../../helpers/tokens";
import {format, formatISO, parse, parseISO} from "date-fns";

const DeleteRsvpModal = ({ show, onHide, timeslot, rsvpData }) => {
  const [success, setSuccess] = useState(null);

  const renderAlert = () => {
    if (success === null) return;
    const message = success
      ? "Se ha eliminado esta reservación con éxito."
      : "Ocurrió un error al intentar eliminar la reservación.";
    return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
  };

  const handleClose = () => {
    setSuccess(null);
    onHide();
  };

  const getDateText = () => {
    let start = parseISO(timeslot.start_date)
    if (timeslot.start_time !== null) {
      start = parse(timeslot.start_time, "HH:mm:ss", start)
    }
    return dateFormatter(formatISO(start))
  }

  if (!timeslot) return <React.Fragment />;
  if (!rsvpData) return <React.Fragment />;
  return (
    <Modal show={show} onHide={handleClose}>
      <Formik
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setSuccess(null);
          if (rsvpData.isStringRsvp) {
            safeGymApi
              .delete(`/programs/rsvp/delete/${rsvpData.id}/`, {
                headers: getAuthHeader()
              })
              .then(res => {
                setSuccess(true);
                setSubmitting(false);
              })
              .catch(err => {
                setSuccess(false);
                setSubmitting(false);
              });
          } else {
            safeGymApi
              .delete(`/programs/reservations/${rsvpData.id}/`, {
                headers: getAuthHeader()
              })
              .then(res => {
                setSuccess(true);
                setSubmitting(false);
              })
              .catch(err => {
                setSuccess(false);
                setSubmitting(false);
              });
          }
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header className="text-danger">
              Confirmar Eliminación
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <strong>
                ¿Estas seguro que quieres eliminar esta reservación?
              </strong>
              <div>
                <hr />
                <strong>
                  {rsvpData.user.first_name} - {rsvpData.user.email}
                </strong>
                <br />
                {timeslot.area_program.area.name}
                <br />
                {getDateText()}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button
                variant="outline-danger"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <EllipsisLoader /> : "Eliminar"}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default DeleteRsvpModal;
