import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import Form from "react-bootstrap/Form";
import { object, string, ref } from "yup";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import EllipsisLoader from "../misc/EllipsisLoader";

const ChangePasswordModal = ({ show, onHide }) => {
  const [success, setSuccess] = useState(null);

  const renderAlert = () => {
    if (success === null) return;
    if (success) {
      return (
        <Alert variant="success">
          La contraseña fue actualizada correctamente.
        </Alert>
      );
    } else {
      return (
        <Alert variant="danger">
          Ocurrió un error al intentar actualizar los datos.
        </Alert>
      );
    }
  };

  const schema = object({
    new_password: string()
      .required("Campo requerido")
      .min(8, "Minimo 8 caracteres")
      .matches(/(?=.*?[A-Z])/, "Mínimo una letra mayúscula")
      .matches(/(?=.*?[a-z])/, "Mínimo una letra minúscula")
      .matches(/(?=.*?[0-9])/, "Mínimo un número"),
    password_confirm: string()
      .oneOf([ref("new_password"), null], "Contraseñas no coinciden.")
      .required("Campo requerido")
  });

  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        validationSchema={schema}
        initialValues={{
          current_password: "",
          new_password: "",
          password_confirm: ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          safeGymApi
            .post(`/auth/users/set_password/`, values, {
              headers: getAuthHeader()
            })
            .then(result => {
              setSuccess(true);
            })
            .catch(err => setSuccess(false));
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="text-primary">
              Cambiar contraseña actual
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <Form.Group>
                <Form.Label>Contraseña actual</Form.Label>
                <Form.Control
                  type="password"
                  name="current_password"
                  value={values.current_password}
                  onChange={handleChange}
                />
                <div className="text-danger">
                  <ErrorMessage name="current_password" />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Nueva Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  name="new_password"
                  value={values.new_password}
                  onChange={handleChange}
                />
                <div className="text-danger">
                  <ErrorMessage name="new_password" />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Confirmar Nueva Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  name="password_confirm"
                  value={values.password_confirm}
                  onChange={handleChange}
                />
                <div className="text-danger">
                  <ErrorMessage name="password_confirm" />
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onHide} variant="outline-secondary">
                Cerrar
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Guardar"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePasswordModal;
