import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faEdit,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {format, parse, parseISO} from "date-fns";
import { es } from "date-fns/locale";
import { Button } from "react-bootstrap";

export const basicDateFormatter = (cell, row) => {
  return format(parse(cell, "yyyy-MM-dd", new Date()), "dd-MMMM-yyyy", {
    locale: es
  });
};

export const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
  let date;
  if (cell instanceof Date) {
    date = cell;
  } else {
    date = parseISO(cell);
    if (row) {
      if ("start_time" in row) {
        if (row.start_time !== null) {
          date = parse(row.start_time, "HH:mm:ss", date)
        }
      }
    }
  }
  return (
    <span>
      {format(date, "dd-MMMM-yyyy", { locale: es })} &nbsp;
      <span className="text-primary">{format(date, "h:mm a")}</span>
    </span>
  );
};

export const dateFormatterMilli = (cell, row, rowIndex, formatExtraData) => {
  const date = parse(cell, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXX", new Date());
  return (
    <span>
      {format(date, "dd-MMMM-yyyy", { locale: es })} &nbsp;
      <span className="text-primary">{format(date, "h:mm a")}</span>
    </span>
  );
};

export const dateFormatterMilliNoTime = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => {
  const date = parse(cell, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXX", new Date());
  return <span>{format(date, "dd-MMMM-yyyy", { locale: es })} &nbsp;</span>;
};

export const endDateFormatter = (cell, row, rowIndex, formatExtraData) => {
  const date = parse(cell, "yyyy-MM-dd'T'HH:mm:ssXX", new Date());
  return format(date, "dd-MMMM-yyyy", { locale: es });
};

export const nameFormatter = (cell, row) => {
  return (
    <span className="d-flex align-items-center">
      <span className="mr-2" style={{ color: cell.hex_color, fontSize: "7px" }}>
        <FontAwesomeIcon icon={faCircle} />
      </span>
      {cell.name}
    </span>
  );
};

export const durationFormatter = (cell, row) => cell + " min.";

export const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
  return (
    <div className="d-flex justify-content-around">
      <Button
        onClick={formatExtraData.handleShow}
        value={row.id}
        className="p-0"
        variant="link"
      >
        <i style={{ fontSize: "14px" }} className="mr-1">
          <FontAwesomeIcon icon={faEdit} />
        </i>
        Editar
      </Button>
      <Button
        value={row.id}
        onClick={formatExtraData.handleShowDelete}
        className="text-danger"
        variant="link"
      >
        <i>
          <FontAwesomeIcon icon={faTrashAlt} />
        </i>{" "}
        Eliminar
      </Button>
    </div>
  );
};

export const scheduleFormatter = (cell, row, rowIndex, formatExtraData) => {
  var program = formatExtraData.programs.find(el => el.id === cell);
  const monClassName = program.monday
    ? "mr-1 text-success font-weight-bold"
    : "mr-1 ";
  const tueClassName = program.tuesday
    ? "mr-1 text-success font-weight-bold"
    : "mr-1 ";
  const wedClassName = program.wednesday
    ? "mr-1 text-success font-weight-bold"
    : "mr-1 ";
  const thuClassName = program.thursday
    ? "mr-1 text-success font-weight-bold"
    : "mr-1 ";
  const friClassName = program.friday
    ? "mr-1 text-success font-weight-bold"
    : "mr-1 ";
  const satClassName = program.saturday
    ? "mr-1 text-success font-weight-bold"
    : "mr-1 ";
  const sunClassName = program.sunday
    ? "mr-1 text-success font-weight-bold"
    : "mr-1";
  return (
    <span className="text-secondary">
      <span className={monClassName}>L</span>-
      <span className={tueClassName}>M</span>-
      <span className={wedClassName}>M</span>-
      <span className={thuClassName}>J</span>-
      <span className={friClassName}>V</span>-
      <span className={satClassName}>S</span>-
      <span className={sunClassName}>D</span>
    </span>
  );
};
