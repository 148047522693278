import axios from "axios";

export default axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? "http://192.168.1.143:8000/api/v1"
      : "https://safegymapp.xyz/api/v1",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("access")
  }
});
