import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { parseISO } from "date-fns";
import { Col, Row } from "react-bootstrap";
import styled from "@emotion/styled";

import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import FacebookLoader from "../misc/FacebookLoader";
import { Bar, Doughnut } from "react-chartjs-2";

const Overview = ({ selectedGym }) => {
  const [loading, setLoading] = useState(true);
  const [dashStats, setDashStats] = useState({});

  const renderBarChart = () => {
    if (loading) return <FacebookLoader />;
    let data = [];
    let labels = [];
    dashStats.today_rsvp_per_hour.forEach(item => {
      labels.push(parseISO(item.date_time));
      data.push(item.count);
    });
    return (
      <Bar
        data={{
          labels: labels,
          datasets: [
            {
              label: "Reservaciones Por Hora - Hoy",
              backgroundColor: "rgba(58, 134, 255,0.9)",
              borderColor: "rgb(255, 99, 132)",
              data: data
            }
          ]
        }}
        options={{
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                },
                type: "time",
                time: {
                  displayFormats: {
                    // hour: "ha"
                    unit: "hour"
                  }
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ]
          }
        }}
      />
    );
  };

  const renderDonutChart = () => {
    if (loading) return <FacebookLoader />;
    let labels = [];
    let data = [];
    let bgColors = [];
    dashStats.areas_today.forEach(item => {
      labels.push(item.label);
      data.push(item.count);
      bgColors.push(item.color);
    });
    return (
      <Doughnut
        height={300}
        data={{
          labels: labels,
          datasets: [
            {
              label: "Hoy",
              backgroundColor: bgColors,
              data: data
            }
          ]
        }}
        options={{
          title: {
            display: true,
            text: "Reservaciones Por Area - Hoy"
          }
        }}
      />
    );
  };

  useEffect(() => {
    const getData = () => {
      if (!selectedGym) return;
      safeGymApi
        .get(`/charts/dashboard/?gym=${selectedGym.id}`, {
          headers: getAuthHeader()
        })
        .then(response => {
          setDashStats(response.data);
          setLoading(false);
        });
    };

    getData();
  }, [selectedGym]);

  const StatCard = styled.div`
    background-color: white;
    border-radius: 8px;
    border: 1px solid #e1e4e8;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0.3rem 0.8rem 0 rgba(0, 0, 0, 0.1);
  `;

  return (
    <React.Fragment>
      <h2 className="text-secondary pb-4">Dashboard</h2>
      <Row>
        <Col lg={4}>
          <StatCard>
            <p className="text-secondary">Reservaciones de Hoy</p>
            {loading ? (
              <FacebookLoader />
            ) : (
              <p
                style={{ fontSize: "40px" }}
                className="py-2 font-weight-bold text-success"
              >
                {dashStats.today_rsvp_count}
              </p>
            )}
          </StatCard>
        </Col>
        <Col lg={4}>
          <StatCard>
            <p className="text-secondary">Reservaciones de Mañana</p>
            {loading ? (
              <FacebookLoader />
            ) : (
              <p
                style={{ fontSize: "40px" }}
                className="py-2 font-weight-bold text-primary"
              >
                {dashStats.tomorrow_rsvp_count}
              </p>
            )}
          </StatCard>
        </Col>
        <Col lg={4}>
          <StatCard>
            <p className="text-secondary">
              Reservaciones en los siguientes 7 dias
            </p>
            {loading ? (
              <FacebookLoader />
            ) : (
              <p
                style={{ fontSize: "40px" }}
                className="py-2 font-weight-bold text-info"
              >
                {dashStats.next_7_rsvp_count}
              </p>
            )}
          </StatCard>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={8}>
          <StatCard>{renderBarChart()}</StatCard>
        </Col>
        <Col lg={4}>
          <StatCard>{renderDonutChart()}</StatCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return { selectedGym: state.selectedGym.data };
};

export default connect(mapStateToProps)(Overview);
