import React, { useState, useEffect } from "react";
import PlainTextFormField from "../misc/PlainTextFormField";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { parseISO, format } from "date-fns";
import { es } from "date-fns/locale";

import safeGymApi from "../../apis/safegym";
import EllipsisLoader from "../misc/EllipsisLoader";
import { getAuthHeader } from "../../helpers/tokens";

const ActionsModal = ({ show, handleClose, selectedMember, data, setData }) => {
  const [expirationDate, setExpirationDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [verifLevel, setVerifLevel] = useState(
    selectedMember.verification_level
  );

  const options = [
    { label: "Verificado", value: 2 },
    { label: "No Verificado", value: 0 }
  ];

  const updateVerification = obj => {
    setVerifLevel(obj.value);
  };

  const renderAlert = () => {
    if (!errorMessage) return;
    return <Alert variant="danger">{errorMessage}</Alert>;
  };

  const renderFormField = (label, name) => (
    <Form.Group>
      <Form.Row>
        <Col>
          <Form.Label>{label}</Form.Label>
        </Col>
        <Col>
          <Field name={name} />
          <ErrorMessage name={name} />
        </Col>
      </Form.Row>
    </Form.Group>
  );

  useEffect(() => {
    setVerifLevel(selectedMember.verification_level);
    const memb = selectedMember.membership;
    if (!memb) {
      setExpirationDate(null);
    } else {
      setExpirationDate(parseISO(selectedMember.membership.expiration));
    }
  }, [selectedMember]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik
        initialValues={{
          first_name: selectedMember.first_name,
          phone: selectedMember.phone,
          external_gym_id: selectedMember.external_gym_id
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (expirationDate !== null) {
            values["membership"] = {
              expiration: format(expirationDate, "yyyy-MM-dd'T'HH:mm:00XX")
            };
          }

          safeGymApi
            .patch(
              `/user/${selectedMember.id}/update/`,
              { ...values, verification_level: verifLevel },
              {
                headers: getAuthHeader()
              }
            )
            .then(response => {
              const memberId = selectedMember.id;
              const newData = data.map(m =>
                m.id === memberId ? response.data : m
              );
              setData(newData);
              setSubmitting(false);
              handleClose();
            })
            .catch(err => {
              setErrorMessage(err.response.data.detail);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header closeButton>
              <Modal.Title>Acciones</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <h5>Datos del usuario</h5>
              <PlainTextFormField label="E-Mail" value={selectedMember.email} />
              {renderFormField("Nombre", "first_name")}
              {renderFormField("Teléfono", "phone")}
              {renderFormField("ID / # de Socio", "external_gym_id")}
              <Form.Group>
                <Form.Label className="pb-0 mb-0">
                  Expiración de Membresía
                </Form.Label>{" "}
                <small
                  style={{ fontSize: "0.7rem" }}
                  className="text-info p-0 m-0"
                >
                  *NO aplicable para créditos
                </small>
                <DatePicker
                  className="form-control"
                  selected={expirationDate}
                  dateFormat="d 'de' MMMM 'del' yyyy"
                  locale={es}
                  onChange={d => setExpirationDate(d)}
                />
              </Form.Group>
              <hr />
              <Form.Group as={Row}>
                <Form.Label column sm="5">
                  Estado
                </Form.Label>
                <Col>
                  <Select
                    onChange={updateVerification}
                    options={options}
                    defaultValue={
                      selectedMember.verification_level >= 2
                        ? options[0]
                        : options[1]
                    }
                  />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Guardar Cambios"}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default ActionsModal;
