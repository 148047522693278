import { combineReducers } from "redux";

import tokensReducer from "./tokensReducer";
import userReducer from "./userReducer";
import headersReducer from "./headersReducer";
import ownedGymsReducer from "./ownedGymsReducer";
import selectedGymReducer from "./selectedGymReducer";
import classProgramsReducer from "./classProgramsReducer";
import customerIdReducer from "./customerIdReducer";

export default combineReducers({
  tokens: tokensReducer,
  user: userReducer,
  headers: headersReducer,
  ownedGyms: ownedGymsReducer,
  selectedGym: selectedGymReducer,
  classPrograms: classProgramsReducer,
  customerId: customerIdReducer
});
