import React from "react";
import { Route, Switch } from "react-router-dom";
import Overview from "./Overview";
import EventsCalendar from "./EventsCalendar";
import ClassesTable from "./ClassesTable";
import AreaEventsCalendar from "./AreaEventsCalendar";
import Members from "./Members";
import Notifications from "./Notifications";
import Reservations from "./Reservations";
import Toolbox from "./Toolbox";
import Billing from "./Billing";
import Account from "./Account";
import NewCard2 from "./Billing/NewCard2";
import Support from "./Support";
// import News from "./News";
import CreateStaffUser from "./Account/CreateStaffUser";
import Memberships from "./Memberships";
import MemberDetails from "./Members/MemberDetails";
import Reports from "./Reports";

const Routes = () => {
  return (
    <Switch>
      <Route path="/home" exact component={Overview} />
      <Route path="/classes" exact component={EventsCalendar} />
      <Route path="/classes/table" exact component={ClassesTable} />
      <Route path="/areas" exact component={AreaEventsCalendar} />
      <Route path="/members" exact component={Members} />
      <Route path="/members/details/:id" component={MemberDetails} />
      <Route path="/notifications" exact component={Notifications} />
      <Route path="/reservations" exact component={Reservations} />
      <Route path="/toolbox" exact component={Toolbox} />
      <Route path="/billing" exact component={Billing} />
      <Route path="/account" exact component={Account} />
      <Route path="/reports" exact component={Reports} />
      <Route path="/billing/new-card" exact component={NewCard2} />
      <Route path="/support" exact component={Support} />
      {/*<Route path="/news" exact component={News} />*/}
      <Route path="/new-staff" exact component={CreateStaffUser} />
      <Route path="/memberships" exact component={Memberships} />
    </Switch>
  );
};

export default Routes;
