import React, {useEffect, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {useLocation, useParams} from "react-router-dom";
import {Button, Card, Row, Col} from "react-bootstrap";
import {parseISO, format} from "date-fns";
import {es} from "date-fns/locale";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faEdit} from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import safeGymApi from "../../apis/safeGymApi";
import {getAuthHeader} from "../../helpers/tokens";
import DeleteUserModal from "./DeleteUserModal";
import CreditsModal from "./CreditsModal";
import LoadingRoller from "../misc/LoadingRoller";
import safegym from "../../apis/safegym";
import RemotePaginationTable from "../../helpers/RemotePaginationTable";
import EditCreditsModal from "./EditCreditsModal";

const MemberDetails = ({history}) => {
  const location = useLocation();
  const params = useParams();

  const [membershipHistory, setMembershipHistory] = useState([]);
  const [membershipUpdatesHistory, setMembershipUpdatesHistory] = useState([]);
  const [creditsHistory, setCreditsHistory] = useState([]);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [showEditCreditsModal, setShowEditCreditsModal] = useState(false);
  const [selectedCredits, setSelectedCredits] = useState();
  const [positiveCredits, setPositiveCredits] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [creditsHistPage, setCreditsHistPage] = useState(1);
  const [creditsHistCount, setCreditsHistCount] = useState(0);

  useEffect(() => {
    if (location.state) {
      setUser(location.state.user);
      setLoading(false);
    } else {
      safegym
        .get(`/user/details/${params.id}/`, {headers: getAuthHeader()})
        .then(resp => {
          setUser(resp.data);
          setLoading(false);
        })
        .catch(() => alert("Error obteniendo el usuario."));
    }
  }, [location, params]);

  useEffect(() => {
    console.log(user);
    if (user) {
      safeGymApi
        .get(`/memberships/history/?u=${user.id}`, {
          headers: getAuthHeader()
        })
        .then(resp => {
          setMembershipHistory(resp.data.results);
        });
      safeGymApi
        .get(`/memberships/history/membership-updates/?u=${user.id}`, {
          headers: getAuthHeader()
        })
        .then(resp => {
          setMembershipUpdatesHistory(resp.data.results);
        });
      safeGymApi
        .get(`/credits/v2/transactions/?u=${user.id}`, {
          headers: getAuthHeader()
        })
        .then(resp => {
          setCreditsHistory(resp.data.results);
          setCreditsHistCount(resp.data.count);
        });
    }
  }, [user]);

  const StatCard = styled.div`
    background-color: white;
    border-radius: 8px;
    border: 1px solid #e1e4e8;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0.3rem 0.8rem 0 rgba(0, 0, 0, 0.1);
  `;

  const simpleDateFormat = date => {
    if (!date) {
      return <React.Fragment/>;
    }
    return <span>{format(parseISO(date), "dd-MMM-yyyy", {locale: es})}</span>;
  };

  const simpleDateTimeFormat = date => {
    if (!date) {
      return <React.Fragment/>;
    }
    const parsed = parseISO(date);
    return (
      <React.Fragment>
        <span>{format(parsed, "dd-MMM-yyyy", {locale: es})}</span>{" "}
        <span className="text-primary">{format(parsed, "HH:mm")}</span>
      </React.Fragment>
    );
  };

  const renderCreditsDetails = () => {
    if (user.credits2.length === 0) {
      return <h2 className="text-success">N/A</h2>;
    }
    let totalCredits = 0;
    user.credits2.forEach(c => {
      totalCredits += c.current_amount;
    });
    return (
      <div>
        <h2 className="text-success">{totalCredits} </h2>
        {user.credits2.map(c => {
          return (
            <div className="border border-rounded my-1 p-1" key={c.id}>
              <p className="m-0">
                <strong>{c.current_amount} </strong>
                {c.current_amount > 1
                  ? "créditos vencen el "
                  : "crédito vence el "}
                {simpleDateFormat(c.expire_on)}
                <span onClick={() => {
                  setSelectedCredits(c)
                  setShowEditCreditsModal(true)
                }} style={{cursor: "pointer"}} className="text-primary ml-2"><FontAwesomeIcon icon={faEdit}/></span>
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const onCreditsTableChange = (type, {page, sizePerPage}) => {
    setCreditsHistPage(page);
    safeGymApi
      .get(`/credits/v2/transactions/?u=${user.id}&page=${page}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        setCreditsHistory(resp.data.results);
      });
  };

  const userFormatter = (cell, row) => {
    return <div>{!cell.first_name ? cell.email : cell.first_name}</div>;
  };

  const membershipUpdatesColumns = [
    {
      text: "Actualizado por",
      dataField: "updated_by.email"
    },
    {
      text: "Vencimiento original",
      dataField: "old_expiration",
      formatter: cell => simpleDateFormat(cell)
    },
    {
      text: "Vencimiento nuevo",
      dataField: "new_expiration",
      formatter: cell => simpleDateFormat(cell)
    },
    {
      text: "Fecha de movimiento",
      dataField: "created_on",
      formatter: cell => simpleDateTimeFormat(cell)
    }
  ];

  const transactionColumns = [
    {
      text: "Cliente",
      dataField: "user",
      formatter: userFormatter
    },
    {
      text: "Concepto",
      dataField: "membership_plan.title"
    },
    {
      text: "Monto",
      dataField: "membership_plan.price"
    },
    {
      text: "Método de pago",
      dataField: "payment_method"
    },
    {
      text: "Atendió",
      dataField: "seller",
      formatter: userFormatter
    },
    {
      text: "Fecha",
      dataField: "created_on",
      formatter: cell => (
        <span>
          {format(parseISO(cell), "dd-MMM-yy", {locale: es})}{" "}
          <span className="text-primary">
            {format(parseISO(cell), "HH:mm")}
          </span>
        </span>
      )
    }
  ];

  const creditsTransactionsColumns = [
    {
      text: "Créditos",
      dataField: "amount",
      formatter: cell => (cell > 0 ? `+${cell}` : cell)
    },
    {
      text: "Concepto",
      dataField: "description"
    },
    {
      text: "Creado por",
      dataField: "transaction_by.email"
    },
    {
      text: "Fecha",
      dataField: "created_on",
      formatter: cell => (
        <span>{format(parseISO(cell), "dd-MMM-yy", {locale: es})}</span>
      )
    }
  ];
  if (loading) {
    return LoadingRoller();
  }
  return (
    <React.Fragment>
      <DeleteUserModal
        show={showDeleteUserModal}
        onHide={() => setShowDeleteUserModal(false)}
        user={user}
      />
      <CreditsModal
        selectedMember={user}
        show={showCreditsModal}
        onHide={() => setShowCreditsModal(false)}
        isPositive={positiveCredits}
        user={user}
        setUser={setUser}
      />
      <EditCreditsModal
        show={showEditCreditsModal}
        onHide={() => {
          setShowEditCreditsModal(false);
          setSelectedCredits(undefined);
        }}
        credits={selectedCredits}
        user={user}
        setUser={setUser}
      />
      <Button
        onClick={() => history.goBack()}
        className="mb-2"
        variant="outline-primary"
        size="sm"
      >
        <FontAwesomeIcon icon={faArrowLeft}/> Atrás
      </Button>

      <Row className="mb-3">
        <Col lg={6}>
          <StatCard className="mb-2">
            <h5>Membresía</h5>
            <h2 className="text-success mb-0 pb-0">
              {user.membership === null
                ? "N/A"
                : format(parseISO(user.membership.expiration), "dd-MMM-yyyy", {
                  locale: es
                })}
            </h2>
            <p>
              <small className=" text-secondary mt-0 pt-0">
                {user.membership_plan === null
                  ? ""
                  : user.membership_plan.title}
              </small>
            </p>
          </StatCard>
          <StatCard>
            {user.first_name}
            <p className="p-0 m-0">{user.email}</p>
            <p className="p-0 m-0">{user.phone}</p>
            <Button
              onClick={() => setShowDeleteUserModal(true)}
              block
              variant="outline-danger"
              size="sm"
            >
              Eliminar Usuario
            </Button>
          </StatCard>
        </Col>
        <Col lg={6}>
          <StatCard>
            <div>
              <h5>Créditos</h5>
            </div>
            {renderCreditsDetails()}
            <div className="text-right">
              <Button
                onClick={() => {
                  setPositiveCredits(true);
                  setShowCreditsModal(true);
                }}
                className="mr-2"
                variant="outline-success"
                size="sm"
              >
                + Agregar
              </Button>
              <Button
                onClick={() => {
                  setPositiveCredits(false);
                  setShowCreditsModal(true);
                }}
                variant="outline-danger"
                size="sm"
              >
                - Quitar
              </Button>
            </div>
          </StatCard>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Card className="">
            <Card.Header>Historial de membresías</Card.Header>
            <Card.Body>
              <BootstrapTable
                keyField="id"
                columns={transactionColumns}
                data={membershipHistory}
                noDataIndication="No se han registrado membresías con este usuario."
                wrapperClasses="table-responsive"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Header>
              Historial actualizaciones a la vigencia de membresía
            </Card.Header>
            <Card.Body>
              <BootstrapTable
                keyField="id"
                columns={membershipUpdatesColumns}
                data={membershipUpdatesHistory}
                noDataIndication="No se han registrado actualizaciones de membresía a este usuario."
                wrapperClasses="table-responsive"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>Transacciones Créditos</Card.Header>
            <Card.Body>
              <RemotePaginationTable
                data={creditsHistory}
                page={creditsHistPage}
                sizePerPage={10}
                totalSize={creditsHistCount}
                columns={creditsTransactionsColumns}
                onTableChange={onCreditsTableChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default MemberDetails;
