import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Card, Image, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ImageUploader from "react-images-upload";

import GymNameModal from "./GymNameModal";
import ScheduleCard from "./ScheduleCard";
import ScheduleModal from "./ScheduleModal";
import RsvpSettingsCard from "./RsvpSettingsCard";
import SuccessAlert from "../misc/SuccessAlert";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import GeneralDataCard from "./GeneralDataCard";
import { updateGymCoverImg, updateGymLogoImg } from "../../actions";

const Toolbox = props => {
  const dispatch = useDispatch();

  const [showGymNameModal, setShowGymNameModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [newLogoImg, setNewLogoImg] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [coverImage, setCoverImage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fileSelectHandler = ev => {
    setNewLogoImg(ev.target.files[0]);
  };

  const fileUploadHandler = async () => {
    setSubmitting(true);
    const formData = new FormData();
    formData.append("logo_img", newLogoImg, newLogoImg.name);
    const { status, data } = await safeGymApi.put(
      `/gym/${props.selectedGym.data.id}/`,
      formData,
      {
        headers: getAuthHeader()
      }
    );

    if (status === 200) {
      dispatch(updateGymLogoImg(data.logo_img));
    } else {
      alert("Error al intentar actualizar la imagen.");
    }
    setSubmitting(false);
  };

  const handleUpdateCoverImage = () => {
    setSubmitting(true);
    const formData = new FormData();
    formData.append("cover_img", coverImage, coverImage.name);
    safeGymApi
      .put(`/gym/${props.selectedGym.data.id}/`, formData, {
        headers: getAuthHeader()
      })
      .then(resp => {
        setCoverImage(null);
        setSubmitting(false);
        dispatch(updateGymCoverImg(resp.data.cover_img));
      })
      .catch(err => console.log(err));
  };

  const renderAlert = () => {
    if (showSuccessAlert) {
      return (
        <SuccessAlert
          text={alertText}
          onHide={() => setShowSuccessAlert(false)}
        />
      );
    }
    return null;
  };

  if (props.selectedGym.data === undefined) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <React.Fragment>
      <h2 className="text-secondary pb-2">Personalización</h2>
      <GymNameModal
        show={showGymNameModal}
        setShowGymNameModal={setShowGymNameModal}
        gymData={props.selectedGym.data}
      />
      <ScheduleModal
        show={showScheduleModal}
        setShow={setShowScheduleModal}
        gymData={props.selectedGym.data}
        setAlertText={setAlertText}
        setShowSuccessAlert={setShowSuccessAlert}
      />
      {renderAlert()}
      <Row>
        <Col lg={4}>
          <Card>
            <Card.Header>
              <div className="d-flex row-flex justify-content-between align-items-center">
                <h4 className="pt-1 text-primary">
                  {props.selectedGym.data.name}
                </h4>
                <Button
                  variant="link"
                  onClick={() => setShowGymNameModal(true)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Image src={props.selectedGym.data.logo_img} fluid />
              <div className="d-flex row-flex justify-content-between mt-1">
                <p className="mr-auto">Actualizar imagen:</p>
                <input
                  className="filestyle p-0 m-0"
                  type="file"
                  onChange={fileSelectHandler}
                />
              </div>
              <Button
                disabled={submitting}
                onClick={fileUploadHandler}
                size="sm"
                hidden={newLogoImg === null}
              >
                {submitting ? <EllipsisLoader /> : "Subir Imagen"}
              </Button>
            </Card.Body>
          </Card>
          <Card className="mt-3">
            <Card.Header className="text-primary">Cover Image</Card.Header>
            <Card.Body>
              <Image src={props.selectedGym.data.cover_img} fluid />
              <ImageUploader
                withPreview={true}
                withIcon={false}
                buttonText="Cambiar imagen de cover"
                imgExtension={[".jpg", ".jpeg", ".png"]}
                label={"Tamaño máximo 2MB"}
                singleImage={true}
                maxFileSize={2000000}
                onChange={pic => setCoverImage(pic[0])}
                withLabel={true}
              />
              {coverImage ? (
                <Button disabled={submitting} onClick={handleUpdateCoverImage}>
                  Subir Imagen
                </Button>
              ) : (
                <React.Fragment />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8}>
          <ScheduleCard
            gymData={props.selectedGym.data}
            setShow={setShowScheduleModal}
          />
          <div className="mt-4"></div>
          <RsvpSettingsCard />
        </Col>
      </Row>
      <Row className="my-4">
        <Col lg={8}>
          <GeneralDataCard />
        </Col>
        <Col lg={4}></Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return { selectedGym: state.selectedGym };
};

export default connect(mapStateToProps)(Toolbox);
