import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { format, endOfDay, addDays } from "date-fns";
import { number, object } from "yup";
import { Formik } from "formik";
import es from "date-fns/locale/es";

import ClassesSelect from "../EventsCalendar/ClassesSelect";
import DaysRepeatCheckboxes from "../EventsCalendar/DaysRepeatCheckboxes";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import EllipsisLoader from "../misc/EllipsisLoader";

const NewAreaModal = ({ show, onHide, showAlert, addNewSchedules }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [selectClassValue, setSelectClassValue] = useState(null);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [displayError, setDisplayError] = useState(null);

  const redAsterisk = () => <span className="text-danger">*</span>;

  const renderError = () => {
    if (displayError) {
      return <p className="text-danger">{displayError}</p>;
    }
  };

  const newAreaValidation = object({
    minutes: number()
      .required("Campo requerido. Solo números.")
      .positive("Solo numeros positivos."),
    peopleLimit: number()
      .required("Campo requerido. Solo números.")
      .positive("Solo numeros positivos.")
  });

  useEffect(() => {
    // End date should always be greater than start date
    if (startDate > endDate) {
      setEndDate(addDays(startDate, 1));
    }
  }, [startDate, endDate]);

  return (
    <Formik
      validationSchema={newAreaValidation}
      initialValues={{
        minutes: "",
        peopleLimit: "",
        selectclass: "",
        time: "",
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }}
      validate={values => {
        const errors = {};
        if (
          values.monday ||
          values.tuesday ||
          values.wednesday ||
          values.thursday ||
          values.friday ||
          values.saturday ||
          values.sunday
        ) {
          setIsRecurrent(true);
        } else {
          setIsRecurrent(false);
        }

        if (!selectClassValue) {
          errors.selectclass = "Campo requerido.";
        }
        if (!startTime) {
          errors.startTime = "Error";
        }

        if (isRecurrent && !endDate) {
          errors.endDate = "Error";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const postData = {
          area: selectClassValue.value,
          minutes_duration: values.minutes,
          is_gym_class: false,
          people_limit: values.peopleLimit,
          monday: values.monday,
          tuesday: values.tuesday,
          wednesday: values.wednesday,
          thursday: values.thursday,
          friday: values.friday,
          saturday: values.saturday,
          sunday: values.sunday,
          is_recurrent: isRecurrent,
          start_date:
            format(startDate, "yyyy-MM-dd") +
            format(startTime, "'T'HH:mm:00XX"),
          end_date: endDate
            ? format(endDate, "yyyy-MM-dd") + format(startTime, "'T'HH:mm:00XX")
            : format(endOfDay(startDate), "yyyy-MM-dd'T'HH:mm:00XX")
        };
        safeGymApi
          .post("/programs/", postData, {
            headers: getAuthHeader()
          })
          .then(response => response.data.id)
          .then(programId => {
            safeGymApi
              .get(`/programs/${programId}/list_related_timeslots/`, {
                headers: getAuthHeader()
              })
              .then(timeslots => {
                addNewSchedules(timeslots.data);
              });
          })
          .then(() => {
            showAlert();
            onHide();
            setSubmitting(false);
          })
          .catch(err => {
            setDisplayError(err.response.data.detail);
            setSubmitting(false);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <Modal show={show} onHide={onHide} size="lg">
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Agregar programación al Calendario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {renderError()}
              <Row>
                <Col>
                  <Form.Group className="step5">
                    <Form.Label>{redAsterisk()}Nombre del Area</Form.Label>
                    <ClassesSelect
                      selectClassValue={selectClassValue}
                      setSelectClassValue={setSelectClassValue}
                      placeholder="Area"
                    />
                    <Form.Text className="text-danger">
                      {errors.selectclass}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="step6">
                    <Form.Label>{redAsterisk()}Limite de Personas</Form.Label>
                    <Form.Control
                      name="peopleLimit"
                      value={values.peopleLimit}
                      onChange={handleChange}
                      type="number"
                      placeholder="Limite de Personas"
                    />
                    <Form.Text className="text-danger">
                      {errors.peopleLimit &&
                        touched.peopleLimit &&
                        errors.peopleLimit}
                    </Form.Text>
                  </Form.Group>
                  <div className="step7">
                    <Form.Group className="w-100 ">
                      <Form.Label>{redAsterisk()}Hora de Inicio</Form.Label>
                      <br />
                      <DatePicker
                        className="form-control w-100 m-0"
                        selected={startTime}
                        onChange={date => setStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Hora"
                        dateFormat="h:mm aa"
                        name="time"
                      />
                    </Form.Group>
                    <Form.Group className="step8">
                      <Form.Label>
                        {redAsterisk()}Duración
                        <span className="text-secondary">(Minutos)</span>
                      </Form.Label>
                      <Form.Control
                        name="minutes"
                        value={values.minutes}
                        onChange={handleChange}
                        type="number"
                        placeholder="Minutos"
                      />
                      <Form.Text className="text-danger">
                        {errors.minutes && touched.minutes && errors.minutes}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="step9">
                      <Form.Label>{redAsterisk()}Fecha de Inicio</Form.Label>
                      <br />
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="EEEE dd 'de' MMMM 'del' yyyy"
                        minDate={new Date()}
                        maxDate={addDays(new Date(), 90)}
                        locale={es}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <div className="step10">
                <div>
                  <DaysRepeatCheckboxes
                    values={values}
                    handleChange={handleChange}
                  />
                </div>
                <Form.Group className="" hidden={!isRecurrent}>
                  <Form.Label>
                    {redAsterisk()}Seguir repitiendo hasta:
                  </Form.Label>
                  <br />
                  <DatePicker
                    className="form-control"
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    dateFormat="EEEE dd 'de' MMMM 'del' yyyy"
                    minDate={addDays(startDate, 1)}
                    maxDate={addDays(new Date(), 89)}
                    locale={es}
                  />
                  <Form.Text className="text-danger">
                    {endDate ? "" : "Campo requerido"}
                  </Form.Text>
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button id="addAreaCloseBtn" variant="secondary" onClick={onHide}>
                Cerrar
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Agregar Programación"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default NewAreaModal;
