import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import LoadingRoller from "../misc/LoadingRoller";
import funcsImage from "../../assets/img/Funcionalidades.png";
import "../../assets/styles/pricing.css";

const SelectPlan = ({ setSelectedPrice, setSelectedPlanDetails, nextStep }) => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleClick = e => {
    const selectedPrice = prices.find(p => p.id === e.target.value);

    setSelectedPrice(selectedPrice.id);
    setSelectedPlanDetails(selectedPrice);
    nextStep();
  };

  const renderPlanFeatures = features => {
    let counter = 0;
    delete features.realPrice;
    return Object.values(features).map(feature => {
      counter += 1;
      return (
        <li key={counter} className="">
          <span className="fa-li">
            <i className="text-success">
              <FontAwesomeIcon icon={faCheck} />
            </i>
          </span>
          {feature}
        </li>
      );
    });
  };

  const renderPlans = () => {
    if (loading) {
      return (
        <div className="text-center w-100">
          <LoadingRoller />
        </div>
      );
    }
    return prices.map(price => {
      return (
        <Col key={price.id}>
          <Card>
            <Card.Body style={{ border: "none" }}>
              <h5 className="card-title text-muted text-uppercase text-center">
                {price.product.name}
              </h5>
              <h4 className="text-center">
                <del>
                  {parseFloat(price.product.metadata.realPrice).toFixed(2)}
                </del>
              </h4>
              <h6 className="card-price text-center">
                ${(price.unit_amount / 100).toFixed(2)}
                <span className="period">/mes</span>
              </h6>
              <hr />
              <ul className="fa-ul">
                {renderPlanFeatures(price.product.metadata)}
              </ul>
              <Button
                block
                data-prod-id={price.product.id}
                onClick={handleClick}
                value={price.id}
              >
                Seleccionar
              </Button>
            </Card.Body>
          </Card>
        </Col>
      );
    });
  };

  useEffect(() => {
    const getProducts = async () => {
      const { status, data } = await safeGymApi.get("/orders/prices/", {
        headers: getAuthHeader()
      });
      if (status === 200) {
        const sortedPrices = data.prices.sort(
          (a, b) => a.unit_amount > b.unit_amount
        );
        setPrices(sortedPrices);
      } else {
        alert("Error en el servidor.");
      }
      setLoading(false);
    };
    getProducts();
  }, []);

  return (
    <div>
      <div className="pricing text-left">
        <h2 className="text-secondary text-center pb-5 pt-2">
          Selecciona tu plan para activar tu cuenta
        </h2>
        <Row className="pb-5">{renderPlans()}</Row>
      </div>

      <Row className="mt-4">
        <Col>
          <hr />
          <Image src={funcsImage} fluid />
        </Col>
      </Row>
      <Row className="faq text-left pt-5">
        <hr />
        <h1 className=" pb-3">Preguntas Frecuentes</h1>
        <div>
          <div className="">
            <h4>Aún faltan días para la re-apertura de mi gimnasio</h4>
            <p>
              Puedes activar tu cuenta en cualquier momento para compartir la
              app con tus clientes y comiencen a registrarse en ella, así
              estarás listo el día de reapertura.
            </p>
          </div>
        </div>
        <div className="pt-3">
          <h4>¿Puedo cancelar?</h4>
          <p>
            Claro, no hay planes ni contratos forzosos, cancelar no tiene costo
            y puedes hacerlo cuando quieras.
          </p>
        </div>

        <div className="pt-3">
          <h4>¿Puedo cancelar antes de que termine mi periodo de prueba?</h4>
          <p>
            Si, si no estas satisfecho con nuestro servicio puedes cancelar
            antes de esta fecha y no se realizará ningún cobro
          </p>
        </div>
        <div className="pt-3">
          <h4>¿Hay costo inicial o adicional?</h4>
          <p>No, no hay costos iniciales ni sorpresas.</p>
        </div>

        <div className="pt-3">
          <h4>¿Puedo cambiarme de plan en el futuro?</h4>
          <p>
            Si, si tu numero de clientes activos disminuye u aumenta puedes
            cambiar de plan en cualquier momento.
          </p>
        </div>
        <div className="pt-3">
          <h4>Sucursales</h4>
          <p>
            Los clientes activos se reparten en tus sucursales. Por ejemplo: Si
            cuentas con el plan premium y 3 sucursales, 1 sucursal puede tener
            500 otra 500 y otra 200.
          </p>
          <p>
            El total sumado de las distintas cuentas no debe sobrepasar el
            límite de tu plan, si cuentas con más de 1200 contáctanos.
          </p>
        </div>
        <div className="pt-3">
          <h4>¿Cuándo aparecerá mi gimnasio en la App?</h4>
          <p>
            Tan pronto actives tu cuenta, los clientes podrán buscar tu gimnasio
            por su nombre y registrarse.
          </p>
        </div>
        <div className="pt-3">
          <h4>¿Recibiré algún tipo de material promocional?</h4>
          <p>
            Si, recibirás un video profesional personalizado con tu logo para
            compartir con tus clientes sobre como usar la app.
          </p>
        </div>
      </Row>
    </div>
  );
};

export default SelectPlan;
