import React, { useEffect, useState } from "react";
import {
  Modal,
  Alert,
  Form,
  OverlayTrigger,
  Tooltip,
  Button
} from "react-bootstrap";
import { Formik, Form as FormikForm, Field } from "formik";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const EditPlanModal = ({ show, onHide, plan }) => {
  const [success, setSuccess] = useState(null);
  const [isCreditsPlan, setIsCreditsPlan] = useState(false);

  const renderAlert = () => {
    if (success === null) return;
    const message = success
      ? "Se han actualizado los datos con éxito."
      : "Ocurrió un error.";
    return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
  };

  const handleClose = () => {
    setSuccess(null);
    setIsCreditsPlan(false);
    onHide();
  };

  useEffect(() => {
    if (plan !== null && plan !== undefined) {
      setIsCreditsPlan(plan.is_credits_plan);
    }
  }, [plan]);

  const renderCreditsCheckbox = () => {
    if (!plan) return <React.Fragment />;
    return (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip id="addMembCredits">
            Si activas esta opción, se le asignaran créditos al usuario con esta
            membresía.
          </Tooltip>
        }
      >
        <Form.Group>
          <Form.Check
            defaultChecked={plan.is_credits_plan}
            type="checkbox"
            label="Créditos"
            onChange={val => {
              setIsCreditsPlan(val.target.checked);
            }}
          />
        </Form.Group>
      </OverlayTrigger>
    );
  };

  if (!plan) return <React.Fragment />;
  return (
    <Modal show={show} onHide={handleClose}>
      <Formik
        initialValues={{
          title: plan.title,
          days_duration: plan.days_duration,
          price: plan.price,
          credits_count: plan.credits_count
        }}
        onSubmit={(values, { setSubmitting }) => {
          safeGymApi
            .put(
              `/memberships/plans/${plan.id}/`,
              { ...values, is_credits_plan: isCreditsPlan },
              {
                headers: getAuthHeader()
              }
            )
            .then(res => {
              setSuccess(true);
              setSubmitting(false);
            })
            .catch(err => {
              setSuccess(false);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header>
              <div className="text-primary">Editar Membresía</div>
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <Form.Group>
                <Form.Label>Título</Form.Label>
                <Field className="form-control form-control-sm" name="title" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Vigencia (Días)</Form.Label>
                <Field
                  className="form-control form-control-sm"
                  type="number"
                  name="days_duration"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Precio</Form.Label>
                <Field
                  className="form-control form-control-sm"
                  type="number"
                  name="price"
                />
              </Form.Group>
              {renderCreditsCheckbox()}
              {isCreditsPlan ? (
                <Form.Group>
                  <Form.Label>Cantidad de Créditos</Form.Label>
                  <Field
                    className="form-control form-control-sm"
                    type="number"
                    placeholder=""
                    name="credits_count"
                    min="0"
                    max="10000"
                  />
                </Form.Group>
              ) : (
                <React.Fragment />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button type="submit" disabled={isSubmitting || success}>
                {isSubmitting ? <EllipsisLoader /> : "Confirmar"}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default EditPlanModal;
