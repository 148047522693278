import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import FadeIn from "react-fade-in";
import { format, fromUnixTime } from "date-fns";
import { Row, Col, Card, Button, ListGroup, Image } from "react-bootstrap";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex
} from "@fortawesome/free-brands-svg-icons";
import {
  faCreditCard,
  faPlus,
  faCheck,
  faTimes,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import {
  faStopwatch,
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { es } from "date-fns/locale";
import axios from "axios";

import LoadingRoller from "../../misc/LoadingRoller";
import safeGymApi from "../../../apis/safegym";
import { getAuthHeader } from "../../../helpers/tokens";
import { Link } from "react-router-dom";
import EditCardModal from "./EditCardModal";
import DeleteCardModal from "./DeleteCardModal";

const BillingDetails = () => {
  const [subs, setSubs] = useState([]);
  const [charges, setCharges] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currentSub, setCurrentSub] = useState({});
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(true);

  const dateFormatter = (cell, row) => {
    return format(fromUnixTime(cell), "d 'de' MMMM 'del' yyyy", {
      locale: es
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const priceFormatter = (cell, row) => {
    return `$ ${(cell / 100).toFixed(2)}`;
  };

  const chargeStatusFormatter = (cell, row) => {
    if (cell === "succeeded") {
      return (
        <div>
          <i className="mr-1 text-success">
            <FontAwesomeIcon icon={faCheckCircle} />
          </i>
          Completado
        </div>
      );
    } else if (cell === "pending") {
      return (
        <div>
          <i className="mr-1 text-warning">
            <FontAwesomeIcon icon={faStopwatch} />
          </i>
          Pendiente
        </div>
      );
    } else {
      return (
        <div>
          <i className="mr-1 text-danger">
            <FontAwesomeIcon icon={faTimesCircle} />
          </i>
          Error
        </div>
      );
    }
  };

  const statusFormatter = (cell, row) => {
    switch (cell) {
      case "trialing":
        return (
          <div>
            <i className="mr-1 text-warning">
              <FontAwesomeIcon icon={faStopwatch} />
            </i>
            Periodo de Prueba
          </div>
        );
      case "active":
        return (
          <div>
            <i className="mr-1 text-success">
              <FontAwesomeIcon icon={faCheckCircle} />
            </i>
            Activo
          </div>
        );
      default:
        return (
          <div>
            <i className="mr-1 text-danger">
              <FontAwesomeIcon icon={faTimesCircle} />
            </i>
            Error
          </div>
        );
    }
  };

  const renderBrandIcon = brand => {
    var icon = null;
    if (brand === "visa") {
      icon = faCcVisa;
    } else if (brand === "mastercard") {
      icon = faCcMastercard;
    } else if (brand === "amex") {
      icon = faCcAmex;
    } else {
      icon = faCreditCard;
    }
    return icon;
  };

  const chargesColumns = [
    {
      text: "Cargo",
      dataField: "amount",
      formatter: priceFormatter
    },
    {
      text: "Fecha",
      dataField: "created",
      formatter: dateFormatter
    },
    {
      text: "Metodo de pago",
      dataField: "payment_method_details.card.brand"
    },
    {
      text: "Estado",
      dataField: "status",
      formatter: chargeStatusFormatter
    }
  ];

  const columns = [
    {
      text: "Estado",
      dataField: "status",
      formatter: statusFormatter
    },
    {
      text: "Inicio Periodo",
      dataField: "current_period_start",
      formatter: dateFormatter
    },
    {
      text: "Final de Periodo",
      dataField: "current_period_end",
      formatter: dateFormatter
    },
    {
      text: "Pago",
      dataField: "plan.amount",
      formatter: priceFormatter
    }
  ];

  const renderStatus = status => {
    let icon;
    let className = "text-danger";
    let text;

    if (status === "active") {
      icon = faCheck;
      className = "text-success";
      text = "Activo";
    } else if (status === "trialing") {
      icon = faStopwatch;
      className = "text-warning";
      text = "Periodo de Prueba";
    } else if (status === "canceled") {
      icon = faTimes;
      text = "Cancelado";
    } else {
      icon = faExclamationTriangle;
      text = "Error";
    }
    return (
      <ListGroup.Item className={className} style={{ borderTop: "none" }}>
        <i className="mr-2">
          <FontAwesomeIcon icon={icon} />
        </i>
        <span>{text}</span>
      </ListGroup.Item>
    );
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getSubs = async () => {
      const { data } = await safeGymApi.get("/orders/subs/", {
        headers: getAuthHeader(),
        cancelToken: source.token
      });
      setSubs(data.data);
      return;
    };
    const getCharges = async () => {
      const { data } = await safeGymApi.get("/orders/charges/", {
        headers: getAuthHeader(),
        cancelToken: source.token
      });
      setCharges(data.data);
      return;
    };
    const getPaymentMethods = async () => {
      const { status, data } = await safeGymApi.get("/orders/pay-methods/", {
        headers: getAuthHeader(),
        cancelToken: source.token
      });
      if (status === 200) {
        setPaymentMethods(data.data);
      } else {
        alert("error");
      }
      return;
    };

    const getCurrentSubData = async () => {
      const { status, data } = await safeGymApi.get("/orders/subs/current/", {
        headers: getAuthHeader(),
        cancelToken: source.token
      });
      if (status === 200) {
        setCurrentSub(data);
      }
    };

    const getData = async () => {
      await getCharges();
      await getPaymentMethods();
      await getSubs();
      await getCurrentSubData();
      setLoading(false);
    };

    getData();

    return () => {
      source.cancel();
    };
  }, []);

  if (loading) {
    return <LoadingRoller />;
  }
  return (
    <FadeIn>
      <div>
        <EditCardModal
          show={showEditCardModal}
          handleClose={() => setShowEditCardModal(false)}
        />
        <DeleteCardModal
          show={showDeleteCardModal}
          handleClose={() => setShowDeleteCardModal(false)}
          data={selectedPaymentMethod}
        />
        <h2 className="mb-3 text-secondary">Administracion</h2>
        <Row>
          <Col lg={4}>
            <Card className="pay-methods-card">
              <Card.Header>Suscripcion Actual</Card.Header>
              <Card.Body className="text-center">
                <Image
                  style={{ height: "200px" }}
                  src={currentSub.plan.product.images[0]}
                  fluid
                />
                <ListGroup className="text-left mt-1">
                  {renderStatus(currentSub.status)}
                  <ListGroup.Item>
                    {currentSub.plan.product.name}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="mr-2">Siguiente pago:</span>
                    <span>
                      {currentSub.status === "active" ||
                      currentSub.status === "trialing"
                        ? dateFormatter(currentSub.current_period_end)
                        : ""}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {currentSub.default_payment_method !== null ? (
                      <React.Fragment>
                        <span className="mr-2">
                          <i className="mr-1 text-secondary">
                            <FontAwesomeIcon
                              icon={renderBrandIcon(
                                currentSub.default_payment_method.card.brand
                              )}
                            />
                          </i>
                          {currentSub.default_payment_method.card.brand}
                        </span>
                        <span>
                          ***{currentSub.default_payment_method.card.last4}
                        </span>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={8}>
            <Card>
              <Card.Header>Historial de Pagos</Card.Header>
              <Card.Body className="px-2 py-0">
                <BootstrapTable
                  keyField="id"
                  bordered={false}
                  hover
                  columns={chargesColumns}
                  data={charges}
                  noDataIndication="Aun no has realizado ningún pago."
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <Card className="my-4 pay-methods-card">
              <Card.Header>Métodos de pago</Card.Header>
              <ListGroup>
                {paymentMethods.map(item => {
                  var brand = item.card.brand;
                  brand = brand.charAt(0).toUpperCase() + brand.slice(1);

                  var icon = null;
                  if (brand === "Visa") {
                    icon = faCcVisa;
                  } else if (brand === "Mastercard") {
                    icon = faCcMastercard;
                  } else if (brand === "Amex") {
                    icon = faCcAmex;
                  } else {
                    icon = faCreditCard;
                  }

                  return (
                    <ListGroup.Item key={item.id}>
                      <div className="d-flex flex-row align-items-center text-secondary">
                        <i className="mr-2" style={{ fontSize: "20px" }}>
                          <FontAwesomeIcon icon={icon} />
                        </i>
                        <div className="mr-auto">
                          {brand} ****{item.card.last4}
                        </div>
                      </div>
                    </ListGroup.Item>
                  );
                })}
                <ListGroup.Item>
                  <div className="text-primary">
                    <i className="mr-1">
                      <FontAwesomeIcon icon={faPlus} />
                    </i>
                    <Link to="/billing/new-card">
                      <Button variant="link">
                        Agregar nuevo método de pago.
                      </Button>
                    </Link>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card>
            <Card>
              <Card.Header>Historial de suscripciones</Card.Header>
              <Card.Body className="p-0">
                <BootstrapTable
                  keyField="id"
                  columns={columns}
                  data={subs}
                  bordered={false}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </FadeIn>
  );
};

export default BillingDetails;
