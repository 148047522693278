import React from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";

const EditCardModal = ({ show, handleClose, data }) => {
  return (
    <Formik
      initialValues={{ name: "", line1: "", line2: "", city: "", zip: "" }}
      onSubmit={(values, { setSubmitting }) => {
        const postData = {
          address_city: values.city,
          address_line1: values.line1,
          address_line2: values.line2,
          address_zip: values.zip
        };
        setSubmitting(false);
      }}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>Editar metodo de pago</Modal.Header>
        <Modal.Body></Modal.Body>
      </Modal>
    </Formik>
  );
};

export default EditCardModal;
