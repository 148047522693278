import React from "react";
import { Formik } from "formik";
import { Modal, Button, Form, Col } from "react-bootstrap";

import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const ScheduleModal = ({
  show,
  setShow,
  gymData,
  setAlertText,
  setShowSuccessAlert
}) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Formik
      initialValues={{
        monday: gymData.schedule.monday,
        tuesday: gymData.schedule.tuesday,
        wednesday: gymData.schedule.wednesday,
        thursday: gymData.schedule.thursday,
        friday: gymData.schedule.friday,
        saturday: gymData.schedule.saturday,
        sunday: gymData.schedule.sunday
      }}
      onSubmit={(values, { setSubmitting }) => {
        safeGymApi
          .put(
            `/gym/schedule/${gymData.schedule.id}/`,
            {
              monday: values.monday,
              tuesday: values.tuesday,
              wednesday: values.wednesday,
              thursday: values.thursday,
              friday: values.friday,
              saturday: values.saturday,
              sunday: values.sunday
            },
            { headers: getAuthHeader() }
          )
          .then(response => {
            setAlertText(
              "El horario se actualizo correctamente. Recargar pagina para visualizar cambios."
            );
            setShowSuccessAlert(true);
            handleClose();
          });

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <Modal show={show} onHide={handleClose}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Editar horario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Row>
                <Col xs={3}>
                  <Form.Label>Lunes</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    placeholder="Ej: 8:00 AM - 10:00 PM"
                    name="monday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.monday}
                  />
                  <Form.Text className="text-danger">
                    {errors.monday && touched.monday && errors.monday}
                  </Form.Text>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={3}>
                  <Form.Label>Martes</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    placeholder="Ej: 8:00 AM - 10:00 PM"
                    name="tuesday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tuesday}
                  />
                  <Form.Text className="text-danger">
                    {errors.tuesday && touched.tuesday && errors.tuesday}
                  </Form.Text>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={3}>
                  <Form.Label>Miercoles</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    placeholder="Ej: 8:00 AM - 10:00 PM"
                    name="wednesday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.wednesday}
                  />
                  <Form.Text className="text-danger">
                    {errors.wednesday && touched.wednesday && errors.wednesday}
                  </Form.Text>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={3}>
                  <Form.Label>Jueves</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    placeholder="Ej: 8:00 AM - 10:00 PM"
                    name="thursday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.thursday}
                  />
                  <Form.Text className="text-danger">
                    {errors.thursday && touched.thursday && errors.thursday}
                  </Form.Text>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={3}>
                  <Form.Label>Viernes</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    placeholder="Ej: 8:00 AM - 10:00 PM"
                    name="friday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.friday}
                  />
                  <Form.Text className="text-danger">
                    {errors.friday && touched.friday && errors.friday}
                  </Form.Text>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={3}>
                  <Form.Label>Sabado</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    placeholder="Ej: 8:00 AM - 10:00 PM"
                    name="saturday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.saturday}
                  />
                  <Form.Text className="text-danger">
                    {errors.saturday && touched.saturday && errors.saturday}
                  </Form.Text>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={3}>
                  <Form.Label>Domingo</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    placeholder="Ej: 8:00 AM - 10:00 PM"
                    name="sunday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.sunday}
                  />
                  <Form.Text className="text-danger">
                    {errors.sunday && touched.sunday && errors.sunday}
                  </Form.Text>
                </Col>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Guardar Cambios"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default ScheduleModal;
