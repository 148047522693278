import React from "react";

import { Alert, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const SuccessAlert = ({ text, onHide }) => {
  return (
    <Alert variant="success">
      <div className="d-flex flex-row justify-content-between align-content-center">
        <span className="align-self-center">
          <strong className="mr-2">Exito.</strong>
          {text}
        </span>
        <Button className="text-secondary" variant="link" onClick={onHide}>
          <FontAwesomeIcon className="text-secondary" icon={faTimes} />
        </Button>
      </div>
    </Alert>
  );
};

export default SuccessAlert;
