import React, { useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Redirect } from "react-router-dom";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";

import { refreshAccessToken, logoutUser } from "../actions";
import SidebarNav from "./SidebarNav";
import Routes from "./Routes";
import TopNav from "./TopNav";
import LoginForm from "./auth/LoginForm";
import "../assets/styles/style.css";
import Onboarding from "./Onboarding";

const App = props => {
  const [openSidebar, setOpenSidebar] = useState(true);
  const [hideAlert, setHideAlert] = useState(false)
  const redirectToHome = () => {
    if (window.location.pathname === "/") {
      return <Redirect to="/home" />;
    }
  };

  const renderMemberLimitAlert = () => {
    if (!props.memberLimits) return;
    if (props.memberLimits.detail) {
      return <Alert variant="warning">{props.memberLimits.detail}</Alert>;
    }
  };

  if (Object.entries(props.tokens).length === 0) {
    return <LoginForm />;
  } else {
    return (
      <div className="wrapper">
        <BrowserRouter>
          {redirectToHome()}
          <SidebarNav
            activeClassName={openSidebar ? "" : "active"}
            setOpenSidebar={setOpenSidebar}
          />
          <div id="content" style={{ paddingBottom: "155px" }}>
            <Container fluid className="h-100 firstStep betaNotifStep lastStep">
              <Onboarding />
              <Row className="h-100">
                <Col className="h-100 pt-3">
                  <Alert className="d-md-none" variant="info">
                    <i className="mr-1">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </i>
                    Recomendamos entrar desde una tablet o computadora para una
                    mejor experiencia.
                  </Alert>
                  {/*<div onClick={() => setHideAlert(true)} hidden={hideAlert}>*/}
                  {/*  <Alert className="d-flex justify-content-between alert-primary">*/}
                  {/*    <div>*/}
                  {/*      <i className="mr-1 text-white">*/}
                  {/*        <FontAwesomeIcon icon={faInfoCircle}/>*/}
                  {/*      </i>*/}
                  {/*      <strong> Estado de CHIHUAHUA: </strong> La nueva ley de husos horarios aún no se propaga en internet, si necesitas ayuda en tus horarios no dudes en contactarnos. WhatsApp (662) 132 3750*/}
                  {/*    </div>*/}
                  {/*    <i className="ml-auto">*/}
                  {/*      <FontAwesomeIcon icon={faTimes}/>*/}
                  {/*    </i>*/}
                  {/*  </Alert>*/}
                  {/*</div>*/}

                  <TopNav toggleSidebar={() => setOpenSidebar(!openSidebar)} />
                  {renderMemberLimitAlert()}
                  <Routes />
                </Col>
              </Row>
            </Container>
          </div>
        </BrowserRouter>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return { tokens: state.tokens, memberLimits: state.user.memberLimits };
};

export default connect(mapStateToProps, {
  refreshAccessToken,
  logoutUser
})(App);
