import React, { useEffect, useState } from "react";
import { parse, format } from "date-fns";
import { Modal, Button } from "react-bootstrap";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import EllipsisLoader from "../misc/EllipsisLoader";
import { dateFormatter } from "../../helpers/cellFormaters";

const DeleteModal = ({
  show,
  handleClose,
  data,
  setHiddenRows,
  hiddenRows,
  showAlert,
  setAlertText
}) => {
  const [requestProcessing, setRequestProcessing] = useState(false);

  const parseDate = date => {
    return parse(date, "yyyy-MM-dd'T'HH:mm:ssXX", new Date());
  };

  const getSuccessText = () => {
    return `Se elimino correctamente el programa ${data.area.name}.`;
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  const handleEliminate = async () => {
    setRequestProcessing(true);
    const { status } = await safeGymApi.delete(`/programs/${data.id}/`, {
      headers: getAuthHeader()
    });
    if (status === 204) {
      setHiddenRows([...hiddenRows, data.id]);
      setAlertText(getSuccessText());
      showAlert();
      handleClose();
    } else {
      alert("Ocurrio un error al intentar eliminar esta programacion.");
    }
    setRequestProcessing(false);
  };

  if (data === null || data === undefined) {
    return <React.Fragment></React.Fragment>;
  }
  let start = parseDate(data.start_date)
  if (data.start_time !== null) {
      start = parse(data.start_time, "HH:mm:ss", start)
  }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="text-danger">
        Eliminar programación de {data.area.name}
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <span>ID: {data.id}</span>
          <span>{data.area.name}</span>
          <span>
            Hora de Entrada: {format(start, "h:mm aaa")}
          </span>
          <span>Duración de clase: {data.minutes_duration} minutos</span>
        </div>

        <div>
          <br />
          <small>
            Se eliminaran todos los bloques de {data.area.name} correspondientes
            a este programa que inicia el{" "}
            <strong>{dateFormatter(start)}</strong> y termina el{" "}
            <strong>{dateFormatter(data.end_date)}</strong> También se cancelan
            todas las reservaciones relacionadas con esta programación.
          </small>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button
          variant="danger"
          onClick={handleEliminate}
          disabled={requestProcessing}
        >
          {requestProcessing ? <EllipsisLoader /> : "Eliminar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
