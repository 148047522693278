import React, { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import { object, string } from "yup";

import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const PhoneModal = ({ show, onHide, selectedGym }) => {
  const [success, setSuccess] = useState(null);

  const renderAlert = () => {
    if (success === null) return;
    if (success) {
      return (
        <Alert variant="success">
          El numero de teléfono de tu gimnasio fue actualizada correctamente.
        </Alert>
      );
    } else {
      return (
        <Alert variant="danger">
          Ocurrió un error al intentar actualizar los datos.
        </Alert>
      );
    }
  };

  const schema = object({
    phone: string()
      .required("Campo requerido")
      .max(13, "Maximo 13 caracteres")
  });

  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        validationSchema={schema}
        initialValues={{ phone: selectedGym.phone }}
        onSubmit={(values, { setSubmitting }) => {
          safeGymApi
            .put(
              `/gym/${selectedGym.id}/`,
              { phone: values.phone },
              { headers: getAuthHeader() }
            )
            .then(result => {
              setSuccess(true);
            })
            .catch(err => setSuccess(false));
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header>Editar numero telefónico de tu Gimnasio</Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <Form.Group>
                <Form.Label>Numero de Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
                <ErrorMessage className="text-danger" name="phone" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onHide} variant="outline-secondary">
                Cerrar
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Guardar"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PhoneModal;
