import React from "react";
import Calendar from "tui-calendar";
import {
  format,
  startOfWeek,
  endOfWeek,
  parse,
  addMinutes,
  parseISO
} from "date-fns";
import { Card } from "react-bootstrap";

import TopBar from "./TopBar";
import NewAreaModal from "./NewAreaModal";
import SuccessAlert from "../misc/SuccessAlert";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

import "tui-calendar/dist/tui-calendar.css";
import "../../assets/styles/customCalendar.css";
import axios from "axios";

class AreaEventsCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showSuccessAlert: false,
      schedules: []
    };
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    this.startDatesCache = [];
  }

  setCalendarRef = element => {
    const daynames = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
    this.calendar = new Calendar(document.querySelector("#calendar"), {
      defaultView: "week",
      week: {
        daynames: daynames
      },
      month: {
        daynames: daynames
      },
      taskView: false,
      scheduleView: ["time"],
      usageStatistics: false,
      useDetailPopup: true,
      template: {
        time: function(schedule) {
          return `<span class="rounded text-light" style="background-color:${
            schedule.bgColor
          }; width: 100%;">&nbsp;${format(
            schedule.start.getTime(),
            "HH:mm"
          )}  ${schedule.title}</span>`;
        }
      }
    });
    this.forceUpdate();
  };

  showModal = () => {
    this.setState({
      showModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  componentWillUnmount = () => {
    this.source.cancel();
  };

  getSchedules = async (startDate, endDate) => {
    const dateFormat = "yyyy-MM-dd'T'HH:mm:ssXX";
    startDate = format(startDate, dateFormat);
    endDate = format(endDate, dateFormat);

    if (!this.startDatesCache.includes(startDate)) {
      const response = await safeGymApi.get(
        `/programs/timeslots/?start=${startDate}&end=${endDate}&areas=1`,
        { headers: getAuthHeader(), cancelToken: this.source.token }
      );

      const newData = response.data.map(item => {
        let result = parseISO(item.start_date);
        if (item.start_time !== null) {
          result = parse(item.start_time, "HH:mm:ss", result)
        }
        const start = format(result, dateFormat);
        const end = format(
          addMinutes(result, item.area_program.minutes_duration),
          dateFormat
        );
        return {
          id: item.id,
          title: item.area_program.area.name,
          category: "time",
          start: start,
          end: end,
          isReadOnly: true,
          bgColor: item.area_program.area.hex_color,
          dragBgcolor: "transparent",
          borderColor: "transparent"
        };
      });
      this.setState({
        schedules: newData
      });
      this.calendar.createSchedules(this.state.schedules);
      this.startDatesCache.push(startDate);
    }
  };

  addNewSchedules = newSchedules => {
    const dateFormat = "yyyy-MM-dd'T'HH:mm:ssXX";
    const scheduleItems = newSchedules.map(item => {
      return {
        id: item.id,
        calendarId: "1",
        title: item.area_program.area.name,
        category: "time",
        dueDateClass: "",
        start: item.start_date,
        end: format(
          addMinutes(
            parseISO(item.start_date),
            item.area_program.minutes_duration
          ),
          dateFormat
        ),
        isReadOnly: true,
        color: item.area_program.area.hex_color,
        dragBgcolor: "transparent",
        borderColor: "transparent",
        bgColor: item.area_program.area.hex_color
      };
    });
    this.setState({
      schedules: [...this.state.schedules, scheduleItems]
    });
    this.calendar.createSchedules(scheduleItems);
  };

  showAlert = () => {
    this.setState({
      showSuccessAlert: true
    });
  };

  renderAlert = () => {
    if (this.state.showSuccessAlert) {
      return (
        <SuccessAlert
          text="El area se ha agregado con exito y esta disponible para reservaciones."
          onHide={() => this.setState({ showSuccessAlert: false })}
        />
      );
    }
    return <React.Fragment></React.Fragment>;
  };

  componentDidMount = () => {
    const now = new Date();
    this.getSchedules(startOfWeek(now), endOfWeek(now));
  };

  render() {
    return (
      <div className="h-100">
        {this.renderAlert()}
        <NewAreaModal
          show={this.state.showModal}
          onHide={this.handleCloseModal}
          addNewSchedules={this.addNewSchedules}
          showAlert={this.showAlert}
        />
        <Card>
          <Card.Header>
            <h4 className="text-primary">Gimnasio</h4>
            Administra las areas dentro de tu gimnasio.
          </Card.Header>
          <Card.Body className={"h-100"}>
            <TopBar
              calendar={this.calendar}
              showModal={this.showModal}
              getSchedules={this.getSchedules}
            />
            <div
              id="calendar"
              style={{
                height: "700px"
              }}
              ref={this.setCalendarRef}
            ></div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default AreaEventsCalendar;
