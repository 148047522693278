import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import CreatePlanModal from "./CreatePlan";
import safegym from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import EditPlanModal from "./EditPlan";
import DeletePlanModal from "./DeletePlan";

const Memberships = () => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    safegym
      .get("/memberships/plans/", { headers: getAuthHeader() })
      .then(resp => {
        setPlans(resp.data);
      })
      .catch(() => alert("Error"));
  }, []);

  const actionsFormatter = (cell, row) => {
    return (
      <div className="d-inline-flex">
        <Button
          onClick={() => {
            setSelectedPlan(row);
            setShowEditModal(true);
          }}
          size="sm"
          variant="info"
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setSelectedPlan(row);
            setShowDeleteModal(true);
          }}
          className="ml-1"
          size="sm"
          variant="danger"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </div>
    );
  };

  const columns = [
    {
      text: "Titulo",
      dataField: "title"
    },
    {
      text: "Vigencia",
      dataField: "days_duration",
      formatter: cell => `${cell} días`
    },
    {
      text: "Créditos",
      dataField: "credits_count",
      formatter: cell => `${cell === 0 ? "" : cell}`
    },
    {
      text: "Precio",
      dataField: "price"
    },
    {
      text: "#veces vendido",
      dataField: "times_sold"
    },
    {
      text: "",
      dataField: "id",
      formatter: actionsFormatter
    }
  ];

  return (
    <React.Fragment>
      <CreatePlanModal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
      />
      <EditPlanModal
        show={showEditModal}
        onHide={() => {
          setSelectedPlan(null);
          setShowEditModal(false);
        }}
        plan={selectedPlan}
      />
      <DeletePlanModal
        show={showDeleteModal}
        onHide={() => {
          setSelectedPlan(null);
          setShowDeleteModal(false);
        }}
        plan={selectedPlan}
      />
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h4 className="text-primary">Membresías</h4>
              Crea planes que podrás asignar a tus clientes
            </Card.Header>
            <Card.Body>
              <Button className="mb-3" onClick={() => setShowCreateModal(true)}>
                {" "}
                + Crear nueva membresía
              </Button>
              <BootstrapTable
                keyField="id"
                data={plans}
                columns={columns}
                noDataIndication="Crea tu primer membresía para llevar un control de ventas, clientes vigentes y vencidos."
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Memberships;
