import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import safeGymApi from "../../../apis/safegym";
import { getAuthHeader } from "../../../helpers/tokens";
import EllipsisLoader from "../../misc/EllipsisLoader";

const DeleteCardModal = ({ show, handleClose, data }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleDelete = async () => {
    setIsSubmitting(true);
    const { status } = await safeGymApi.post(
      "/orders/pay-methods/delete/",
      { id: data.id },
      { headers: getAuthHeader() }
    );
    if (status === 204) {
      handleClose();
      alert("Metodo eliminado correctamente de tu cuenta.");
    } else {
      setShowError(true);
    }
    setIsSubmitting(false);
  };

  if (!data) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className={"text-danger"}>
        Eliminar Metodo de Pago
      </Modal.Header>
      <Modal.Body>
        <p className="text-danger" hidden={!showError}>
          Ocurrió un error al intentar eliminar este metodo de pago.
        </p>
        {`${data.card.brand} ****${data.card.last4}`}
        <br />
        Estas seguro que deseas eliminar este metodo de pago?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant={"secondary"}>
          Cancelar
        </Button>
        <Button
          onClick={handleDelete}
          variant={"danger"}
          disabled={isSubmitting}
        >
          {isSubmitting ? <EllipsisLoader /> : "Eliminar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCardModal;
