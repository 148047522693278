import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";
import { fetchClassPrograms } from "../../actions";
import {
  nameFormatter,
  dateFormatter,
  endDateFormatter,
  actionsFormatter,
  scheduleFormatter,
  durationFormatter,
  dateFormatterMilli
} from "../../helpers/cellFormaters";
import { Link } from "react-router-dom";
import SuccessAlert from "../misc/SuccessAlert";
import { sortCaret } from "../../helpers/sortCaret";

const ClassesTable = props => {
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [hiddenRows, setHiddenRows] = useState([]);

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = e => {
    const programId = parseInt(e.target.value);
    const program = props.programs.find(el => el.id === programId);
    setSelectedProgram(program);
    setShowEditModal(true);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = e => {
    const programId = parseInt(e.target.value);
    const program = props.programs.find(el => el.id === programId);
    setSelectedProgram(program);
    setShowDeleteModal(true);
  };

  const defaultSorted = [
    {
      dataField: "area",
      order: "asc"
    }
  ];

  const hideAlert = () => setShowSuccessAlert(false);
  const showAlert = () => setShowSuccessAlert(true);
  const renderAlert = () => {
    if (showSuccessAlert) {
      return <SuccessAlert onHide={hideAlert} text={alertText} />;
    }
    return <React.Fragment />;
  };

  const columns = [
    {
      text: "Clase",
      dataField: "area",
      sort: true,
      sortValue: (cell, row) => cell.name,
      sortCaret: sortCaret,
      formatter: nameFormatter,
      headerStyle: { cursor: "pointer" }
    },
    {
      text: "Fecha de Inicio",
      dataField: "start_date",
      sort: true,
      sortCaret: sortCaret,
      formatter: dateFormatter,
      headerStyle: { cursor: "pointer" }
    },
    {
      text: "Final",
      dataField: "end_date",
      sort: true,
      sortCaret: sortCaret,
      formatter: endDateFormatter,
      headerStyle: { cursor: "pointer" }
    },
    {
      text: "Duración de Clase",
      dataField: "minutes_duration",
      sort: true,
      sortCaret: sortCaret,
      formatter: durationFormatter,
      headerStyle: { cursor: "pointer" }
    },
    {
      text: "Limite de Personas",
      dataField: "people_limit",
      sort: true
    },
    {
      text: "Dias",
      dataField: "id",
      formatter: scheduleFormatter,
      formatExtraData: {
        programs: props.programs
      }
    },
    {
      text: "Creado el",
      dataField: "created_on",
      formatter: dateFormatterMilli,
      sort: true,
      sortCaret: sortCaret,
      headerStyle: { cursor: "pointer" }
    },
    {
      text: "Acciones",
      dataField: "is_active",
      formatter: actionsFormatter,
      formatExtraData: {
        programs: props.programs,
        handleShow: handleShowEditModal,
        handleShowDelete: handleShowDeleteModal
      }
    }
  ];

  useEffect(() => {
    if (!loading) {
      props.fetchClassPrograms();
      setLoading(true);
    }
  }, [props, loading]);

  return (
    <React.Fragment>
      {renderAlert()}
      <EditModal
        show={showEditModal}
        handleClose={handleCloseEditModal}
        data={selectedProgram}
        showAlert={showAlert}
        setAlertText={setAlertText}
      />
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        hiddenRows={hiddenRows}
        setHiddenRows={setHiddenRows}
        data={selectedProgram}
        showAlert={showAlert}
        setAlertText={setAlertText}
      />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center flex-row">
            <h4 className="text-primary mb-0">Programaciones</h4>
            <Link to="/classes">
              <Button>
                <i>
                  <FontAwesomeIcon icon={faCalendarDay} />
                </i>{" "}
                Ir a Calendario
              </Button>
            </Link>
          </div>
          <small className="text-secondary">
            Mostrando horarios vigentes y de expiración menor a 10 días.
          </small>
        </Card.Header>
        <Card.Body>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={props.programs}
            columns={columns}
            defaultSorted={defaultSorted}
            hiddenRows={hiddenRows}
            pagination={paginationFactory()}
            wrapperClasses="table-responsive"
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return { programs: state.classPrograms };
};

export default connect(mapStateToProps, { fetchClassPrograms })(ClassesTable);
