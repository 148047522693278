import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import img0 from "../assets/img/onboarding/0.png";
import img1 from "../assets/img/onboarding/1.png";
import img2 from "../assets/img/onboarding/2.png";
import img3 from "../assets/img/onboarding/3.png";
import img4 from "../assets/img/onboarding/4.png";
import img5 from "../assets/img/onboarding/5.png";
import img6 from "../assets/img/onboarding/6.png";
import img7 from "../assets/img/onboarding/7.png";
import check from "../assets/img/onboarding/check.png";
import { useSelector } from "react-redux";
import safeGymApi from "../apis/safegym";
import { getAuthHeader } from "../helpers/tokens";

const Onboarding = () => {
  const history = useHistory();
  let location = useLocation();
  const userState = useSelector(state => state.user);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([
    {
      target: ".betaNotifStep",
      content: (
        <div>
          <p>
            <strong>Bienvenido al Tour</strong>
          </p>
          <p>
            Te daremos un breve recorrido para que conozcas las funcionalidades
            básicas del panel de control Safe Gym
          </p>
          <p>Tomará solo 1 minuto</p>
          <Image src={img0} fluid />
        </div>
      ),
      placement: "center",
      placementBeacon: "left"
    },
    {
      target: ".secondStep",
      content: (
        <div>
          <p>
            <strong>Bloques Horarios</strong>
          </p>
          <p>Crea aquí los bloques horarios con cupo limitado.</p>
          <p>
            Estos se mostraran en la App Móvil para ser reservados por tus
            clientes.
          </p>
          <Image src={img1} fluid />
        </div>
      ),

      placement: "right-end",
      plcementBeacon: "right"
    },
    {
      target: ".step4",
      content: (
        <div>
          <p>
            <strong>Crear un bloque horario</strong>
          </p>
          <p>Creemos uno para la sección de gimnasio como ejemplo</p>
          <Image src={img2} fluid />
        </div>
      ),
      placement: "right-end",
      placementBeacon: "right"
    },

    {
      target: ".step5",
      content: (
        <div>
          <p>
            <strong>Crear un bloque horario</strong>
          </p>
          <p>
            Define el nombre que se mostrara en la app ej: gimnasio, area de
            cardio, spinning, etc
          </p>
          <Image src={img3} fluid />
        </div>
      ),
      placement: "right-end",
      placementBeacon: "right"
    },

    {
      target: ".selectSeccionStep",
      content: (
        <div>
          <p>
            <strong>Crear un bloque horario</strong>
          </p>
          <p>Escoge la sección donde aparecerá dentro de la app</p>
          <Image src={img2} fluid />
        </div>
      ),
      placement: "right-end",
      placementBeacon: "right"
    },
    {
      target: ".step6",
      content: (
        <div>
          <p>
            <strong>Crear un bloque horario</strong>
          </p>
          <p>
            2- Define el limite de personas que podrán reservar este bloque
            horario
          </p>
          <Image src={img4} fluid />
        </div>
      ),
      placement: "bottom",
      placementBeacon: "right"
    },

    {
      target: ".step7",
      content: (
        <div>
          <p>
            <strong>Crear un bloque horario</strong>
          </p>
          <p>3- Define el horario y fecha</p>
          <Image src={img5} fluid />
        </div>
      ),
      placement: "right-end",
      placementBeacon: "right"
    },
    {
      target: ".notifStep",
      content: (
        <div>
          <p>
            <strong>Avisos</strong>
          </p>
          <p>
            Comunica fácilmente cualquier aviso o promoción a todos tus clientes
          </p>
          <p>Se publicaran en la app</p>
          <Image src={img6} fluid />
        </div>
      ),
      placement: "right-end",
      placementBeacon: "right"
    },
    {
      target: ".membersStep",
      content: (
        <div>
          <p>
            <strong>Clientes / Usuarios</strong>
          </p>
          <p>
            Por seguridad tu decides quien si y quien no puede reservar una cita
          </p>
        </div>
      ),

      placement: "right-end",
      placementBeacon: "right"
    },
    {
      target: ".membersStep2",
      content: (
        <div>
          <p>
            <strong>Verificación de clientes</strong>
          </p>
          <p>
            Los clientes se registran desde la app con sus datos, se mostraran
            aquí para que los verifiques, puedes agregar una fecha de
            vencimiento de su membresía
            <Image src={img7} fluid />
          </p>
        </div>
      ),

      placement: "bottom-start",
      placementBeacon: "bottom"
    },
    {
      target: ".toolboxStep",
      content: (
        <div>
          <p>
            <strong>Personalización</strong>
          </p>
          <p>Control de flujo de personas.</p>
        </div>
      ),
      placement: "right-end",
      placementBeacon: "right"
    },
    {
      target: ".toolboxStep2",
      content: (
        <div>
          <p>
            <strong>Limite de reservaciones</strong>
          </p>
          <p>
            Limita la cantidad de reservaciones que un usuario puede tener en la
            app al dia y a la semana Manteniendo su flexibilidad
          </p>
        </div>
      ),
      placement: "left-start",
      placementBeacon: "left"
    },
    {
      target: ".prevRsvpDays",
      content: (
        <div>
          <p>
            <strong>Tiempo para reservar</strong>
          </p>
          <p>
            Define con cuantos días previos al bloque horario pueden reservarlo
          </p>
        </div>
      ),
      placement: "left-start",
      placementBeacon: "left"
    },
    {
      target: ".membershipRequired",
      content: (
        <div>
          <p>
            <strong>Bloqueo de reservaciones</strong>
          </p>
          <p>
            Si la membresía del cliente venció no podrá reservar hasta renovarla
          </p>
        </div>
      ),
      placement: "left-start",
      placementBeacon: "left"
    },
    {
      target: ".creditsSystemCheckboxStep",
      content: (
        <div>
          <p>
            <strong>Sistema de Créditos</strong>
          </p>
          <p>
            Si activas el sistema de créditos, los clientes usaran 1 crédito por
            cada reservación. Útil para gimnasios donde los clientes compran un
            número determinado de clases
          </p>
        </div>
      ),
      placement: "left-start",
      placementBeacon: "left"
    },
    {
      target: ".payStep",
      content: (
        <div>
          <p>
            <strong>Activa tu cuenta</strong>
          </p>
          <p>
            Activa para desbloquear todas las funcionalidades y empezar a
            compartir la app con tus clientes.
          </p>

          <Image src={check} fluid />
        </div>
      ),
      placement: "right-end",
      placementBeacon: "right"
    },
    {
      target: ".lastStep",
      content: (
        <div>
          <p>
            <strong>¡Gracias!</strong>
          </p>
          <p>
            En Safe Gym queremos brindar el mejor servicio al cliente no dudes
            en comunicarte con nosotros en cualquier momento para dudas o
            sugerencias
          </p>
          <ul className="text-danger">
            <li>Chat en linea</li>
            <li>Whatsapp: 662 132 3750</li>
            <li>Llámanos 662 132 3750</li>
          </ul>
        </div>
      ),
      placement: "center",
      placementBeacon: "left"
    }
  ]);

  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    switch (index) {
      case 1:
        safeGymApi.patch(
          `/user/onboarding/${userState.preferences.id}/`,
          { show_onboarding: false },
          { headers: getAuthHeader() }
        );
        history.push("/classes");
        break;

      case 7:
        console.log("Hasta aca alv");
        try {
          document.getElementById("addAreaCloseBtn").click();
        } catch (e) {}
        history.push("/notifications");
        break;
      case 8:
        history.push("/members");
        break;
      case 10:
        history.push("/toolbox");
        break;
      case 15:
        if (location.pathname !== "/billing") {
          history.push("/billing");
        }

        break;
      default:
        break;
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (index === 2) {
        document.getElementById("crearHorarioBtn").click();
        setTimeout(() => {
          setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        }, 280);
      } else {
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      }
    }
  };

  useEffect(() => {
    if (Object.entries(userState).length > 0) {
      setRun(userState.preferences.show_onboarding);
    }
  }, [userState]);

  const joyrideLocale = {
    back: "Atrás",
    close: "Cerrar",
    last: "Finalizar",
    next: "Siguiente",
    skip: "Saltar"
  };

  return (
    <div>
      <Joyride
        run={run}
        steps={steps}
        stepIndex={stepIndex}
        continuous={true}
        locale={joyrideLocale}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 1500
          }
        }}
      />
    </div>
  );
};

export default Onboarding;
