import React, { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Formik } from "formik";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const AddressEditModal = ({ show, onHide, selectedGym }) => {
  const { address } = selectedGym;
  const [success, setSuccess] = useState(null);

  const renderAlert = () => {
    if (success === null) return;
    if (success) {
      return (
        <Alert variant="success">
          La dirección de tu gimnasio fue actualizada correctamente.
        </Alert>
      );
    } else {
      return (
        <Alert variant="danger">
          Ocurrió un error al intentar actualizar los datos.
        </Alert>
      );
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        initialValues={{
          street: address.street,
          number: address.number,
          postalCode: address.postal_code,
          state: address.string_address.state,
          city: address.string_address.city
        }}
        onSubmit={(values, { setSubmitting }) => {
          const postData = {
            address: {
              street: values.street,
              number: values.number,
              postal_code: values.postalCode,
              string_address: {
                state: values.state,
                city: values.city
              }
            }
          };
          safeGymApi
            .put(`/gym/${selectedGym.id}/`, postData, {
              headers: getAuthHeader()
            })
            .then(result => {
              setSuccess(true);
            })
            .catch(err => {
              setSuccess(false);
            });
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          errors,
          touched,
          isSubmitting
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <h3>Editar Dirección del Gimnasio</h3>
            </Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <Row>
                <Col md={9}>
                  <Form.Group>
                    <Form.Label>Calle</Form.Label>
                    <Form.Control
                      type="text"
                      name="street"
                      value={values.street}
                      onChange={handleChange}
                    />
                    <Form.Text>
                      {errors.street && touched.street && errors.street}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Numero</Form.Label>
                    <Form.Control
                      type="text"
                      name="number"
                      value={values.number}
                      onChange={handleChange}
                    />
                    <Form.Text>
                      {errors.number && touched.number && errors.number}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Ciudad</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                    />
                    <Form.Text>
                      {errors.city && touched.city && errors.city}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Estado</Form.Label>
                    <Form.Control
                      type="text"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                    />
                    <Form.Text>
                      {errors.state && touched.state && errors.state}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Codigo Postal</Form.Label>
                    <Form.Control
                      type="text"
                      name="postalCode"
                      value={values.postalCode}
                      onChange={handleChange}
                    />
                    <Form.Text>
                      {errors.postalCode &&
                        touched.postalCode &&
                        errors.postalCode}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onHide} variant="outline-secondary">
                Cerrar
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Guardar"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddressEditModal;
