import React from "react";
import { Form, Col, Row } from "react-bootstrap";
const PlainTextFormField = ({ label, value }) => {
  return (
    <Form.Group as={Row}>
      <Form.Label column sm="5">
        {label}
      </Form.Label>
      <Col>
        <Form.Control plaintext readOnly defaultValue={value} />
      </Col>
    </Form.Group>
  );
};

export default PlainTextFormField;
