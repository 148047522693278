import React, { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  Elements
} from "@stripe/react-stripe-js";
import { Button, Row, Col, Card, Alert } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { loadStripe } from "@stripe/stripe-js";
import { withRouter } from "react-router-dom";

import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import "../../assets/styles/cardSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronLeft,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import FadeIn from "react-fade-in";
import Select from "react-select";
import { countries } from "../../data/countries";

let key;
key =
  process.env.NODE_ENV === "production"
    ? "pk_live_51H4XFRFNqcKYLzi9WrkhjMWsruWWbJeb8Cfedza4rPhMBHtJBKqt4L7XieTSsPwYjkjeaqg8dKiCZgpLfi0eltHm007q6LXt1i"
    : "pk_test_51H4XFRFNqcKYLzi9dzfEDlk8mCRVVObXTKMDpiTwcTBWlgvP8VYgD2J13yrAdC0OqIOT6fiCdhJwTL7t1TdQ36rF006xU2ub5a";
const stripePromise = loadStripe(key, { locale: "es" });

const CardSetupForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState(false);
  const [countryCode, setCountryCode] = useState(null);

  const showAlert = () => {
    if (showErrorAlert) {
      return (
        <Alert variant="danger">
          <FontAwesomeIcon icon={faExclamationTriangle} /> {errorMessage}
        </Alert>
      );
    }
  };
  if (success) {
    return (
      <Alert variant="success">
        <FontAwesomeIcon icon={faCheck} /> Se agrego el método de pago
        correctamente.
      </Alert>
    );
  }
  return (
    <Row className="mt-5">
      <Col lg={3}></Col>
      <Col lg={6}>
        {showAlert()}
        <FadeIn>
          <Formik
            initialValues={{
              name: "",
              city: "",
              state: "",
              country: countryCode,
              line1: "",
              line2: "",
              postalCode: "",
              titleError: ""
            }}
            validate={values => {
              const errors = {};
              if (!stripe || !elements || !props.custSecret) {
                errors.titleError =
                  "Ocurrió un error. Por favor recargue la pagina.";
              }

              if (!values.name) {
                errors.name = "Campo requerido";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const result = await stripe.confirmCardSetup(props.custSecret, {
                payment_method: {
                  card: elements.getElement(CardElement),
                  billing_details: {
                    name: values.name,
                    address: {
                      city: values.city,
                      country: values.country,
                      line1: values.line1,
                      line2: values.line2,
                      postal_code: values.postalCode,
                      state: values.state
                    }
                  }
                }
              });
              if (result.error) {
                setErrorMessage(result.error.message);
                setShowErrorAlert(true);
              } else {
                safeGymApi.post(
                  "/orders/subscription-payment-method/",
                  {
                    payment_method: result.setupIntent.payment_method
                  },
                  {
                    headers: getAuthHeader()
                  }
                );
                setSuccess(true);
              }
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Card
                  style={{ boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1)" }}
                >
                  <Card.Header>Agregar método de pago</Card.Header>
                  <Card.Body>
                    <ErrorMessage
                      name="titleError"
                      component="div"
                      className="text-danger mb-3"
                    />
                    <Field
                      className="StripeElement"
                      type="text"
                      name="name"
                      placeholder="Nombre en la tarjeta"
                    />
                    <ErrorMessage name="name" component="div" />
                    <Field
                      className="StripeElement"
                      type="text"
                      name="line1"
                      placeholder="Direccion - Linea 1"
                    />
                    <Field
                      className="StripeElement"
                      type="text"
                      name="line2"
                      placeholder="Direccion - Linea 2 (Opcional)"
                    />
                    <Field
                      className="StripeElement"
                      type="text"
                      name="postalCode"
                      placeholder="Codigo Postal"
                    />
                    <Field
                      className="StripeElement"
                      type="text"
                      name="city"
                      placeholder="Ciudad"
                    />
                    <Field
                      className="StripeElement"
                      type="text"
                      name="state"
                      placeholder="Estado"
                    />
                    <Select
                      options={countries}
                      placeholder="País"
                      onChange={({ value }) => setCountryCode(value)}
                    />

                    <div className="mt-3">
                      <CardElement />
                    </div>
                    <div className="d-flex flex-row">
                      <Button
                        className="mr-auto mt-4"
                        disabled={isSubmitting}
                        variant="outline-secondary"
                        size="sm"
                        onClick={props.history.goBack}
                      >
                        <i className="mr-2">
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </i>
                        Atras
                      </Button>
                      <Button
                        disabled={isSubmitting}
                        variant="success"
                        size="sm"
                        type="submit"
                        className="mt-4"
                      >
                        Guardar tarjeta
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Form>
            )}
          </Formik>
        </FadeIn>
      </Col>
      <Col lg={3}></Col>
    </Row>
  );
};

const NewCard2 = ({ nextStep, toSelectPaymentMethod, history }) => {
  const [custSecret, setCustSecret] = useState(null);

  useEffect(() => {
    const getCustSecret = async () => {
      const { status, data } = await safeGymApi.get("/orders/intent/", {
        headers: getAuthHeader()
      });
      if (status === 200) {
        setCustSecret(data.client_secret);
      } else {
        alert("Ocurrió un error. Por favor recargue la pagina.");
      }
    };
    getCustSecret();
  }, []);

  return (
    <React.Fragment>
      <Elements stripe={stripePromise}>
        <CardSetupForm
          custSecret={custSecret}
          nextStep={nextStep}
          toSelectPaymentMethod={toSelectPaymentMethod}
          history={history}
        />
      </Elements>
    </React.Fragment>
  );
};

export default withRouter(NewCard2);
