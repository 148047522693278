import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { timezoneNames } from "../../data/timezones";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

const TimezoneSelectModal = ({ show, handleClose, selectedGymId }) => {
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    const { status } = await safeGymApi.put(
      `/gym/${selectedGymId}/`,
      { timezone: value },
      { headers: getAuthHeader() }
    );

    if (status === 200) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }

    setSubmitting(false);
  };

  const renderAlert = () => {
    if (success === null) return;
    if (success) {
      return (
        <Alert variant="success">
          La zona horaria de tu gimnasio se actualizo correctamente a{" "}
          <strong>{value}</strong>
        </Alert>
      );
    } else {
      return (
        <Alert variant="danger">
          Ocurrió un error al intentar actualizar los datos.
        </Alert>
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header>Editar Zona Horaria</Modal.Header>
        <Modal.Body>
          {renderAlert()}
          <p className="text-danger">
            <small>
              Advertencia: Asegurate que la zona horaria corresponda a la misma
              de tu gimnasio. Cambiarla puede afectar los horarios de tus clases
              y areas.
            </small>
          </p>
          <Form.Group>
            <Form.Label>Seleccionar Zona Horaria</Form.Label>
            <Select
              options={timezoneNames}
              placeholder="Seleccionar"
              onChange={i => {
                console.log(i);
                setValue(i.value);
                setSuccess(null);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button type="submit" disabled={submitting}>
            {submitting ? <EllipsisLoader /> : "Actualizar"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TimezoneSelectModal;
