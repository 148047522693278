import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Formik } from "formik";

import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";
import EllipsisLoader from "../misc/EllipsisLoader";

const CreateNotifModal = ({ show, handleClose, gym, data, setData }) => {
  const [isPushNotification, setIsPushNotification] = useState(true);

  return (
    <Formik
      initialValues={{ title: "", content: "" }}
      validate={values => {
        const errors = {};
        if (!values.title) {
          errors.title = "Campo requerido";
        }
        if (!values.content) {
          errors.content = "Campo requerido";
        }
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const response = await safeGymApi.post(
          "/notifications/",
          {
            gym: gym.data.id,
            title: values.title,
            content: values.content,
            is_push_notification: isPushNotification
          },
          { headers: getAuthHeader() }
        );
        if (response.status === 201) {
          setData([...data, response.data]);
          handleClose();
          resetForm();
        }
        setSubmitting(false);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        touched,
        isValid,
        errors
      }) => (
        <Modal show={show} onHide={handleClose}>
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Crear Aviso</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="pushNotifTooltip">
                    Si lo activas, los usuarios recibirán una notificación a su
                    celular de este aviso.{" "}
                  </Tooltip>
                }
              >
                <Form.Group>
                  <Form.Check
                    defaultChecked={true}
                    type="checkbox"
                    label="Notificación Push"
                    onChange={val => {
                      setIsPushNotification(val.target.checked);
                    }}
                  />
                </Form.Group>
              </OverlayTrigger>
              <Form.Group>
                <Form.Label>Titulo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Titulo"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  isValid={touched.title && !errors.title}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Contenido</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Contenido"
                  name="content"
                  value={values.content}
                  onChange={handleChange}
                  isValid={touched.content && !errors.content}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Crear"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

const mapStateToProps = state => {
  return { gym: state.selectedGym };
};

export default connect(mapStateToProps)(CreateNotifModal);
