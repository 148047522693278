import React, {useEffect, useState} from "react";
import {Modal, Button} from "react-bootstrap";
import safeGymApi from "../../apis/safegym";
import {getAuthHeader} from "../../helpers/tokens";
import LoadingRoller from "../misc/LoadingRoller";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faTrashAlt,
  faUserCheck,
  faUserTimes
} from "@fortawesome/free-solid-svg-icons";
import {parseISO, format, parse} from "date-fns";
import {sortCaret} from "../../helpers/sortCaret";
import safegym from "../../apis/safegym";

const PeopleListModal = ({
                           selectedTimeSlot,
                           show,
                           handleClose,
                           setSelectedRsvp,
                           handleDeleteRsvp
                         }) => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  const MyExportCSV = props => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <Button size="sm" variant="outline-success" onClick={handleClick}>
        <i className="mr-1">
          <FontAwesomeIcon icon={faFileExcel}/>
        </i>
        Descargar para Excel
      </Button>
    );
  };

  const updateRsvpStatus = row => {
    let status = null;
    if (row.assistance === null) {
      status = true;
    } else if (row.assistance === false) {
      status = null;
    } else {
      status = false;
    }
    safegym
      .patch(
        `/programs/rsvp/${row.id}/assistance/`,
        {
          assistance: status
        },
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        let newData = userList.map(u =>
          u.id === row.id ? {...u, assistance: status} : u
        );
        setUserList(newData);
      })
      .catch(err => alert("Error"));
  };

  const assistanceFormatter = (cell, row) => {
    if (row.isStringRsvp) {
      return <React.Fragment/>;
    }
    if (cell === null)
      return (
        <Button
          onClick={() => updateRsvpStatus(row)}
          variant="outline-secondary"
          className="py-0 px-1"
          style={{fontSize: "12px"}}
        >
          N/A
        </Button>
      );
    return cell ? (
      <Button
        onClick={() => updateRsvpStatus(row)}
        variant="outline-success"
        className="py-0 px-1"
        style={{fontSize: "12px"}}
      >
        <FontAwesomeIcon icon={faUserCheck}/>
      </Button>
    ) : (
      <Button
        onClick={() => updateRsvpStatus(row)}
        variant="outline-danger"
        s
        className="py-0 px-1"
        style={{fontSize: "12px"}}
      >
        <FontAwesomeIcon icon={faUserTimes}/>
      </Button>
    );
  };

  const deleteRsvpFormatter = (cell, row) => {
    if (!cell) return "";
    return (
      <button
        onClick={() => {
          setSelectedRsvp(row);
          handleDeleteRsvp();
        }}
        style={{border: "none", background: "white"}}
      >
        <span className="text-danger">
          <FontAwesomeIcon icon={faTrashAlt}/>
        </span>
      </button>
    );
  };

  const rowStyle = {
    fontSize: "12px"
  };

  const columnStyle = {
    fontSize: "14px",
    padding: "5px"
  };

  const headerStyle = {
    fontSize: "14px",
    padding: "5px"
  };

  useEffect(() => {
    const getList = async () => {
      if (!selectedTimeSlot) return;
      setLoading(true);
      if (!show) {
        return;
      }
      const {status, data} = await safeGymApi.get(
        `/programs/rsvp/users2/?id=${selectedTimeSlot.id}`,
        {
          headers: getAuthHeader()
        }
      );
      if (status === 200) {
        const users = data.map(item => {
          // Handle string users, which dont have nested info
          if (!item.user) {
            item["user"] = {
              external_gym_id: "",
              first_name: item.first_name,
              email: item.email,
              phone: item.phone
            };
            item.isStringRsvp = true;
            item.assistance = null;
          } else {
            item.isStringRsvp = false;
          }
          return item;
        });
        setUserList(users);
      } else {
        alert("Error");
      }
      setLoading(false);
    };

    getList();
    return () => {
      setUserList([]);
    };
  }, [selectedTimeSlot, show]);

  const columns = [
    {
      text: "ID Miembro",
      dataField: "user.external_gym_id",
      style: columnStyle,
      headerStyle: headerStyle,
      sort: true,
      sortCaret: sortCaret
    },
    {
      text: "Nombre",
      dataField: "user.first_name",
      style: columnStyle,
      headerStyle: headerStyle,
      sort: true,
      sortCaret: sortCaret
    },
    {
      text: "E-mail",
      dataField: "user.email",
      style: columnStyle,
      headerStyle: headerStyle,
      sort: true,
      sortCaret: sortCaret
    },
    {
      text: "Teléfono",
      dataField: "user.phone",
      style: columnStyle,
      headerStyle: headerStyle
    },
    {
      text: "Asistencia",
      dataField: "assistance",
      formatter: assistanceFormatter,
      sort: true,
      sortCaret: sortCaret,
      style: columnStyle,
      headerStyle: headerStyle,
      csvFormatter: cell => (cell === null ? "N/A" : cell ? "Si" : "No")
    },
    {
      text: "",
      dataField: "id",
      csvExport: false,
      formatter: deleteRsvpFormatter,
      style: columnStyle,
      headerStyle: headerStyle
    }
  ];

  const getDateText = () => {
    let start = parseISO(selectedTimeSlot.start_date)
    if (selectedTimeSlot.start_time !== null) {
      start = parse(selectedTimeSlot.start_time, "HH:mm:ss", start)
    }
    return format(start, "d-MMM-yyyy HH mm")
  }

  const renderTable = () => {
    if (loading) {
      return <LoadingRoller/>;
    }
    if (selectedTimeSlot === null) {
      return <React.Fragment/>;
    }
    return (
      <ToolkitProvider
        keyField="user.email"
        columns={columns}
        data={userList}
        exportCSV={{
          fileName: `${selectedTimeSlot.area_program.area.name} ${getDateText()}.csv`
        }}
      >
        {props => (
          <div>
            <div className="text-right mb-2">
              <MyExportCSV {...props.csvProps} />
            </div>
            <BootstrapTable
              {...props.baseProps}
              rowStyle={rowStyle}
              noDataIndication="No hay reservaciones en este bloque."
            />
          </div>
        )}
      </ToolkitProvider>
    );
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="text-primary">
          Reservaciones en este bloque
        </Modal.Header>
        <Modal.Body>{renderTable()}</Modal.Body>
      </Modal>
    </div>
  );
};

export default PeopleListModal;
