import React, { useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Alert, Button, Modal } from "react-bootstrap";

import EllipsisLoader from "../../misc/EllipsisLoader";
import safeGymApi from "../../../apis/safegym";
import { getAuthHeader } from "../../../helpers/tokens";

const DeleteAreaModal = ({
  show,
  onHide,
  gymAreas,
  setGymAreas,
  selectedArea
}) => {
  const [success, setSuccess] = useState(null);

  const renderAlert = () => {
    if (success === null) return;
    const message = success ? "Eliminado correctamente." : "Ocurrió un error.";
    return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
  };

  const handleHide = () => {
    setSuccess(null);
    onHide();
  };

  if (!selectedArea) return <React.Fragment />;
  return (
    <Modal show={show} onHide={handleHide}>
      <Formik
        initialValues={{ name: selectedArea.name }}
        onSubmit={(values, { setSubmitting }) => {
          setSuccess(null);
          safeGymApi
            .patch(
              `/gym/areas/${selectedArea.id}/`,
              {
                archived: true
              },
              { headers: getAuthHeader() }
            )
            .then(() => {
              setSuccess(true);
              const newData = gymAreas.flatMap(a =>
                a.id === selectedArea.id ? [] : a
              );
              setGymAreas(newData);
              setSubmitting(false);
            })
            .catch(() => {
              setSuccess(false);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Header className="text-danger">Eliminar</Modal.Header>
            <Modal.Body>
              {renderAlert()}
              <div>
                <p>
                  ¿Estas seguro que quieres eliminar el area{" "}
                  <span style={{ color: selectedArea.hex_color }}>
                    {`"${selectedArea.name}"`}
                  </span>
                  ?
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleHide} variant="outline-secondary">
                Cerrar
              </Button>
              <Button variant="danger" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Eliminar"}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default DeleteAreaModal;
