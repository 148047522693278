export const countries = [
  { value: "AR", label: "Argentina" },
  { value: "CA", label: "Canada" },
  { value: "CL", label: "Chile" },
  { value: "CO", label: "Colombia" },
  { value: "CR", label: "Costa Rica" },
  { value: "DO", label: "Republica Dominicana" },
  { value: "EC", label: "Ecuador" },
  { value: "ES", label: "España" },
  { value: "SV", label: "El Salvador" },
  { value: "MX", label: "México" },
  { value: "PA", label: "Panama" },
  { value: "PE", label: "Peru" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "VE", label: "Venezuela" }
];
