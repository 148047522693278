import { FETCH_USER } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        subscriptionActive: action.payload.subscription_active,
        email: action.payload.email,
        firstName: action.payload.first_name,
        memberLimits: action.payload.member_limits,
        preferences: action.payload.preferences
      };
    default:
      return state;
  }
};
