import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import { format, formatISO9075, endOfDay, addDays } from "date-fns";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { object, number } from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiking, faDumbbell } from "@fortawesome/free-solid-svg-icons";
import es from "date-fns/locale/es";

import DaysRepeatCheckboxes from "./DaysRepeatCheckboxes";
import ClassesSelect from "./ClassesSelect";
import EllipsisLoader from "../misc/EllipsisLoader";
import safeGymApi from "../../apis/safegym";
import { getAuthHeader } from "../../helpers/tokens";

import "react-datepicker/dist/react-datepicker.css";
import "../../assets/styles/datepicker.css";

const NewClassModal = ({ show, onHide, showAlert, addNewSchedules }) => {
  registerLocale("es", es);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [selectClassValue, setSelectClassValue] = useState(null);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [displayError, setDisplayError] = useState(null);
  const [isGymClass, setIsGymClass] = useState(false);

  const redAsterisk = () => <span className="text-danger">*</span>;

  const renderError = () => {
    if (displayError) {
      return <p className="text-danger">{displayError}</p>;
    }
  };

  const newClassValidation = object({
    minutes: number()
      .required("Campo requerido. Solo números.")
      .positive("Solo números positivos."),
    peopleLimit: number()
      .required("Campo requerido. Solo números.")
      .positive("Solo números positivos.")
  });

  const handleSubmitForm = (values, setSubmitting) => {
    const postData = {
      area: selectClassValue.value,
      instructor_name: values.instructor,
      minutes_duration: values.minutes,
      is_gym_class: isGymClass,
      people_limit: values.peopleLimit,
      monday: values.monday,
      tuesday: values.tuesday,
      wednesday: values.wednesday,
      thursday: values.thursday,
      friday: values.friday,
      saturday: values.saturday,
      sunday: values.sunday,
      is_recurrent: isRecurrent,
      start_time: formatISO9075(startTime, {representation: "time"}),
      start_date:
        format(startDate, "yyyy-MM-dd") + format(startTime, "'T'HH:mm:00XX"),
      end_date: endDate
        ? format(endDate, "yyyy-MM-dd") + format(startTime, "'T'HH:mm:00XX")
        : format(endOfDay(startDate), "yyyy-MM-dd'T'HH:mm:00XX")
    };

    safeGymApi
      .post("/programs/", postData, {
        headers: getAuthHeader()
      })
      .then(response => response.data.id)
      .then(programId => {
        safeGymApi
          .get(`/programs/${programId}/list_related_timeslots/`, {
            headers: getAuthHeader()
          })
          .then(timeslots => {
            addNewSchedules(timeslots.data);
          });
      })
      .then(() => {
        setSubmitting(false);
        showAlert();
        onHide();
      })
      .catch(err => {
        setDisplayError(err.response.data.detail);
        setSubmitting(false);
      });
  };

  useEffect(() => {
    // End date should always be greater than start date
    if (startDate > endDate) {
      setEndDate(addDays(startDate, 1));
    }
  }, [startDate, endDate]);

  return (
    <Formik
      validationSchema={newClassValidation}
      initialValues={{
        minutes: "",
        peopleLimit: "",
        instructor: "",
        selectclass: "",
        time: "",
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }}
      validate={values => {
        const errors = {};

        if (
          values.monday ||
          values.tuesday ||
          values.wednesday ||
          values.thursday ||
          values.friday ||
          values.saturday ||
          values.sunday
        ) {
          setIsRecurrent(true);
        } else {
          setIsRecurrent(false);
        }

        if (!selectClassValue) {
          errors.selectclass = "Campo requerido.";
        }
        if (!startTime) {
          errors.startTime = "Error";
        }

        if (isRecurrent && !endDate) {
          errors.endDate = "Error";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitForm(values, setSubmitting);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <Modal show={show} onHide={onHide} size="lg">
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Crear bloques horario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {renderError()}
              <Row>
                <Col>
                  <Form.Group className="step5">
                    <Form.Label>{redAsterisk()}Nombre</Form.Label>
                    <ClassesSelect
                      selectClassValue={selectClassValue}
                      setSelectClassValue={setSelectClassValue}
                    />
                    <Form.Text className="text-danger">
                      {errors.selectclass}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="selectSeccionStep">
                    <Form.Label className="mr-4">
                      {redAsterisk()}Sección:
                    </Form.Label>
                    <Form.Check
                      className="mr-4"
                      checked={!isGymClass}
                      name="seccionRadio"
                      type="radio"
                      inline
                      label={
                        <span>
                          <FontAwesomeIcon
                            className="text-secondary mr-1"
                            icon={faDumbbell}
                          />
                          Área
                        </span>
                      }
                      onChange={e => {
                        setIsGymClass(!e.target.checked);
                      }}
                    />
                    <Form.Check
                      checked={isGymClass}
                      name="seccionRadio"
                      type="radio"
                      inline
                      label={
                        <span>
                          <FontAwesomeIcon
                            className="text-secondary mr-1"
                            icon={faBiking}
                          />
                          Clase
                        </span>
                      }
                      onChange={e => {
                        setIsGymClass(e.target.checked);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="step6">
                    <Form.Label>{redAsterisk()}Limite de Personas</Form.Label>
                    <Form.Control
                      name="peopleLimit"
                      value={values.peopleLimit}
                      onChange={handleChange}
                      type="number"
                      placeholder="Limite de Personas"
                    />
                    <Form.Text className="text-danger">
                      {errors.peopleLimit &&
                        touched.peopleLimit &&
                        errors.peopleLimit}
                    </Form.Text>
                  </Form.Group>
                  <div className="step7">
                    <Form.Group>
                      <Form.Label>{redAsterisk()}Fecha de Inicio</Form.Label>
                      <br />
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="EEEE dd 'de' MMMM 'del' yyyy"
                        locale={es}
                        minDate={new Date()}
                        maxDate={addDays(new Date(), 90)}
                      />
                    </Form.Group>
                    <Form.Group className="w-100">
                      <Form.Label>{redAsterisk()}Hora de Inicio</Form.Label>
                      <br />
                      <DatePicker
                        className="form-control w-100 m-0"
                        selected={startTime}
                        onChange={date => setStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Hora"
                        dateFormat="h:mm aa"
                        name="time"
                      />
                    </Form.Group>
                  </div>
                  <Form.Group>
                    <Form.Label>
                      {redAsterisk()}Duración{" "}
                      <span className="text-secondary">(Minutos)</span>
                    </Form.Label>
                    <Form.Control
                      name="minutes"
                      value={values.minutes}
                      onChange={handleChange}
                      type="number"
                      placeholder="Minutos"
                    />
                    <Form.Text className="text-danger">
                      {errors.minutes && touched.minutes && errors.minutes}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      Instructor{" "}
                      <span className="text-secondary">(opcional)</span>
                    </Form.Label>
                    <Form.Control
                      name="instructor"
                      value={values.instructor}
                      onChange={handleChange}
                      type="text"
                      placeholder="Nombre de instructor"
                    />
                  </Form.Group>
                  <DaysRepeatCheckboxes
                    values={values}
                    handleChange={handleChange}
                  />
                  <Form.Group hidden={!isRecurrent}>
                    <Form.Label>
                      {redAsterisk()}Seguir repitiendo hasta:
                    </Form.Label>
                    <br />
                    <DatePicker
                      className="form-control"
                      selected={endDate}
                      onChange={date => setEndDate(date)}
                      dateFormat="EEEE dd 'de' MMMM 'del' yyyy"
                      locale={es}
                      minDate={addDays(startDate, 1)}
                      maxDate={addDays(new Date(), 365)}
                    />
                    <Form.Text className="text-danger">
                      {endDate ? "" : "Campo requerido"}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onHide}>
                Cerrar
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? <EllipsisLoader /> : "Crear bloques"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default NewClassModal;
